import { Typography, PlarinLogo } from '@plarin/design';
import clsx from 'clsx';
import React from 'react';
import './auth-complete-form-template.scss';

export const AuthCompleteFormTemplate: React.FC<{
  title: React.ReactNode;
  subtitle: React.ReactNode;
  formProps?: React.ComponentProps<'form'>;
  children: React.ReactNode;
}> = ({ title, subtitle, children, formProps }) => (
  <div className="authCompleteTemplate">
    <a href="/" className="authCompleteTemplate__logo">
      <PlarinLogo variant="auth-form" />
    </a>
    <div className="authCompleteTemplate__content">
      <div className="authCompleteFormTemplate">
        <div className="authCompleteFormTemplate__title">
          <Typography component="h1" size="AuthMediumHeader" align="left" color="TextPrimary" weight={600}>
            {title}
          </Typography>
        </div>
        <div className="authCompleteFormTemplate__subtitle">
          <Typography component="span" size="authContent" color="TextSecondary" align="left">
            {subtitle}
          </Typography>
        </div>
        <form {...formProps} className={clsx('authCompleteFormTemplate__form', formProps?.className)}>
          {children}
        </form>
      </div>
    </div>
  </div>
);
