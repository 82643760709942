import { Column, CustomCellRendererSelector, GridApi } from '@plarin/inputs';
import { ManageYDTabNameEnum, getSafeParsedLocalStorage, isUrlProd } from '@plarin/utils';
import type { SelectionColumnDef, ICellRendererParams, IRowNode } from 'ag-grid-community';
import React from 'react';
import { TMetric, TMetricGroup, TYDMetric, TYDMetricGroup } from '../../../types/metrics';
import { TWsDictionary } from '../../../types/profile/types';
import { ManageYDNameCellsEnum, STORAGE_ITEM } from '../../utils/constants';
import { selectionColumnDef } from '../../utils/get-selection-column-def';
import { SwitchStatusEntity } from '../switch-status-entity';
import { customColumns } from './custom-columns';
import { defaultColumns } from './default-columns';

// скрываем чекбокс, вместо него показываем лоадер
export const isLoading = (params: ICellRendererParams<any, any, any> | IRowNode<any>) => {
  return params.data.cellLoadingName || params.data.price === 'loading' || params.data.status === 'loading';
};

// настройки колонки с чекбоксами
export const getYdSelectionColumnDef = (isGroupingActive: boolean): SelectionColumnDef => {
  return {
    ...selectionColumnDef,
    maxWidth: isGroupingActive ? 64 : 40,
    minWidth: isGroupingActive ? 64 : 40,
    cellRendererParams: {
      suppressCount: true,
      suppressDoubleClickExpand: true,
      suppressEnterExpand: true,
      value: '',
      loaderPrecisePositioning: isGroupingActive ? -8 : undefined,
    },
    cellRendererSelector: params => {
      if (new Set(Object.keys(params.data)).has('orgHierarchy') && params.data.isGroupRow) {
        return { component: 'agGroupCellRenderer' };
      }
      if (isLoading(params)) {
        return { component: 'PreloaderRenderer' };
      }
      return { component: null };
    },
    cellClass: params => {
      return `campaignsStatusCode checkbox ${params.data.statusColor} ${
        isLoading(params.node) ? 'hideCheckbox' : 'hidePreloader'
      }`;
    },
  };
};

const createSwitchButton = (tabName: ManageYDTabNameEnum, data: any, gridApi: GridApi) => {
  return <SwitchStatusEntity tabNameYd={tabName} data={data} gridApi={gridApi} network="yd" />;
};

const getDefaultColumns = (
  tabName: ManageYDTabNameEnum,
  checkedColumns: string[] = [],
  isGroupingActive: boolean,
): Column[] => {
  const columns = [defaultColumns.ACCOUNTS, defaultColumns.CAMPAIGNS, defaultColumns.GROUPS, defaultColumns.ADS];

  switch (tabName) {
    case ManageYDTabNameEnum.ACCOUNTS: {
      return columns
        .filter((_, index) => index === 0)
        .map(el => {
          if (el.field === 'accountInfo') {
            return {
              ...el,
              cellRendererParams: { ...el.cellRendererParams, tabName },
              headerComponentParams: { subheader: 'ID Клиента', isPinned: true },
              cellRendererSelector: CustomCellRendererSelector,
              pinned: 'left',
              cellClassRules: {
                ...el.cellClassRules,
                hasOrgHierarchy: params =>
                  params.node.data.hasOwnProperty('orgHierarchy') && !params.node.data.isGroupRow,
              },
            };
          }

          return el;
        });
    }
    case ManageYDTabNameEnum.CAMPAIGNS: {
      return columns
        .filter((_, index) => index === 0 || index === 1)
        .map(el => {
          if (el.field === 'campaignName') {
            return {
              ...el,
              cellRendererParams: {
                ...el.cellRendererParams,
                tabName,
                createSwitchButton: createSwitchButton,
              },
              cellRendererSelector: CustomCellRendererSelector,
              headerComponentParams: { subheader: 'ID Кампании', isPinned: true },
              pinned: 'left',
              cellClassRules: {
                ...el.cellClassRules,
                hasOrgHierarchy: params =>
                  params.node.data.hasOwnProperty('orgHierarchy') && !params.node.data.isGroupRow,
              },
            };
          }
          return el;
        });
    }
    case ManageYDTabNameEnum.GROUPS: {
      return columns

        .filter((_, index) => index === 1 || index === 2)
        .map(el => {
          if (el.headerName === 'Группа объявлений') {
            return {
              ...el,
              cellRendererParams: {
                ...el.cellRendererParams,
                tabName,
                // isAdsPreview: true,
              },
              cellRendererSelector: CustomCellRendererSelector,
              headerComponentParams: { subheader: 'ID Объявления', isPinned: true },
              pinned: 'left',
              cellClassRules: {
                ...el.cellClassRules,
                hasOrgHierarchy: params =>
                  params.node.data.hasOwnProperty('orgHierarchy') && !params.node.data.isGroupRow,
              },
            };
          }
          return el;
        });
    }
    case ManageYDTabNameEnum.ADS: {
      return columns
        .filter((_, index) => (index === 1 && checkedColumns.includes('campaign_name')) || index === 3 || index === 2)
        .map(el => {
          if (el.field === 'adId') {
            return {
              ...el,
              headerComponentParams: { subheader: 'ID Объявлений', isPinned: true },
              cellRendererParams: {
                ...el.cellRendererParams,
                tabName,
                createSwitchButton: createSwitchButton,
              },
              cellRendererSelector: CustomCellRendererSelector,
              pinned: 'left',
              cellClassRules: {
                ...el.cellClassRules,
                hasOrgHierarchy: params =>
                  params.node.data.hasOwnProperty('orgHierarchy') && !params.node.data.isGroupRow,
              },
            };
          }
          return el;
        });
    }

    default:
      return [];
  }
};

const needColumnsPaths = {
  // mt_project: 'PROJECTS',
  campaign_status: 'STATUS',
  // ad_group_status: 'STATUS',
  ad_status: 'STATUS',
  // ad_plan_start: 'PERIOD',
  // price: 'PRICE',
  // satistics_fast: 'FAST_STAT',
  // satistics_fast72: 'FAST_STAT_72',
  // ad_plan_lifetime: 'BUDGET_LIFETIME',
  // ad_plan_daily: 'BUDGET_DAILY',
  account_balance: 'DEFAULT',
  daily_budget_amount: 'BUDGET',
};

const columnsDefault = {
  statistics_spent: 'DEFAULT',
  statistics_impressions: 'DEFAULT',
  statistics_clicks: 'DEFAULT',
  statistics_conversions: 'DEFAULT',
  statistics_ctr: 'DEFAULT',
  statistics_cpc: 'DEFAULT',
  statistics_cpm: 'DEFAULT',
  statistics_ecpm: 'DEFAULT',
  statistics_crr: 'DEFAULT',
  statistics_cpa: 'DEFAULT',
  statistics_cr: 'DEFAULT',
  statistics_revenue: 'DEFAULT',
};

const getColumns2 = (
  names: TYDMetricGroup['fields'],
  wsDictionary: TWsDictionary,
  tabName: keyof typeof ManageYDNameCellsEnum,
) => {
  const columns: Column[] = [];
  Object.keys(needColumnsPaths).forEach(path => {
    if (
      // для скрытия колонок трансляция когда на бэке не скрыты)
      (tabName === 'groupName' && path === 'campaign_status') ||
      (tabName === 'adId' && path === 'campaign_status')
    ) {
      return null;
    }

    names.forEach(metric => {
      if (path === metric.path) {
        // @ts-ignore
        columns.push(customColumns(metric, wsDictionary, tabName)[needColumnsPaths[path]]);
      }
    });
  });
  return columns;
};

const otherColumns = (
  names: TYDMetricGroup['fields'],
  wsDictionary: TWsDictionary,
  tabName: keyof typeof ManageYDNameCellsEnum,
) => {
  const excludedPaths = ['account_name', 'ad_plan_name', 'campaign_name', 'ad_name'];
  const columns: Column[] = [];

  // Используем Set для отслеживания уже добавленных колонок
  const addedPaths = new Set<string>();

  // Проходим по names и добавляем соответствующие колонки из columnsDefault в том порядке, в котором они появляются в names
  names
    .filter(el => !Object.keys(needColumnsPaths).includes(el.path))
    .filter(el => !excludedPaths.includes(el.path))

    .forEach(el => {
      if (
        el.checked // Проверка на checked
      ) {
        columns.push(customColumns(el, wsDictionary, tabName).DEFAULT);
        addedPaths.add(el.path);
      }
      // Проверка на колонку из columnsDefault
      else if (Object.keys(columnsDefault).includes(el.path) && !addedPaths.has(el.path)) {
        //@ts-ignore
        const column = columnsDefault[el.path];

        // Добавляем колонку из columnsDefault в тот же порядок, что и в names
        columns.push(customColumns(el, wsDictionary, tabName)[column]);
        addedPaths.add(el.path);
      } // Если колонка не в columnsDefault, проверяем на другие условия и добавляем по необходимости
    });

  return columns;
};


export const dynamicColumnsYDRenderer = (
  names: TYDMetric[],
  tabName1: ManageYDTabNameEnum,
  tabName2: keyof typeof ManageYDNameCellsEnum,
  wsDictionary: TWsDictionary,
  isGroupingActive: boolean,
) => {
  const checkedColumns = getSafeParsedLocalStorage<string[]>(STORAGE_ITEM.localStorage.METRICSYD);
  return [
    ...getDefaultColumns(tabName1, checkedColumns ? checkedColumns : [], isGroupingActive),
    ...getColumns2(names, wsDictionary, tabName2),
    ...otherColumns(names, wsDictionary, tabName2),
  ];
};
