import { Typography } from '@plarin/design';
import clsx from 'clsx';
import React, { useState } from 'react';
import classes from './tabs-layout.module.scss';

type TTabs = 'Дашбоард' | 'Настройка проекта';

interface TabsLayoutProps {
  children: React.ReactNode;
}

const TabsLayout: React.FC<TabsLayoutProps> = ({ children }) => {
  const [activeTab, setActiveTab] = useState<TTabs>('Дашбоард');
  return (
    <div className={classes.layoutContent}>
      <div className={classes.layoutHeader}>
        <div className={classes.headerLeft}>
          <div
            className={clsx(classes.tabName, activeTab === 'Дашбоард' && classes.active)}
            onClick={() => setActiveTab('Дашбоард')}
          >
            <Typography
              size="AuthContent"
              weight={activeTab === 'Дашбоард' ? 600 : 400}
              componentProps={{ className: classes.tabNameText }}
              color="TextPrimary"
            >
              Дашборд
            </Typography>
          </div>
          <div
            className={clsx(classes.tabName, activeTab === 'Настройка проекта' && classes.active)}
            style={{ cursor: 'not-allowed' }}
            // onClick={() => setActiveTab('Настройка проекта')}
            onClick={() => {}}
          >
            <Typography
              size="AuthContent"
              weight={activeTab === 'Настройка проекта' ? 600 : 400}
              componentProps={{ className: classes.tabNameText }}
              color="TextDisabled"
            >
              Настройки проекта
            </Typography>
          </div>
        </div>
        <div className={classes.headerRight}></div>
      </div>
      <div className={classes.layoutBody}>{children}</div>
    </div>
  );
};

export default TabsLayout;
