import { Page, EAppErrorCodes } from '@plarin/core';
import { Typography } from '@plarin/design';
import { Button, Checkbox, Input } from '@plarin/inputs';
import { useInput, TypeInput, formatPhone } from '@plarin/utils';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import React, { useState, useEffect } from 'react';
import { history } from './../../components/app';
import { useAppDataStore } from '../../components/app-data-provider';
import { AuthFormContainerRegSocialTemplate } from '../../components/auth/auth-form-template';
import { AuthInputsTemplate } from '../../components/auth/auth-inputs-template';
import { AuthRegPageTemplate } from '../../components/auth/auth-page-template';
import classes from '../registration/style.module.scss';
import { LegalDocuments } from './../../enums';
import { RegistrationSocialFormStore } from './registration-social-form.store';
import socialClasses from './style.module.scss';
import { routerPaths } from 'src/routing/router-path';

const getEmailHint = (canEditEmail: boolean, phone: string) => {
  if (canEditEmail) return 'Для регистрации необходимо заполнить адрес электронной почты';

  if (!phone) return 'Вы сможете изменить адрес электронной почты после регистрации в разделе “Профиль и настройки”';

  return undefined;
};

const getPhoneHint = (canEditEmail: boolean) =>
  canEditEmail
    ? undefined
    : 'Вы сможете изменить адрес электронной почты и телефон после регистрации в разделе “Профиль и настройки”';

const RegistrationSocialPage: React.FC<{}> = observer(function RegistrationSocialPage() {
  const { profileStore } = useAppDataStore();
  const [pageStore] = useState(() => new RegistrationSocialFormStore(profileStore));
  const { data, submit, isPending, emailFromToken, setNewEmail, phone, alertProps, errorCode, setErrorCode } =
    pageStore;
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const [canEditEmail, setCanEditEmail] = useState<boolean>(false);
  const [policyChecked, setPolicyChecked] = useState<boolean>(true);
  const [emailHasError, setEmailHasError] = useState(false);

  const fname = useInput({
    initialValue: data.fname || '',
    type: TypeInput.fName,
    isRequired: true,
  });
  const {
    setError: fnameSetError,
    setHint: fnameSetHint,
    setWasChanged: fnameSetWasChanged,
    checkValue: fnameCheckValue,
    error: fnameError,
    ...fnameInput
  } = fname;

  const lname = useInput({
    initialValue: data.lname === 'None' ? '' : data.lname || '',
    type: TypeInput.lName,
    isRequired: false,
  });
  const {
    setError: lnameSetError,
    setHint: lnameSetHint,
    setWasChanged: lnameSetWasChanged,
    checkValue: lnameCheckValue,
    error: lnameError,
    ...lnameInput
  } = lname;

  const emailInInput = useInput({
    initialValue: emailFromToken === 'None' ? '' : emailFromToken || '',
    type: TypeInput.email,
    isRequired: true,
  });
  const {
    setError: emailSetError,
    setHint: emailSetHint,
    setWasChanged: emailSetWasChanged,
    checkValue: emailCheckValue,
    error: emailError,
    value: newEmailValue,
    ...emailInput
  } = emailInInput;

  useEffect(() => {
    fnameCheckValue();
    lnameCheckValue();
    emailCheckValue();

    if (!fname.value || fname.error || lname.error || emailInInput.error) {
      setIsSubmit(false);
      !fname.value && fnameSetError(true);
    } else {
      setIsSubmit(true);
      data.fname = fname.value;
      data.lname = lname.value;
    }
  }, [fname, lname, data, fnameSetError, emailInInput]);

  useEffect(() => {
    if (canEditEmail) {
      // сетим в стор новую почту, введённую пользователем
      setNewEmail(newEmailValue);

      // выделяем инпут красным, если в инпуте почта из токена, а мы хотим получить от пользователя другую почту
      newEmailValue === emailFromToken ? setEmailHasError(true) : setEmailHasError(false);
    }
  }, [newEmailValue, canEditEmail]);

  // если при первом рендере этой формы в url присутствует этот код ошибки, мы хотим отметить в сторе, что эта ошибка присутствует
  useEffect(() => {
    if (window.location.href.includes(EAppErrorCodes.USER_WITH_THIS_EMAIL_ALREADY_EXISTS))
      setErrorCode(EAppErrorCodes.USER_WITH_THIS_EMAIL_ALREADY_EXISTS);
  }, []);

  // если в токене нет email, то пользователь должен сам ввести почту в инпут
  useEffect(() => {

    emailFromToken === 'None' && setCanEditEmail(true);
  }, [emailFromToken]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');

    if (!token) {
      history.push(routerPaths.unAuth.LOGIN);
    }
  }, []);

  return (
    <Page title="Регистрация">
      <AuthRegPageTemplate>
        <AuthFormContainerRegSocialTemplate
          errorCode={errorCode}
          alertProps={alertProps}
          setCanEditEmail={setCanEditEmail}
          emailSetError={setEmailHasError}
        >
          <AuthInputsTemplate
            formProps={{
              onSubmit: submit,
              className: classes.socialReg,
            }}
            addSpace
          >
            <span className={clsx(socialClasses.inputsWrap, getPhoneHint(canEditEmail) && socialClasses.mb30)}>
              <Input {...lnameInput} label="Фамилия" fullWidth size={48} disableMinHeight={true} error={lnameError} />
              <Input {...fnameInput} label="Имя" fullWidth size={48} disableMinHeight={true} error={fnameError} />
              <Input
                {...emailInput}
                value={newEmailValue}
                type="email"
                label="Электронная почта"
                disabled={!canEditEmail}
                fullWidth
                autoComplete="off"
                hint={getEmailHint(canEditEmail, phone)}
                size={48}
                disableMinHeight={true}
                error={emailError || emailHasError}
              />
              {phone && (
                <Input
                  value={formatPhone(phone) || '+' + phone}
                  onChange={() => void 0}
                  label="Номер мобильного телефона"
                  disabled={true}
                  disableMinHeight={true}
                  hint={getPhoneHint(canEditEmail)}
                  size={48}
                />
              )}
            </span>
            <div className={clsx(classes.policy, socialClasses.mt20)}>
              <Checkbox
                value={policyChecked}
                onChange={setPolicyChecked}
                size="medium"
                className={classes.disableMarginLabel}
              />
              <Typography size="Caption" componentProps={{ className: 'containerWidth' }} color="TextSecondary">
                Регистрируясь, я принимаю условия{' '}
                <Typography
                  underline
                  color="linkPrimary"
                  component="a"
                  componentProps={{ href: LegalDocuments.LICENSE_AGREEMENT, target: '_blank' }}
                >
                  лицензионного договора
                </Typography>
                , даю{' '}
                <Typography
                  underline
                  color="linkPrimary"
                  component="a"
                  componentProps={{ href: LegalDocuments.PERSONAL_DATA_PROCESSING_CONSENT, target: '_blank' }}
                >
                  согласие на обработку персональных данных
                </Typography>{' '}
                и
                <Typography
                  underline
                  color="linkPrimary"
                  component="a"
                  componentProps={{ href: LegalDocuments.INFORMATION_MESSAGES_CONSENT, target: '_blank' }}
                >
                  {' '}
                  согласие
                  <br /> на получение информационных сообщений
                </Typography>
              </Typography>
            </div>
            {isPending ? (
              <Button variant="filled" size="medium" color="primary" loading={true} />
            ) : (
              <Button
                variant="filled"
                size="medium"
                color="primary"
                submit={isSubmit}
                disabled={!policyChecked || emailError || fnameError || lnameError || emailHasError}
              >
                Зарегистрироваться
              </Button>
            )}
          </AuthInputsTemplate>
        </AuthFormContainerRegSocialTemplate>
      </AuthRegPageTemplate>
    </Page>
  );
});

export default RegistrationSocialPage;
