import { WsAvatar, WsItemType } from '@plarin/design';
import React from 'react';
import { LinesMember } from './components';
import classes from './style.module.scss';
import { MemberRenderProps } from './type';

export const MemberRender = ({ data, MemberCellMenu, api }: MemberRenderProps) => {
  const { fname, lname, status, email, created } = data;

  return (
    <div className={classes.container}>
      <div>
        <WsAvatar data={data} type={WsItemType.member} />
      </div>

      <LinesMember fname={fname} lname={lname} email={email} created={created} api={api} />

      <MemberCellMenu data={data} />
    </div>
  );
};
