import { Alert, SocialButtonsLayout, SocialVkButton, TAlertProps } from '@plarin/inputs';
import clsx from 'clsx';
import React from 'react';
import { SOCIAL_NAME, SocialNameType } from '../../../utils/constants';
import { AuthFormContainerTemplate } from './auth-form-container-template';
import './auth-form-container-template.scss';

export const AuthFormContainerSocialButtonsTemplate: React.FC<{
  title: string;
  disabled: boolean;
  onSocialButtonClick?: (social: SocialNameType) => void;
  alertProps?: TAlertProps;
  children: React.ReactNode;
}> = ({ title, onSocialButtonClick, alertProps, disabled, children }) =>
  AuthFormContainerTemplate({
    title,
    children: (
      <>
        {alertProps && (
          <div role="alert" className="auth-form-template__error">
            <Alert {...alertProps} />
          </div>
        )}

        <div className={clsx('auth-form-template__social-buttons', disabled && 'disabled')}>
          <SocialButtonsLayout>
            <SocialVkButton onClick={() => void onSocialButtonClick?.(SOCIAL_NAME.VKONTAKTE)} />
          </SocialButtonsLayout>
        </div>
        {children}
      </>
    ),
  });
