export enum EAppErrorCodes {
  SOCIAL_NOT_LOGIN = 800,
  USER_WITH_THIS_EMAIL_ALREADY_EXISTS = 'USER_WITH_THIS_EMAIL_ALREADY_EXISTS',
  SOCIAL_AUTH = 'SOCIAL_AUTH',
  NO_EMAIL_PROVIDED = 'NO_EMAIL_PROVIDED',
  ACCESS_DENIED = 'ACCESS_DENIED',
  TOKEN_NOT_FOUND = 'TOKEN_NOT_FOUND',
  FORGOT_TOKEN_NOT_FOUND = 'FORGOT_TOKEN_NOT_FOUND',
  SOCIAL_PROFILE_ALREADY_CONNECTED = 'SOCIAL_PROFILE_ALREADY_CONNECTED',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
  EXCESS_PARAMETER_EMAIL = 'EXCESS_PARAMETER_EMAIL',
}

export class AppError extends Error {
  code: EAppErrorCodes;

  constructor(code: EAppErrorCodes, msg?: string) {
    super(msg);
    this.code = code;
  }
}

export const isServerError = (error: number) => +error.toString()[0] === 5;

export const isInvalidRequestServer = (error: number) => +error.toString()[0] === 4;
