import { DownloadReportIcon, MoreIcon, Preloader } from '@plarin/design';
import { MainTooltip, Button, Popover } from '@plarin/inputs';
import { TooltipError, TooltipMessage } from '@plarin/utils';
import { observer } from 'mobx-react';
import React, { useCallback, useRef, useState } from 'react';
import { TReportData } from '../../../../../types/reports/reports';
import { useAppDataStore } from '../../../../components/app-data-provider';
import { responseReportAdapter } from '../../../../utils/reports';
import classes from './styles.module.scss';

export const ReportsActions = observer(
  ({
    id,
    data,
    openEditReport,
  }: {
    id: string;
    data: TReportData;
    openEditReport: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  }) => {
    const {
      reports: { deleteReport, downloadExcelReport, createReport, isLoadingExcelDownload },
    } = useAppDataStore();
    const [isOpen, setOpen] = useState(false);
    const moreIcon = useRef(null);

    const hasClients = !!data.clients.length;

    const onToggleMenu = useCallback(
      (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setOpen(isOpen => !isOpen);
      },
      [setOpen],
    );

    const onCloseMenu = useCallback(
      (e: any) => {
        e.stopPropagation();
        setOpen(false);
      },
      [setOpen],
    );

    const onDownload = useCallback(
      (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation();
        downloadExcelReport({ id: data._id, name: data.name }).then();
      },
      [data._id, data.name, downloadExcelReport],
    );

    const duplicateReport = async () => {
      createReport({ ...responseReportAdapter(data), name: `Дубликат - ${data.name}` });
    };

    const options = [
      {
        name: 'Редактировать',
        action: (e: React.MouseEvent<HTMLDivElement, MouseEvent> | undefined) => {
          e && openEditReport(e);
          onCloseMenu(e);
        },
      },
      {
        name: 'Дублировать',
        action: (e: React.MouseEvent<HTMLDivElement, MouseEvent> | undefined) => {
          duplicateReport();
          onCloseMenu(e);
        },
      },
      {
        name: 'Удалить',
        action: (e: React.MouseEvent<HTMLDivElement, MouseEvent> | undefined) => {
          deleteReport(id);
          onCloseMenu(e);
        },
      },
    ];

    return (
      <div className={classes.actions}>
        <MainTooltip
          isVisible
          component="span"
          tooltipMessage={hasClients ? TooltipMessage.DOWNLOAD_REPORT : TooltipError.NO_REPORT_DOWNLOAD}
        >
          <Button disabled={!hasClients} className={classes.hoverIconReports}>
            {isLoadingExcelDownload.includes(id) ? (
              <Preloader size={16} thickness={3} className={classes.circularProgress} />
            ) : (
              <DownloadReportIcon color={!hasClients ? 'disabled' : 'main'} onClick={onDownload} />
            )}
          </Button>
        </MainTooltip>

        <div className={classes.moreIcon} ref={moreIcon}>
          <MainTooltip isVisible component="span" tooltipMessage={TooltipMessage.EDIT}>
            <Button className={classes.hoverIconReports}>
              <MoreIcon onClick={onToggleMenu} />
            </Button>
          </MainTooltip>
        </div>

        <Popover
          isListLeft
          classNameWrapper={classes.moreIconMenu}
          setIsOpen={onCloseMenu}
          anchorEl={moreIcon.current}
          isOpen={isOpen}
          dataList={options}
        />
      </div>
    );
  },
);
