interface GetUrlAuthData {
  token: string | null;
  id: string | null;
  accessToken: string | null;
  refreshToken: string | null;
  email: string | null;
  fname: string | null;
  lname: string | null;
  phone: string | null;
  stayLoggedIn: boolean | null;
}

export function getUrlAuthData(): GetUrlAuthData {
  const params = new URLSearchParams(window.location.search);
  return {
    token: params.get('token'),
    id: params.get('id'),
    accessToken: params.get('at'),
    refreshToken: params.get('rt'),
    email: params.get('email'),
    fname: params.get('fname'),
    lname: params.get('lname'),
    phone: params.get('phone'),
    stayLoggedIn: !!params.get('stay_logged_in'),
  };
}
