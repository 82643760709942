import { AppError, EApiCodes, EAppErrorCodes } from '@plarin/core';
import { Typography } from '@plarin/design';
import { TAlertProps } from '@plarin/inputs';
import { minutesToSendReq, typografText } from '@plarin/utils';
import axios from 'axios';
import React from 'react';
import { Link } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import { routerPaths } from '../routing/router-path';

interface AlertContentProps {
  children: React.ReactNode;
}

const LinkSupport = () => {
  const supportRedirect = () => {
    window.open('https://plarin.ru/support/', '_blank');
  };
  const { show: showIntercom, getVisitorId } = useIntercom();
  const visitorId = getVisitorId();

  return (
    <>
      {visitorId ? (
        <Typography
          underline
          color="linkPrimary"
          componentProps={{ onClick: showIntercom, style: { cursor: 'pointer' } }}
        >
          в службу поддержки
        </Typography>
      ) : (
        <Typography
          underline
          color="linkPrimary"
          component={Link}
          componentProps={{ onClick: supportRedirect, to: routerPaths.unAuth.LOGIN }}
        >
          в службу поддержки
        </Typography>
      )}
    </>
  );
};

const AlertContent: React.FC<AlertContentProps> = ({ children }) => (
  <>
    {children} <LinkSupport />
  </>
);

export const getAlertProps = (error: Error): TAlertProps => {
  const apiCode = getApiCodeAxiosError(error) || error.toString().toUpperCase().split(' ').join('_');
  if (error.message && error.message.includes('504')) {
    return {
      title: 'Произошла ошибка на сервере',
      content: <AlertContent>Попробуйте еще раз или обратитесь</AlertContent>,
    };
  }
  if (apiCode === EApiCodes.WRONG_CREDENTIALS) {
    return {
      title: 'Неверный адрес электронной почты или пароль',
      content: <AlertContent>Попробуйте еще раз или обратитесь</AlertContent>,
    };
  }
  if (apiCode === EApiCodes.PHONE_NOT_FOUND || apiCode === EApiCodes.PHONE_NOT_CONFIRMED) {
    return {
      title: 'Неверный номер телефона',
      content: <AlertContent>Попробуйте еще раз или обратитесь</AlertContent>,
    };
  }
  if (apiCode === EApiCodes.TWILIO_INVALID_TO_PHONE_NUMBER) {
    return {
      title: 'Неверный формат номера телефона',
      content: <AlertContent>Попробуйте еще раз или обратитесь</AlertContent>,
    };
  }
  if (apiCode === EApiCodes.VALIDATION_ERROR) {
    return {
      title: 'Невалидные данные',
      content: <AlertContent>Попробуйте еще раз или обратитесь</AlertContent>,
    };
  }
  if (apiCode === EApiCodes.USER_NOT_FOUND) {
    return {
      title: 'Указанный адрес электронной почты не найден',
      content: (
        <Typography
          underline
          color="linkPrimary"
          component={Link}
          componentProps={{ to: routerPaths.unAuth.REGISTRATION }}
        >
          Зарегистрироваться
        </Typography>
      ),
    };
  }
  if (apiCode === EApiCodes.EMAIL_ALREADY_CONFIRMED) {
    return {
      title: 'Электронная почта уже подтверждена.',
      content: (
        <>
          <Typography underline color="linkPrimary" component={Link} componentProps={{ to: routerPaths.unAuth.LOGIN }}>
            Войдите
          </Typography>{' '}
          или{' '}
          <Typography
            underline
            color="linkPrimary"
            component={Link}
            componentProps={{ to: routerPaths.unAuth.FORGOT_PASSWORD }}
          >
            восстановите пароль.
          </Typography>
        </>
      ),
    };
  }
  if (apiCode === EApiCodes.UNAUTHORIZED || apiCode === EApiCodes.CONFIRM_EMAIL_TOKEN_EXPIRED_AND_RESENT) {
    return {
      title: 'Не удалось подтвердить регистрацию',
      content: typografText(
        'Срок действия ссылки истек. Мы выслали новую ссылку на указанный вами адрес электронной почты.',
      ),
    };
  }
  if (apiCode === EApiCodes.USER_IS_NOT_ACTIVE) {
    return {
      title: 'Ваш аккаунт заблокирован',
      content: <AlertContent>Обратитесь</AlertContent>,
    };
  }
  if (apiCode === EApiCodes.THROTTLING_LIMIT_1_MIN) {
    return {
      title: `Подождите ${minutesToSendReq(error)}`,
      content: undefined,
    };
  }
  if (apiCode === EApiCodes.THROTTLING_LIMIT_2_MIN) {
    return {
      title: `Подождите ${minutesToSendReq(error)}`,
      content: undefined,
    };
  }
  if (apiCode === EApiCodes.THROTTLING_LIMIT_5_MIN) {
    return {
      title: `Подождите ${minutesToSendReq(error)}`,
      content: undefined,
    };
  }
  if (apiCode === EApiCodes.LIMIT_EXCEEDED) {
    return {
      title: 'Вы исчерпали лимит отправки СМС\u2011кодов',
      content: <>Подождите {minutesToSendReq(error)} и попробуйте заново.</>,
    };
  }
  if (apiCode === EApiCodes.SMS_NOT_SENT) {
    return {
      title: 'Не получается отправить смс. Произошла ошибка на сервере',
      content: <AlertContent>Для разрешения проблемы обратитесь</AlertContent>,
    };
  }
  if (apiCode === EApiCodes.TOKEN_EXPIRED) {
    return {
      title: 'Не удалось подтвердить новую электронную почту',
      content: typografText(
        'Срок действия ссылки истек. Мы выслали новую ссылку на указанный вами адрес электронной почты.',
      ),
    };
  }

  if (apiCode === EApiCodes.ALREADY_EXISTS) {
    return {
      title: 'Что то пошло не так.',
      content: (
        <>
          Указанный адрес электронной почты уже зарегистрирован.{' '}
          <Typography underline color="linkPrimary" component={Link} componentProps={{ to: routerPaths.unAuth.LOGIN }}>
            Войдите
          </Typography>{' '}
          или{' '}
          <Typography
            underline
            color="linkPrimary"
            component={Link}
            componentProps={{ to: routerPaths.unAuth.FORGOT_PASSWORD }}
          >
            восстановите пароль.
          </Typography>
        </>
      ),
    };
  }

  if (apiCode === EApiCodes.EMAIL_REQUIRED) {
    return {
      title: 'Не удалось зарегистрировать аккаунт без адреса электронной почты.',
      content: <>Введите адрес электронной почты.</>,
    };
  }

  if (error instanceof AppError) {
    if (error.code === EAppErrorCodes.TOKEN_NOT_FOUND) {
      return {
        title: 'Не удалось получить токен для изменения пароля',
        content: <>Попробуйте еще раз.</>,
      };
    }
    if (error.code === EAppErrorCodes.SOCIAL_NOT_LOGIN) {
      return {
        title: 'Не удалось войти через аккаунт социальной сети ',
        content: <AlertContent>Попробуйте еще раз или обратитесь</AlertContent>,
      };
    }
    if (error.code === EAppErrorCodes.USER_WITH_THIS_EMAIL_ALREADY_EXISTS) {
      return {
        title: 'Адрес электронной почты из вашего аккаунта\n' + 'социальной сети уже зарегистрирован в Plarin',
        content: (
          <>
            Для настройки входа в Plarin через социальную сеть{' '}
            <Typography
              underline
              color="linkPrimary"
              component={Link}
              componentProps={{ to: routerPaths.unAuth.LOGIN }}
            >
              войдите в учетную запись
            </Typography>{' '}
            с помощью указанной почты и привяжите к ней аккаунт социальной сети в личном кабинете.
          </>
        ),
      };
    }
    if (error.code === EAppErrorCodes.SOCIAL_AUTH) {
      return {
        title: 'Ошибка авторизации через социальную сеть',
      };
    }
    if (error.code === EAppErrorCodes.NO_EMAIL_PROVIDED) {
      return {
        title: 'Не удалось зарегистрировать аккаунт \n' + 'без адреса электронной почты',
        content: <>Предоставьте права на адрес электронной почты при регистрации через социальную сеть</>,
      };
    }
    if (error.code === EAppErrorCodes.UNKNOWN_ERROR) {
      return {
        title: 'Произошла неизвестная ошибка',
      };
    }
    if (error.code === EAppErrorCodes.SOCIAL_PROFILE_ALREADY_CONNECTED) {
      return {
        title: 'Данная социальная сеть уже подключена в Plarin',
      };
    }
    if (error.code === EAppErrorCodes.FORGOT_TOKEN_NOT_FOUND) {
      return {
        title: 'Не удалось восстановить пароль',
        content: (
          <>
            Срок действия ссылки истек. Пожалуйста, воспользуйтесь{' '}
            <Typography
              underline
              color="linkPrimary"
              component={Link}
              componentProps={{ to: routerPaths.unAuth.FORGOT_PASSWORD }}
            >
              восстановлением пароля
            </Typography>{' '}
            еще раз.
          </>
        ),
      };
    }
    if (error.code === EAppErrorCodes.EXCESS_PARAMETER_EMAIL) {
      return {
        title: 'Произошла техническая ошибка',
        content: <AlertContent>EXCESS_PARAMETER_EMAIL. Попробуйте еще раз или обратитесь</AlertContent>,
      };
    }
  }

  return {
    title: 'Что то пошло не так.',
    content: <AlertContent>Попробуйте еще раз или обратитесь</AlertContent>,
  };
};

export function getApiCodeAxiosError(error: Error): EApiCodes | undefined {
  if (axios.isAxiosError(error) && error.response?.data) {
    return error.response!.data.error.code;
  }
}
