import { useEffect, useLayoutEffect, useState } from 'react';
import { IUsePosition } from './type';

const defaultElementValue = {
  bottom: 0,
  x: 0,
  height: 0,
  width: 0,
};

const configObserverSize = { attributes: true, childList: true, subtree: true };
const config = { ...configObserverSize, attributeFilter: ['style'] };

export const usePosition = ({
  isOpenBottom,
  anchorEl,
  isListLeft,
  refBoxListItem,
  marginTop = 8,
  ref,
  setHaveScroll,
  upTopElement,
  observerSize,
  isRight,
  isAutoHorizon,
}: IUsePosition) => {
  const [sizeWrapper, setSizeWrapper] = useState<DOMRect | undefined>(undefined);
  const [windowInnerHeight, setWindowInnerHeight] = useState<number>(0);
  const [windowInnerWidth, setWindowInnerWidth] = useState<number>(0);
  const [isRenderFirstTop, setIsRenderFirstTop] = useState({ value: false, valueDefined: false });

  const {
    bottom: top,
    x: left,
    height: heightElement,
    width: widthElment,
  } = (anchorEl && anchorEl?.getBoundingClientRect()) || defaultElementValue;

  const isTopAuto = windowInnerHeight - top - (sizeWrapper?.height || 0) - marginTop <= 0 || isRenderFirstTop.value;

  const isLeftAuto = windowInnerWidth - widthElment - left - (sizeWrapper?.width || 0) <= 0;

  let topWrapper = isTopAuto ? top - (sizeWrapper?.height || 0) - heightElement - marginTop : top + marginTop;
  const rightWrapper = windowInnerWidth - left - (sizeWrapper?.width || 0);
  let leftWrapper = isListLeft
    ? left - (sizeWrapper?.width || 0) + widthElment
    : left + (rightWrapper < 0 ? rightWrapper - 24 : 0); // 24 - расстояние от правой стороны экрана

  const sizeLeftWrapper = leftWrapper + (isLeftAuto ? -(sizeWrapper?.width || 0) : widthElment); // определение расположения обертки с лева

  if (upTopElement) {
    topWrapper = topWrapper - (isTopAuto ? 0 : heightElement + marginTop);
    leftWrapper = sizeLeftWrapper;
  }

  if (isRight) {
    topWrapper =
      windowInnerHeight - top + heightElement - (sizeWrapper?.height || 0) > 0
        ? top - heightElement
        : top - (sizeWrapper?.height || 0);
    leftWrapper = isLeftAuto ? left - (sizeWrapper?.width || 0) : sizeLeftWrapper;
  }

  // для того чтоб открывалась выпадашка всегда вниз (доп опция через пропс)
  if (isOpenBottom) {
    topWrapper = top + marginTop;
  }

  if (isAutoHorizon) {
    leftWrapper = isLeftAuto ? left - (sizeWrapper?.width || 0) + widthElment : leftWrapper;
  }
  // будет опускаться выпадашка вниз на топ-8, если не хватает места вверху (авто)
  if (topWrapper < 0) {
    topWrapper = 8;
  }

  if (windowInnerWidth < leftWrapper + (sizeWrapper?.width ?? 0)) {
    leftWrapper = windowInnerWidth - (sizeWrapper?.width ?? 0);
  }

  function resizeWindowAuto() {
    setSizeWrapper(ref && ref?.current?.getBoundingClientRect());
  }
  useEffect(() => {
    window.addEventListener('resize', resizeWindowAuto);
    const callback = () => {
      setSizeWrapper(ref && ref?.current?.getBoundingClientRect());
    };
    const observer = new MutationObserver(callback);

    ref.current && observer.observe(ref.current, observerSize ? configObserverSize : config);

    return () => {
      observer.disconnect();
      window.removeEventListener('resize', resizeWindowAuto);
    };
  }, [ref]);

  useEffect(() => {
    !isRenderFirstTop.valueDefined && sizeWrapper && setIsRenderFirstTop({ value: isTopAuto, valueDefined: true });
  }, [sizeWrapper]); // определяем была ли первая отрисовка в верху или нет

  useLayoutEffect(() => {
    setWindowInnerHeight(window.innerHeight);
    setWindowInnerWidth(window.innerWidth);
    setSizeWrapper(ref && ref?.current?.getBoundingClientRect());
    if (refBoxListItem?.current && refBoxListItem.current.scrollHeight > refBoxListItem.current.offsetHeight) {
      setHaveScroll(true);
    }
  }, []);

  return { topWrapper, leftWrapper };
};
