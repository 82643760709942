
import { FakeGroupCellPages } from '@plarin/utils';
import React from 'react';
import classes from './style.module.scss';
import { MainTooltip } from '../../../tooltip/main-tooltip';
import { AgGreedTreeClosed } from '@plarin/design';

const gaTooltip = 'В этом аккаунте нет представлений';
const afTooltip = 'В этой интеграции нет приложений';
const ymTooltip = 'В этом аккаунте нет счётчиков';
const ydTooltip = 'В этом аккаунте нет клиентов';

const getTooltipText = (page: FakeGroupCellPages | undefined) => {
  if (page === FakeGroupCellPages.ga) return gaTooltip;
  if (page === FakeGroupCellPages.af) return afTooltip;
  if (page === FakeGroupCellPages.ym) return ymTooltip;
  if (page === FakeGroupCellPages.yd) return ydTooltip;

  return 'Нельзя раскрыть группировку';
};

// отображает задизейбленную стрелочку для "раскрытия группировки" для строк, которые на самом деле не являются группирующими (не имеют детей)
export const FakeGroupCellRenderer = (props: any) => {
  return (
    <div className={classes.wrapper}>
      <MainTooltip
        tooltipMessage={getTooltipText(props.page)}
        isVisible={true}
        component="div"
        componentClassName={classes.tooltip}
        placement="bottom"
        followCursor
      >
        <AgGreedTreeClosed />
      </MainTooltip>
    </div>
  );
};
