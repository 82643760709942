import { getGridApi } from '@plarin/inputs';
import { getNumeral, int } from '@plarin/utils';
import { IStatusPanelParams } from 'ag-grid-enterprise';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useAppDataStore } from '../app-data-provider';
import classes from './style.module.scss';

// если число равно нулю, то int возвращает строку '--', а нам это здесь не нужно
const getNumber = (num: number) => (num ? int(num) : String(num));

export enum CommonStatusPanelTypes {
  members = 'members',
  projects = 'projects',
  teams = 'teams',
}

interface IconnectionStatusPanel extends IStatusPanelParams {
  type: CommonStatusPanelTypes;
}

// константы для функции, склоняющей существительное после числителя (один аккаунт, два аккаунта)
const strMembers = ['пользователь', 'пользователя', 'пользователей'];
const strProjects = ['проект', 'проекта', 'проектов'];
const strTeams = ['команда', 'команды', 'команд'];

const getItemsSum = (items: number, numerals: string[]) => {
  return <span className={classes.accTotal}>{items ? `${getNumber(items)} ${getNumeral(items, numerals)}` : ''}</span>;
};

const getItemsNumerals = (itemsType: CommonStatusPanelTypes) => {
  switch (itemsType) {
    case CommonStatusPanelTypes.members:
      return strMembers;

    case CommonStatusPanelTypes.projects:
      return strProjects;

    case CommonStatusPanelTypes.teams:
      return strTeams;
  }
};

// этот компонент футера передаётся в таблицу с помощью объекта statusBar
// дока по statusBar для statusPanel: https://www.ag-grid.com/react-data-grid/component-status-bar/
export const СommonStatusPanel = observer((props: IconnectionStatusPanel) => {
  const {
    members: { rowCountMembers },
    projects: { rowCountProjects },
    teams: { rowCountTeams },
  } = useAppDataStore();
  const itemsType: CommonStatusPanelTypes = props.type; // members/projects/teams
  const itemsNumerals =
    getItemsNumerals(itemsType); /* itemsType === CommonStatusPanelTypes.appsflyer ? strIntegration : strAccounts*/

  const data = (() => {
    switch (itemsType) {
      case CommonStatusPanelTypes.members:
        return rowCountMembers;
      case CommonStatusPanelTypes.teams:
        return rowCountTeams;
      case CommonStatusPanelTypes.projects:
        return rowCountProjects;
    }
  })();

  const [first, setfirst] = useState(0);

  // костыль для обновления футера при изменении содержания таблицы
  const updateStatusBar = () => {
    getGridApi(props.api) && setfirst(props.api.getDisplayedRowCount());
  };

  useEffect(() => {
    props.api.addEventListener('modelUpdated', updateStatusBar);

    return () => {
      getGridApi(props.api)?.removeEventListener('modelUpdated', updateStatusBar);
    };
  }, [rowCountMembers]);

  return (
    <div className={classes.statusPanel} key={first}>
      {getItemsSum(data, itemsNumerals)}
    </div>
  );
});
