import { WsAvatar, WsItemType } from '@plarin/design';
import { HightLightText, EllipsisTooltip, CustomMenuItem } from '@plarin/inputs';
import { typografNames } from '@plarin/utils';
import clsx from 'clsx';
import React, { useState } from 'react';
import { TTeamsFilters } from '../../../../../../../../types/projects/projects';
import classes from '../../styles.module.scss';

type TListItemProps = {
  team: TTeamsFilters;
  changeTeamsSelection: (team: TTeamsFilters) => void;
  isFoundItem?: boolean;
  searchValue?: string;
};

export const TeamsListItem = ({ team, changeTeamsSelection, isFoundItem, searchValue = '' }: TListItemProps) => {
  const [isHovered, setIsHovered] = useState(false);
  const nameTooltipContent = `${team.name} `;

  return (
    <CustomMenuItem
      onMouseOver={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onMouseDown={e => e.preventDefault()}
      onClick={() => isFoundItem && changeTeamsSelection(team)}
      className={clsx(classes.cabinetListItem, isFoundItem && classes.foundCabinet)}
    >
      <WsAvatar type={WsItemType.team} size={20} data={team} />
      <div className={classes.regularWrap}>
        <EllipsisTooltip tooltipMessage={nameTooltipContent}>
          <HightLightText
            size="Main"
            weight={400}
            color={isHovered ? 'TextWhite' : 'TextPrimary'}
            componentProps={{ className: classes.listItemText }}
            text={typografNames(team.name)}
            filterValue={searchValue}
          />
        </EllipsisTooltip>
      </div>
    </CustomMenuItem>
  );
};
