import clsx from 'clsx';
import React, { useCallback, useState } from 'react';
import { TReportData } from '../../../../../types/reports/reports';
import { useAppDataStore } from '../../../../components/app-data-provider';
import { responseReportAdapter } from '../../../../utils/reports';
import { ReportsActions } from './reports-actions';
import { ReportsClients } from './reports-clients';
import { ReportsName } from './reports-name';
import { ReportsPeriod } from './reports-period';

export const PeriodItem = ({ data, filterValue }: { data: TReportData; filterValue: string }) => {
  const {
    reports: { updateInitialStateDrawer, openDrawer },
  } = useAppDataStore();

  const openEditReport = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.stopPropagation();
      updateInitialStateDrawer({ ...responseReportAdapter(data), isEdit: true, id: data._id });
      openDrawer();
    },
    [openDrawer, updateInitialStateDrawer, data._id],
  );
  return (
    <div className={clsx('report-list-item', 'reportHover')}>
      <div className="report-list-item_left" onClick={openEditReport}>
        <ReportsName name={data.name} metrics={data.entities} filterValue={filterValue} />
      </div>
      <div className="report-list-item_right">
        <ReportsPeriod data={data} />
        <ReportsClients reportData={data} />
        <ReportsActions id={data._id} data={data} openEditReport={openEditReport} />
      </div>
    </div>
  );
};
