import axios from 'axios';
import moment from 'moment';
import { useEffect, useState } from 'react';
interface IAuthorizeProps {
  children: JSX.Element;
  isAuthorized?: boolean;
  logout: () => Promise<void>;
}

let interval: NodeJS.Timer;

export const accessTokenName = 'plarin_app_storage_access_token';

export const refreshTokenName = 'plarin_app_storage_refresh_token';
const isRefreshingKey = 'isRefreshing';

const refreshTokens = async (logout: () => Promise<void>) => {
  if (localStorage.getItem(isRefreshingKey)) {
    await new Promise<void>(resolve => {
      const interval = setInterval(() => {
        if (!localStorage.getItem(isRefreshingKey)) {
          clearInterval(interval);
          resolve();
        }
      }, 100);
    });
    return;
  }

  const refreshToken = localStorage.getItem(refreshTokenName);
  if (!refreshToken) {
    await logout();
    return;
  }

  try {
    localStorage.setItem(isRefreshingKey, 'true');
    const res = await axios.post(
      '/api/v1/user/auth/refresh',
      {},
      {
        headers: {
          Authorization: `Bearer ${refreshToken}`,
        },
      },
    );

    localStorage.setItem(refreshTokenName, res.data.data.refresh_token);
    localStorage.setItem(accessTokenName, res.data.data.access_token);

    if (res.data.data.refresh_expires) {
      localStorage.setItem('expires', moment(res.data.data.refresh_expires).unix().toString());
    }
    return moment(res.data.data.refresh_expires).unix().toString();
  } catch (e) {
    [accessTokenName, refreshTokenName, 'metrics'].forEach(item => localStorage.removeItem(item));
    await logout();
  } finally {
    localStorage.removeItem(isRefreshingKey);
  }
};

export const AuthorizedOnly = ({ children, isAuthorized, logout }: IAuthorizeProps) => {
  const [expires, setExpires] = useState<string | null>(localStorage.getItem('expires'));
  const refreshToken = localStorage.getItem(refreshTokenName);

  useEffect(() => {
    setExpires(localStorage.getItem('expires'));
  }, [isAuthorized, logout, refreshTokens]);

  useEffect(() => {
    if (refreshToken) {
      setTimeout(() => {
        if (expires) {
          interval = setInterval(() => {
            const currentTime = Date.now();
            const expiryTime = Number(expires) * 1000;

            if (expiryTime - currentTime < 60000) {
              refreshTokens(logout).then(newExpires => {
                newExpires ? setExpires(newExpires) : setExpires(null);
              });
            }
          }, 5000);
        } else {
          setTimeout(() => {
            refreshTokens(logout).then(newExpires => {
              newExpires ? setExpires(newExpires) : setExpires(null);
            });
          }, 20000);
        }
      }, 0);
    }

    return () => {
      clearInterval(interval);
    };
  }, [isAuthorized, logout, expires, refreshToken]);

  return isAuthorized ? children : null;
};
