import { RedoIcon } from '@plarin/design';
import {
  ActionTableButton,
  Button,
  Column,
  GridApi,
  MainTooltip,
  SearchDefaultInput,
  Table,
  StatusPanelDef,
} from '@plarin/inputs';
import { getMemberName } from '@plarin/utils';
import { RowClickedEvent } from 'ag-grid-community';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { TWsRole, WsMembers } from '../../../types/profile/types';
import { useAppDataStore } from '../../components/app-data-provider';
import { СommonStatusPanel, CommonStatusPanelTypes } from '../../components/common-status-panel/common-status-panel';
import { WsEmptyTableOverlay } from '../../components/ws-table-overlay/ws-table-overlay';
import { useFilter } from '../manage-vk/tabs-content/utils/quickFilterSwitcher';
import { teamsColumns } from './components/columns';
import { SwitcherActionButton } from './components/table-actions';
import { TeamsConfirmModal } from './components/teams-confirm-modal/teamsConfirmModal';
import { TeamsDrawer } from './components/teams-drawer/teams-drawer';
import { getWsRole } from './components/utils';
import classes from './style.module.scss';

const Teams = () => {
  const {
    teams: {
      setSearchFilter,
      searchFilter,
      isTeamsLoading,
      teamsData,
      setRowCountTeams,
      getTeams,
      isProjectsLoading,
      isDrawerOpen,
      onCloseDrawer,
      onOpenDrawer,
      setTeams,
      idAddedTeam,
      setIdAddedTeam,
      hasLoadingError,
      emptyDrawerData,
    },
    profileStore: { currentWorkspace, data: profileData },
    projects: { projectsData, getProjects },
  } = useAppDataStore();

  const [gridApi, setGridApi] = useState<GridApi>(null as unknown as GridApi);
  const [hasAdminRights, setHasAdminRights] = useState(false);
  const [isArchive, setIsArchive] = useState(false);
  const archivedAmount = useRef(0);

  const wsRole: TWsRole | undefined = getWsRole(profileData.user_id, currentWorkspace);
  const wsMembers: WsMembers = useMemo(() => currentWorkspace.members, [currentWorkspace]);
  const columns: Column[] = teamsColumns(wsMembers, wsRole, projectsData, profileData.user_id);

  const onOpenEditDrawer = useCallback(
    (e: RowClickedEvent) => {
      const isLeader = e.data.leader_id === profileData.user_id;

      onOpenDrawer({ ...e.data, canOnlyView: !(isLeader || hasAdminRights), isEditDrawer: true });
    },
    [currentWorkspace.members, projectsData],
  );

  const onOpenCreateDrawer = () => {
    onOpenDrawer(emptyDrawerData);
  };

  const getFilteredData = () => (isArchive ? teamsData : teamsData.filter(e => e.status === 'active'));

  const changeArchive = () => setIsArchive(value => !value);

  const statusBar = useMemo<{
    statusPanels: StatusPanelDef[];
  }>(() => {
    return {
      statusPanels: [
        {
          statusPanel: СommonStatusPanel,
          align: 'left',
          statusPanelParams: { type: CommonStatusPanelTypes.teams },
        },
      ],
    };
  }, []);

  useEffect(() => {
    wsRole && setHasAdminRights(wsRole === 'wsrole:owner' || wsRole === 'wsrole:admin');
  }, [profileData.user_id]);

  useEffect(() => {
    getTeams();
    getProjects();

    return () => {
      setTeams([]);
      setIdAddedTeam('');
    };
  }, []);

  useEffect(() => {
    archivedAmount.current = teamsData.filter(i => i.status === 'inactive').length;
  }, [teamsData]);

  const agNoRowsOverlay = useCallback(
    () =>
      WsEmptyTableOverlay({
        type: 'teams',
        hasLoadingError,
        hasAdminRights,
        openDrawer: onOpenCreateDrawer,
        archived: archivedAmount.current,
      }),
    [archivedAmount, hasAdminRights, hasLoadingError],
  );

  const data = useMemo(
    () =>
      getFilteredData().map(el => {
        const leader = wsMembers.find(member => member._id === el.leader_id);
        const leaderName = (() => {
          return getMemberName({ fname: leader?.fname, lname: leader?.lname, email: leader?.email });
        })();
        return { ...el, leaderName };
      }),
    [getFilteredData],
  );

  useEffect(() => {
    setRowCountTeams(data.length);
  }, [data]);

  useFilter(searchFilter, gridApi, data);

  return (
    <>
      <SearchDefaultInput
        placeholder="Поиск"
        filterValue={searchFilter}
        setFilter={setSearchFilter}
        searchIconColor="main"
        inputStyle="tableSearch"
      />
      <div
        className={clsx(classes.tableContent, classes.fixFlexColumns, !getFilteredData().length && classes.emptyTable)}
      >
        <div className={classes.tableWrap}>
          <div className={classes.actionPanel}>
            <div className={classes.leftButtons}>
              <MainTooltip
                tooltipMessage="Только владелец и администраторы могут создавать команды"
                component="span"
                isVisible={!hasAdminRights}
              >
                <Button
                  variant="filled"
                  size="small"
                  color="primary"
                  onClick={onOpenCreateDrawer}
                  disabled={!hasAdminRights}
                >
                  Создать
                </Button>
              </MainTooltip>
            </div>

            <div className={classes.rightButtons}>
              <SwitcherActionButton isOn={isArchive} action={changeArchive} name="Архивные" />
              <ActionTableButton
                textTooltip="Обновить"
                action={() => getTeams()}
                loading={isTeamsLoading || isProjectsLoading}
                icon={<RedoIcon />}
                className={classes.reloadButton}
                isReloadButton={true}
              />
            </div>
          </div>
        </div>

        <Table
          columns={columns}
          rowData={data}
          statusBar={teamsData.length && statusBar}
          onGridReady={setGridApi}
          tableLoading={isTeamsLoading || isProjectsLoading}
          onRowClicked={onOpenEditDrawer}
          isRowGroup
          agNoRowsOverlay={agNoRowsOverlay}
          needAutoHeight
          spaceAboveTable={254}
          extraFooterHeight={40}
          getRowClass={el => (el?.data?._id === idAddedTeam ? 'ag-row-selected' : undefined)}
        />
      </div>

      <TeamsDrawer isOpen={isDrawerOpen} onClose={onCloseDrawer} />
      <TeamsConfirmModal />
    </>
  );
};

export default observer(Teams);
