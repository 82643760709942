import React, { useState } from 'react';
import SwiperCore, { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import './slider.scss';
import { SliderPagination, SliderPaginationProps } from './slider-pagination';

SwiperCore.use([Autoplay]);

export type TSliderProps = {
  interval?: number;
  children?: React.ReactNode;
};

export const Slider: React.FC<TSliderProps> = function Slider({ children, interval }) {
  const count = React.Children.count(children);
  const [index, setIndex] = React.useState(0);
  const [swiperInstance, setSwiperInstance] = useState<SwiperCore>();
  const handleChangeSlide: SliderPaginationProps['onSelect'] = i => {
    swiperInstance && swiperInstance.slideToLoop(i);
  };

  return (
    <div className="slider">
      <Swiper
        autoplay={{
          delay: interval || 3000,
          disableOnInteraction: false,
        }}
        loop={true}
        onSlideChange={e => setIndex(e.realIndex)}
        onSwiper={setSwiperInstance}
      >
        {React.Children.toArray(children).map((item, i) => (
          <SwiperSlide key={i}>{item}</SwiperSlide>
        ))}
      </Swiper>

      <SliderPagination className="slider__pagination" count={count} index={index} onSelect={handleChangeSlide} />
    </div>
  );
};
