import { CustomSelect, ListVariants } from '@plarin/inputs';
import { getMemberName } from '@plarin/utils';
import { AvatarsIconTypes } from '@plarin/design';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { useAppDataStore } from '../../../../app-data-provider';

export const ProjectManagers = observer(({ disabledRead }: { disabledRead?: boolean }) => {
  const {
    members: { members },
    projects: { projectsState, setProjectsState, editProject, setIsDrawerDataChanged },
  } = useAppDataStore();

  useEffect(() => {
    editProject.editProjectData && setProjectsState('projectManagers', editProject.editProjectData?.manager_id);
    setIsDrawerDataChanged(false);
  }, [editProject, setProjectsState]);

  const listVariants = members.reduce((acc: ListVariants[], member, index) => {
    if (index === 0) {
      acc.push({ value: 'not', iconType: AvatarsIconTypes.none, label: 'Без менеджера' });
    }
    if (member.role !== 'wsrole:guest') {
      acc.push({
        value: `${member._id}`,
        label: getMemberName({ fname: member?.fname, lname: member?.lname, email: member?.email }),
        ...member,
      });
    }
    return acc;
  }, []);


  return (
    <>
      <CustomSelect
        disabled={disabledRead}
        disabledRead={disabledRead}
        ulPadding={true}
        useMemberAvatar
        label="Менеджер проекта"
        value={projectsState.projectManagers ? projectsState.projectManagers : 'not'}
        onChange={e => {
          setProjectsState('projectManagers', e);
        }}
        listVariants={listVariants}
        menuHeight={144}
      />
    </>
  );
});
