import { Typography } from '@plarin/design';
import { Drawer } from '@plarin/inputs';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { TGuests } from '../../../../types/projects/projects';
import { useAppDataStore } from '../../app-data-provider';
import { WsDrawerPreloader } from '../../drawer/preloaders/preloaders';
import { ActionBlockDrawer } from './action-block-drawer';
import {
  ProjectInfoCabinets,
  ProjectInfoGuests,
  TeamsAndMembersSection,
  ProjectManagers,
  ProjectName,
  ProjectStatus,
} from './components';
import classes from './project-drawer.module.scss';
import { TitleProjectDrawer } from './title-project-drawer';

interface IProjectDrawer {
  isDrawerOpen: boolean;
  onClose: () => void;
  editProjects: () => void;
  createProjects: () => void;
}

export const ProjectDrawer = observer(({ isDrawerOpen, onClose, editProjects, createProjects }: IProjectDrawer) => {
  const {
    teams: { getTeams, teamsData },
    members: { members, getWSMembers },
    projects: {
      getCabinetsShort,
      setTeams,
      setGuestsState,
      statusWSroles,
      editProject,
      isDrawerDataChanged,
      setModalData,
      projectsState,
    },
  } = useAppDataStore();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const isCreateProject = !editProject.editProjectData;

  const onCloseDrawer = () => {
    if (isDrawerDataChanged && projectsState && projectsState.projectsName !== '') {
      setModalData({ type: 'saveExit', data: {} });
    } else {
      onClose();
    }
  };

  // костыль, чтобы при появлении дровера лейбл в инпуте для названия проекта не менял своего положения
  useEffect(() => {
    // скелетон вроде не нужен так как данные берем с мобикс, и в момент загрузки таблици - все данные уже получены в мобикс
    if (isDrawerOpen && !isCreateProject) {
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
      }, 350);
    }
  }, [isDrawerOpen]);

  useEffect(() => {
    getCabinetsShort();
    getTeams();
    getWSMembers();
  }, [getCabinetsShort, getTeams, getWSMembers]);

  useEffect(() => {
    let guests: TGuests[] = members?.reduce((acc, el): TGuests[] => {
      if (el.role.split(':')[1] === 'guest') {
        return [
          ...acc,
          {
            id: el._id,
            fname: el.fname || '',
            lname: el.lname || '',
            avatarColor: el.avatar_color,
            status: el.status,
            email: el.email,
          },
        ];
      } else {
        return acc;
      }
    }, [] as TGuests[]);

    setTeams('teams', teamsData);

    setGuestsState('guests', guests);
  }, [members, teamsData, setGuestsState, setTeams]);

  const { isAdmin, isManager, isLeader } = statusWSroles;

  const isHidden = isLeader || isManager || isAdmin;

  return (
    <Drawer
      isOpen={isDrawerOpen}
      workspaceDrawer
      title={<TitleProjectDrawer />}
      preloader={<WsDrawerPreloader />}
      loading={isLoading}
      closeDrawer={onCloseDrawer}
      actionBlock={
        isHidden && (
          <ActionBlockDrawer
            onCloseDrawer={() => onClose()}
            editProjects={editProjects}
            createProjects={createProjects}
          />
        )
      }
    >
      <>
        {isHidden && (
          <div className={classes.aboutProject}>
            <Typography color="TextPrimary" weight={600} size="Main" align="left" component="span">
              О проекте
            </Typography>
          </div>
        )}

        <div className={classes.withProjectContent}>
          {isHidden && (
            <>
              <ProjectName />
              <ProjectStatus />
            </>
          )}
          <ProjectManagers disabledRead={!isHidden} />
          <ProjectInfoCabinets />
          <TeamsAndMembersSection />
          <ProjectInfoGuests />
        </div>
      </>
    </Drawer>
  );
});
