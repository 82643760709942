import { IconButton } from '@mui/material';
import { AvatarIcon } from '@plarin/design';
import React from 'react';
import classes from './icon-button.module.scss';

interface AvatarButtonProps {
  children?: React.ReactNode;
}

export const AvatarButton: React.FC<AvatarButtonProps> = ({ children = <AvatarIcon /> }) => {
  return (
    <IconButton
      classes={{
        root: classes.avatarIconButton,
      }}
    >
      {children}
    </IconButton>
  );
};
