import { WsAvatar, WsItemType, CloseCircleIcon, Typography } from '@plarin/design';
import { EllipsisTooltip, MainTooltip } from '@plarin/inputs';
import { TooltipMessage, typografNames } from '@plarin/utils';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { useAppDataStore } from '../../app-data-provider';
import classes from './styles.module.scss';

interface Props {
  name: string;
  itemData: any;
  id?: string;
  status?: 'inactive' | 'active' | 'blocked' | 'completed';
  deleteAction?: (id: string) => void;
  avatarType?: 'square' | 'circle';
  hasAdminRights: boolean;
  wsItemType: WsItemType;
}

// Рендерит списки проектов и участников в дровере Команд
export const TeamsDrawerItemList = observer(
  ({ name, id, status, deleteAction, hasAdminRights, itemData, wsItemType }: Props) => {
    const {
      profileStore: { WSRole },
    } = useAppDataStore();

    const [isHover, setIsHover] = useState(false);

    return (
      <div
        className={classes.listItem}
        key={id}
        onMouseLeave={() => setIsHover(false)}
        onMouseOver={() => !isHover && setIsHover(true)}
      >
        <div className={classes.iconBlock}>
          <WsAvatar data={itemData} type={wsItemType} />
          <EllipsisTooltip tooltipMessage={name}>
            <Typography
              size="AuthContent"
              ellips={true}
              color={
                status === 'inactive' || status === 'blocked' || status === 'completed'
                  ? 'TextSecondary'
                  : 'TextPrimary'
              }
            >
              {typografNames(name)}
            </Typography>
          </EllipsisTooltip>
        </div>

        {/* Если в дровер зашёл employee, но он лидер команды, то он может выполнять  deleteAction*/}
        {(WSRole !== 'wsrole:employee' || hasAdminRights) && (
          <div className={classes.deleteAction}>
            {isHover && (
              <MainTooltip
                tooltipMessage={TooltipMessage.DELETE_FROM_LIST}
                isVisible={true}
                component="span"
                followCursor={true}
              >
                <CloseCircleIcon onClick={() => deleteAction && id && deleteAction(id)} />
              </MainTooltip>
            )}
          </div>
        )}
      </div>
    );
  },
);
