import { WsAvatar, WsItemType, Typography } from '@plarin/design';
import { EllipsisTooltip } from '@plarin/inputs';
import { getMemberName } from '@plarin/utils';
import clsx from 'clsx';
import React from 'react';
import { TMembersTeams } from '../../../../../../../types/projects/projects';
import classes from '../project-info.module.scss';

interface IProjectMembersList {
  member: TMembersTeams;
}

// строчка участника проекта в дровере проектов в блоке "Участники"
export const MemberItem = ({ member }: IProjectMembersList) => {
  const name = getMemberName({ fname: member?.fname, lname: member?.lname, email: member?.email });
  return (
    <div className={clsx(classes.listItemAdded, classes.listMemberAdded)}>
      <WsAvatar type={WsItemType.member} data={member} />
      <div className={classes.itemMember}>
        <EllipsisTooltip tooltipMessage={name}>
          <Typography color="TextPrimary" size="AuthContent" weight={400} ellips={true}>
            {name}
          </Typography>
        </EllipsisTooltip>
        <EllipsisTooltip tooltipMessage={`${member.teamName}`}>
          <Typography size="Caption" color="TextSecondary" weight={400} ellips={true}>
            {`${member.teamName.length > 1 ? 'Команды' : 'Команда'}: ${member.teamName.join(', ')} `}
          </Typography>
        </EllipsisTooltip>
      </div>
    </div>
  );
};
