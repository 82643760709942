import { CellClassParams, Column, GetQuickFilterTextParams, StatusColorEnum } from '@plarin/inputs';
import { returnCellRules } from '../../utils/manage-vk';
import { ActionsName } from '../actions-name';

enum DefaultColumnNamesEnum {
  ACCOUNTS,
  CAMPAIGNS,
  GROUPS,
  ADS,
}

export const defaultColumns: Record<keyof typeof DefaultColumnNamesEnum, Column> = {
  ACCOUNTS: {
    headerName: 'Клиент',
    // headerComponentParams: { subheader: 'ID Клиента' },
    field: 'accountInfo',
    cellRenderer: 'TwoLinesRenderer',
    cellRendererParams: {
      firstLine: 'accountInfo',
      firstLineIsBold: true,
      secondLine: 'accountId',
      mayCopySecondLine: true,
      alternativeBullet: 'statusColor',
      ActionsName: ActionsName,
      disableEditName: true,
      isManageYD: true,
    },
    cellClassRules: {
      'sync-status-disabled': (params: CellClassParams) => params.data.statusColor === StatusColorEnum.ARCHIVED,
      banned: param => param.data.statusColor === StatusColorEnum.BANNED,
    },
    getQuickFilterText: (params: GetQuickFilterTextParams) => `${params.value} ${params.data.accountId}`,
    minWidth: 300,
    lockPinned: true,
  },
  CAMPAIGNS: {
    headerName: 'Кампания',
    headerComponentParams: { subheader: 'ID Кампании' },
    field: 'campaignName',
    cellRenderer: 'TwoLinesRenderer',
    cellRendererParams: {
      firstLine: 'campaignName',
      firstLineIsBold: true,
      secondLine: 'campaignId',
      mayCopySecondLine: true,
      ydSecondLineIcon: 'campaignType',
      alternativeBullet: 'statusColor',
      ActionsName: ActionsName,
      disableEditName: true,
      isManageYD: true,
    },
    cellClassRules: {
      'sync-status-disabled': (params: CellClassParams) => params.data.statusColor === StatusColorEnum.ARCHIVED,
      banned: param => param.data.statusColor === StatusColorEnum.BANNED,
    },
    getQuickFilterText: (params: GetQuickFilterTextParams) => `${params.value} ${params.data.campaignId}`,
    minWidth: 300,
    lockPinned: true,
  },
  GROUPS: {
    headerName: 'Группа объявлений',
    headerComponentParams: { subheader: 'ID Группы объявлений' },
    field: 'adGroupName',
    cellRenderer: 'TwoLinesRenderer',
    cellRendererParams: {
      firstLine: 'adGroupName',
      firstLineIsBold: true,
      secondLine: 'adGroupId',
      mayCopySecondLine: true,
      ydSecondLineIcon: 'campaignType',
      alternativeBullet: 'statusColor',
      ActionsName: ActionsName,
      disableEditName: true,
      isManageYD: true,
    },
    cellClassRules: {
      'sync-status-disabled': (params: CellClassParams) => params.data.statusColor === StatusColorEnum.ARCHIVED,
      banned: param => param.data.statusColor === StatusColorEnum.BANNED,
    },
    getQuickFilterText: (params: GetQuickFilterTextParams) => `${params.value} ${params.data.adGroupId}`,
    minWidth: 300,
    lockPinned: true,
  },
  ADS: {
    headerName: 'Объявление',
    headerComponentParams: { subheader: 'ID Объявления' },
    field: 'adId',
    cellRenderer: 'TwoLinesRenderer',
    cellRendererParams: {
      firstLine: 'adId',
      firstLineIsBold: true,
      secondLine: 'adId',
      mayCopySecondLine: true,
      ydSecondLineIcon: 'campaignType',
      alternativeBullet: 'statusColor',
      ActionsName: ActionsName,
      disableEditName: true,
      isManageYD: true,
    },

    cellClassRules: {
      'sync-status-disabled': (params: CellClassParams) => params.data.statusColor === StatusColorEnum.ARCHIVED,
      banned: param => param.data.statusColor === StatusColorEnum.BANNED,
    },
    getQuickFilterText: (params: GetQuickFilterTextParams) => `${params.data.adName} ${params.data.adId}`,
    minWidth: 300,
    lockPinned: true,
  },
};
