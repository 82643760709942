import { Typography } from '@plarin/design';
import { captureError, formatDateToReq, isUrlProd } from '@plarin/utils';
import { GridApi } from 'ag-grid-community';
import React from 'react';
import {
  TNewStrategyBid,
  TNewStrategyBids,
  LimitTypes,
  TCommonBudgetOptimizationLevel,
  BidItemsType,
  MtOptimisationLevel,
} from '../inputs';
import { TRANSLATION } from '../table';
import classes from './style.module.scss';
import { TRows, TRow, BidResp, StrategySaveAction, AvailableAdsProps, AvailableAdsItemResp } from './types';

export enum StrategyBidModes {
  maxPrice = 'Предельная цена',
  minPrice = 'Минимальная цена',
  differentModes = 'Разные значения',
  maxImpressions = 'Максимум показов',
  fixedRate = 'Фиксированная ставка',
}

export const inputsTypeVariants1 = [
  {
    value: StrategyBidModes.maxPrice,
    label: StrategyBidModes.maxPrice,
  },
  {
    value: StrategyBidModes.minPrice,
    label: StrategyBidModes.minPrice,
  },
];

export const inputsTypeVariants2 = [
  {
    value: StrategyBidModes.maxImpressions,
    label: StrategyBidModes.maxImpressions,
  },
  {
    value: StrategyBidModes.fixedRate,
    label: StrategyBidModes.fixedRate,
  },
  {
    value: StrategyBidModes.maxPrice,
    label: StrategyBidModes.maxPrice,
  },
];

// эти данные мы не можем получить от API, поэтому ручками забираем их из интерфейса vk ads
export const VKHardcodeRUB = {
  minLimit: 100,
  maxLimit: 9999999999,
};

export const VKHardcodeUSDAndEUR = {
  minLimit: 2.5,
  maxLimit: 9999999999,
};

export const limitMinMaxOk = (budgetLimit: number, minLimit: number) =>
  budgetLimit <= VKHardcodeRUB.maxLimit && budgetLimit >= minLimit;

export enum WhatIsEditedInStrategy {
  bid = 'bid',
  limit = 'limit',
}

export type EditBidProps = {
  selectedRows: TRows;
  close: () => void;
  onSaveAction: (args: StrategySaveAction) => Promise<BidResp>;
  gridApi: GridApi;
  getAvailableAds: (args: AvailableAdsProps) => any;
  isOnlyBudgetEditor?: boolean;
  whatIsEdited: WhatIsEditedInStrategy;
};

export type BudgetEditorProps = {
  rowData: Record<string, any>;
  close: () => void;
  onSaveAction: (args: StrategySaveAction) => Promise<BidResp>;
  gridApi: GridApi;
  getAvailableAds: (args: AvailableAdsProps) => any;
};

// для таких обжективов у нас нет формы редактирования статегии
export const bannedObjectives: string[] = ['survey'];

export const formatObjective = (data: TRow) => {
  if (data.adPlanObjective) {
    if (data.adPlanObjective === 'site_conversions') return 'Реклама сайта';
    if (data.adPlanObjective === 'leadads') return 'Лид-форма';
    if (data.adPlanObjective === 'appinstalls') return 'Мобильное приложение';
    if (data.adPlanObjective === 'reengagement') return 'Ремаркетинг';
    if (data.adPlanObjective === 'in_app_conversions') return 'Внутренние события';
    if (data.adPlanObjective === 'storeproductssales') return 'Каталог товаров';
    if (data.adPlanObjective === 'dzen') return 'Дзен';
    if (data.adPlanObjective === 'odkl') return 'Одноклассники';
    if (data.adPlanObjective === 'socialengagement') return 'Сообщество и профиль';
    if (data.adPlanObjective === 'socialaudio') return 'Музыка';
    if (data.adPlanObjective === 'socialvideo') return 'Видео и трансляции';
    if (data.adPlanObjective === 'vk_miniapps') return 'VK Mini Apps и игры';
    // adPlanObjective с тремя стратегиями Максимум показов, Фиксированная ставка и Предельная цена
    if (data.adPlanObjective === 'branding_dzen') return 'Реклама в Дзене';
    if (data.adPlanObjective === 'branding_socialengagement') return 'Запись ВКонтакте';
    if (data.adPlanObjective === 'branding_universal_banner') return 'Баннерная реклама';
    if (data.adPlanObjective === 'branding_video') return 'Видеореклама';
    if (data.adPlanObjective === 'branding_html5') return 'HTML5 баннер';
    // adPlanObjective survey отдельный тип у которого нет стратегий
    if (data.adPlanObjective === 'survey') return 'Опрос';
  }

  return '';
};

export const getHeader = (adPlanObjective: string, bidType: string) => {
  const whatYouPayFor = () => {
    if (bidType === 'CPC') return ' — Клики';
    if (bidType === 'CPM') return ' — Показы';
    if (bidType === 'CPI') return ' — Установка';
    if (bidType === 'CPL') return ' — Лиды';
    if (bidType === 'CPA') return ' — CPA';
    // этих пока нет и неизвестно будут ли такие bidType - будут добовляться по мере необходимости
    /* if (bidType === 'CPE') return ' — CPE';
    if (bidType === 'CPV') return ' — CPV';
    if (bidType === 'oCPM') return ' — oCPM';
    if (bidType === 'CPG') return ' — CPG';
    if (bidType === 'CPE_2') return ' — CPE_2';
    if (bidType === 'CPA') return ' — CPA';
    if (bidType === 'BGD') return ' — BGD';
    if (bidType === 'VCPM') return ' — VCPM';
    if (bidType === 'OTS') return ' — OTS';*/

    return '';
  };

  const filterObjective = (adPlanObjective: string, arrHeader: { adPlanObjective: string; value: string }[]) => {
    return (
      (arrHeader.filter(el => {
        return el.adPlanObjective === adPlanObjective;
      })?.[0]?.value || '') + whatYouPayFor()
    );
  };
  // TODO: кампаний может быть несколько
  const arrHeader = [
    { adPlanObjective: 'site_conversions', value: 'Реклама сайта' },
    { adPlanObjective: 'leadads', value: 'Лид-форма' },
    { adPlanObjective: 'appinstalls', value: 'Мобильное приложение' },
    { adPlanObjective: 'reengagement', value: 'Ремаркетинг' },
    { adPlanObjective: 'in_app_conversions', value: 'Внутренние события' },
    { adPlanObjective: 'storeproductssales', value: 'Каталог товаров' },
    { adPlanObjective: 'dzen', value: 'Дзен' },
    { adPlanObjective: 'odkl', value: 'Одноклассники' },
    { adPlanObjective: 'socialengagement', value: 'Сообщество и профиль' },
    { adPlanObjective: 'socialaudio', value: 'Музыка' },
    { adPlanObjective: 'socialvideo', value: 'Видео и трансляции' },
    { adPlanObjective: 'vk_miniapps', value: 'VK Mini Apps и игры' },
    // adPlanObjective с тремя стратегиями Максимум показов, Фиксированная ставка и Предельная цена
    { adPlanObjective: 'branding_dzen', value: 'Реклама в Дзене' },
    { adPlanObjective: 'branding_socialengagement', value: 'Запись ВКонтакте' },
    { adPlanObjective: 'branding_universal_banner', value: 'Баннерная реклама' },
    { adPlanObjective: 'branding_video', value: 'Видеореклама' },
    { adPlanObjective: 'branding_html5', value: 'HTML5 баннер' },
    // adPlanObjective survey отдельный тип у которого нет стратегий
    { adPlanObjective: 'survey', value: 'Опрос' },
  ];

  return filterObjective(adPlanObjective, arrHeader);
};

export const getSingleBidMode = (row: Record<string, any>) => {
  // maxPrice - "максимальная стоимость установки/конверсии" для стратегии "Предельная цена"

  if (row.maxPrice > 0) return StrategyBidModes.maxPrice;

  return StrategyBidModes.minPrice;
};

export const getBidMode = (selectedRows: TRows) => {
  if (selectedRows.length === 1) return getSingleBidMode(selectedRows[0]);

  if (new Set(selectedRows.map(row => getSingleBidMode(row))).size > 1) return StrategyBidModes.differentModes;

  return getSingleBidMode(selectedRows[0]);
};

export const getLimitTypeFromSingleItem = (limitDaily: number, limitLifetime: number) => {
  if (limitDaily > 0 && limitLifetime === 0) return LimitTypes.daily;
  if (limitDaily === 0 && limitLifetime > 0) return LimitTypes.lifetime;

  if (limitDaily > 0 && limitLifetime > 0) return LimitTypes.bothLimitsHaveValue;

  return LimitTypes.differentLimitTypes;
};

export const getCommonLimitType = (newBidsData: TNewStrategyBids) => {
  const limitsSet = new Set(newBidsData.map(item => getLimitTypeFromSingleItem(item.limitDaily, item.limitLifetime)));

  if (limitsSet.size === 1) return Array.from(limitsSet)[0];
  return LimitTypes.differentLimitTypes;
};

export const rowDataHasError = (
  selectedRow: Record<string, any>,
  itemsType: string,
  commonBudgetOptimizationLevel: TCommonBudgetOptimizationLevel,
) => {
  let hasError = false;

  // непонятен тип лимита
  if (
    commonBudgetOptimizationLevel === MtOptimisationLevel.adPlan &&
    getLimitTypeFromSingleItem(selectedRow.adPlanDaily || 0, selectedRow.adPlanLifetime || 0) ===
      LimitTypes.differentLimitTypes
  ) {
    !isUrlProd && console.log('Ошибка в пришедших данных: в дневном/общем лимите');
    hasError = true;
  }

  if (
    commonBudgetOptimizationLevel === MtOptimisationLevel.adGroup &&
    getLimitTypeFromSingleItem(selectedRow.campaignDaily || 0, selectedRow.campaignLifetime || 0) ===
      LimitTypes.differentLimitTypes
  ) {
    !isUrlProd && console.log('Ошибка в пришедших данных: в дневном/общем лимите');
    hasError = true;
  }

  if (!selectedRow.bidType.length) {
    !isUrlProd && console.log('Ошибка в пришедших данных: нет bidType');

    captureError({
      tags: {
        ISSUE: 'BIDTYPE',
      },
      extras: {
        Objective: selectedRow.adPlanObjective,
        Object: selectedRow.adPlanId,
      },
      captureMessage: { message: 'Object without bidType', level: 'warning' },
    });

    // hasError = true;
  }

  return hasError;
};

export const maxPriceWasChanged = (selectedRow: Record<string, any>, newBidData: TNewStrategyBid) => {
  if (selectedRow.maxPrice !== newBidData.maxPrice) {
    return true;
  }

  return false;
};

export const limitWasChanged = (
  selectedRow: Record<string, any>,
  newBidData: TNewStrategyBid,
  budgetOptimizationLevel: string,
) => {
  const getLimitDaily = (level: string) => selectedRow[`${level}Daily`] || 0;
  const getLimitLifetime = (level: string) => selectedRow[`${level}Lifetime`] || 0;

  const isChanged = (limitDaily: number, limitLifetime: number) =>
    newBidData.limitDaily !== limitDaily || newBidData.limitLifetime !== limitLifetime;

  if (budgetOptimizationLevel === 'adPlan') {
    return isChanged(getLimitDaily('adPlan'), getLimitLifetime('adPlan'));
  }

  if (budgetOptimizationLevel === 'campaign') {
    return isChanged(getLimitDaily('campaign'), getLimitLifetime('campaign'));
  }

  return false;
};

export const getCommonDailyLimit = (newBidsData: TNewStrategyBids): number | undefined => {
  if (new Set(newBidsData.map(item => item.limitDaily)).size > 1) return undefined;

  return newBidsData[0].limitDaily;
};

export const getCommonLifetimeLimit = (newBidsData: TNewStrategyBids): number | undefined => {
  if (new Set(newBidsData.map(item => item.limitLifetime)).size > 1) return undefined;

  return newBidsData[0].limitLifetime;
};

export const getStartAndEndDateForReqBody = (data: TNewStrategyBid, itemType: BidItemsType) => {
  let startDate = null;
  let stopDate = null;

  if (itemType === 'ad_plan') {
    startDate = data.adPlanStart;
    stopDate = data.adPlanStop;
  } else if (itemType === 'campaign' || itemType === 'banner') {
    startDate = data.campaignStart;
    stopDate = data.campaignStop;
  }

  return {
    startDate: startDate ? formatDateToReq(startDate) : (null as unknown as string),
    stopDate: stopDate ? formatDateToReq(stopDate) : (null as unknown as string),
  };
};

export const getItemsTypeForAdsReq = (itemsType: BidItemsType) => {
  if (itemsType === 'ad_plan') return 'ad_plan';
  if (itemsType === 'campaign') return 'campaign';
  if (itemsType === 'banner') return 'ad';

  return undefined;
};

export const addPackageLimitMinMax = (
  responseObj: AvailableAdsItemResp[],
  selectedRows: TRows,
  setSelectedRows: (newSelectedRows: TRows) => void,
) => {
  const newSelectedRows = selectedRows.map(selectedRow => {
    const additionalData = responseObj.find(item => item._id === selectedRow.key);
    if (additionalData) {
      return {
        ...selectedRow,
        packageLimitBidMin: additionalData.package_limit_bid_min,
        packageLimitBidMax: additionalData.package_limit_bid_max,
      };
    }
    return selectedRow;
  });
  setSelectedRows(newSelectedRows);
};

export const setAvailableAdsIds = (
  responseObj: AvailableAdsItemResp[],
  setAdsAmounts: React.Dispatch<React.SetStateAction<number[]>>,
) => {
  setAdsAmounts(responseObj.map(i => i.available_ads));
};

export const isPackageLimitBidError = (selectedRows: TRows) => {
  const packageLimitBidMinError = selectedRows.some(object => !(object.packageLimitBidMin > 0));
  const packageLimitBidMaxError = selectedRows.some(object => !(object.packageLimitBidMax > 0));

  if (packageLimitBidMinError) {
    !isUrlProd && console.log('Ошибка в пришедших данных: нет packageLimitBidMin');
  }

  if (packageLimitBidMaxError) {
    !isUrlProd && console.log('Ошибка в пришедших данных: нет packageLimitBidMax');
  }

  if (packageLimitBidMinError || packageLimitBidMaxError) {
    return true;
  }
  return false;
};

type TGetOptimnisationLevelAlert = {
  commonBudgetOptimizationLevel: TCommonBudgetOptimizationLevel;
  itemsType: BidItemsType;
  isMultipleEdit: boolean;
};

// уведомление о том, к какому срезу применятся изменения
// например, если редактируется стратегия на срезе объявлений с оптимизацией на уровне группы, то алерт сообщит, что изменения применятся к группе, в которой находятся объявленя
// https://www.figma.com/design/25lwgSBgcDaNu93HHKFZQ3/%D0%9E%D0%B3%D0%BB%D0%B0%D0%B2%D0%BB%D0%B5%D0%BD%D0%B8%D0%B5-%D0%BF%D0%BE-%D0%BF%D1%80%D0%BE%D0%B5%D0%BA%D1%82%D1%83-%2B-%D0%A0%D0%B0%D0%B1%D0%BE%D1%87%D0%B8%D0%B9-%D1%84%D0%B0%D0%B9%D0%BB?node-id=320-32817&t=UgYCJJD9vsteZN1h-4
export const GetOptimnisationLevelAlert = ({
  commonBudgetOptimizationLevel,
  itemsType,
  isMultipleEdit,
}: TGetOptimnisationLevelAlert) => {
  if (
    itemsType == 'ad_plan' ||
    (commonBudgetOptimizationLevel === MtOptimisationLevel.adGroup && itemsType === 'campaign')
  )
    return <></>;

  // рекламный объект, к которому применятся изменения
  const getOptimisationLevelName = () => {
    if (commonBudgetOptimizationLevel === MtOptimisationLevel.adPlan) return 'кампании';
    if (commonBudgetOptimizationLevel === MtOptimisationLevel.adGroup) return 'группе объявлений';

    return '--';
  };

  // группа объявлений или объявление, которые редактируются
  const getItemsType = () => {
    if (itemsType === 'campaign') return isMultipleEdit ? 'группы объявлений' : 'группа объявлений';

    if (itemsType === 'banner') return isMultipleEdit ? 'объявления' : 'объявление';

    return '--';
  };

  const message = isMultipleEdit
    ? `Изменения будут применены к ${getOptimisationLevelName()}, в которой находятся ${getItemsType()}.`
    : `Изменения будут применены к ${getOptimisationLevelName()}, в которой находится ${getItemsType()}.`;

  return (
    <div className={classes.notice}>
      <Typography size="caption">{message}</Typography>
    </div>
  );
};
