import { Typography } from '@plarin/design';
import { CellLoader } from '@plarin/inputs';
import { AdsPreview } from '@plarin/inputs/src/table/cell-renderers/two-lines-renderer/components/ads-preview';
import { ManageVkTabNameEnum, isUrlProd } from '@plarin/utils';
import type { GridApi, ICellRendererParams } from 'ag-grid-community';
import clsx from 'clsx';
import { toJS } from 'mobx';
import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppDataStore } from '../app-data-provider';
import { FirstLineContent } from './components/FirstLineContent';
import { SecondLineContent } from './components/SecondLineContent';
import classes from './style.module.scss';
import { navigationDict } from './utils';

interface SwitchButtonProps {
  createSwitchButton: (tabName: ManageVkTabNameEnum, data: any, gridApi: GridApi) => React.ReactNode;
  tabName: ManageVkTabNameEnum;
  data: any;
  api: GridApi;
  classes: any;
}

const SwitchButton: React.FC<SwitchButtonProps> = ({ createSwitchButton, tabName, data, api, classes }) => {
  return <div className={classes.switch}>{createSwitchButton(tabName, { ...data }, api)}</div>;
};

export interface NameNavigationCellProps extends ICellRendererParams {
  firstLine: string;
  secondLine: string;
  mayCopySecondLine?: boolean;
  type?: string;
  currentStatusName?: string;
  additionalInfoToCopy?: string;
  tabName?: string;
  createSwitchButton?: (tabName: ManageVkTabNameEnum, data: any, gridApi: GridApi) => React.ReactNode;
  isAdsPreview?: boolean;
  disableGroupCell?: true;
  isQuickFilter?: boolean;
  disableEditName?: boolean;
  column: any;
  cellName: string;
  selectedTab: ManageVkTabNameEnum;
}

export const NameNavigationCell = ({
  firstLine,
  secondLine,
  mayCopySecondLine,
  additionalInfoToCopy,
  type,
  currentStatusName,
  createSwitchButton,
  isAdsPreview,
  disableGroupCell,
  isQuickFilter = true,
  disableEditName,
  column,
  cellName,
  selectedTab,
  ...props
}: NameNavigationCellProps) => {
  const {
    manageVK: { setCurrentTab, currentTab, getSelectedIds, setSelectedIds },
  } = useAppDataStore();

  const { data } = props;
  const [isHoverCell, setIsHoverCell] = useState(false);

  const filterValue = isQuickFilter && props.api.getQuickFilter();

  const history = useHistory();
  const goToTab = useCallback((path: string) => window.location.pathname !== path && history.push(path), [history]);
  const tabNavigationHandler = () => {
    const nextTabConfig = navigationDict[selectedTab];
    const selectedIds = getSelectedIds(currentTab!);

    if (nextTabConfig) {
      setTimeout(() => {
        setCurrentTab(selectedTab);

        // Если чекбокс у строки уже выбран и мы делаем переход влево, не сбрасываем этот чекбокс при клике.
        selectedIds.includes(data.key) && setSelectedIds([data.key], currentTab!);

        currentTab === selectedTab ? goToTab(nextTabConfig.nextCellName) : goToTab(cellName);
      });
    }
  };

  if (new Set(Object.keys(data)).has('orgHierarchy') && data.isGroupRow && !disableGroupCell)
    return <Typography weight={600}>{data[firstLine]}</Typography>;
  if (currentStatusName && data.syncStatusCode === 'loading') return <CellLoader />;

  return (
    <div
      className={clsx(classes.container)}
      onMouseEnter={() => setIsHoverCell(true)}
      onMouseLeave={() => setIsHoverCell(false)}
      onClick={() => !isUrlProd && console.log(toJS(data))}
    >
      <div className={clsx(classes.leftBlock, createSwitchButton && classes.leftBlockSpaceBetween)}>
        {isAdsPreview && <AdsPreview data={data} />}

        <div className={clsx(classes.wrap)}>
          <FirstLineContent
            data={data}
            firstLine={firstLine}
            currentStatusName={currentStatusName}
            filterValue={filterValue}
            tabNavigationHandler={tabNavigationHandler}
            isHoverCell={isHoverCell}
            setIsHoverCell={setIsHoverCell}
            column={column}
            disableEditName={disableEditName}
            cellName={cellName}
            props={props}
          />
          {(data[secondLine] || data[secondLine] === null) && (
            <SecondLineContent
              data={data}
              secondLine={secondLine}
              copySecondLine={mayCopySecondLine}
              additionalInfoToCopy={additionalInfoToCopy}
              filterValue={filterValue}
              currentStatusName={currentStatusName}
            />
          )}
        </div>

        {Object.keys(data).length
          ? createSwitchButton && (
              <SwitchButton
                createSwitchButton={createSwitchButton}
                tabName={props.tabName as ManageVkTabNameEnum}
                data={data}
                api={props.api}
                classes={classes}
              />
            )
          : null}
      </div>
    </div>
  );
};
