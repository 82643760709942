import { WsAvatar, WsItemType, Typography } from '@plarin/design';
import { EllipsisTooltip } from '@plarin/inputs';
import React from 'react';
import { WSRolesEnum } from '../../../../types/workspace/enum';
import { TWorkspaceAccessResp, WSMembers } from '../../../../types/workspace/types';
import classes from './style.module.scss';

type TitleDrawerProps = {
  member: TWorkspaceAccessResp | WSMembers;
};

export const TitleDrawer = ({ member }: TitleDrawerProps) => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { fname, lname, status, email, role } = member;
  const textPrimary = !fname && !lname && status !== 'blocked';

  return (
    <div className={classes.headerDrawer}>
      <div className={classes.titleMember}>
        <WsAvatar data={member} size={40} type={WsItemType.member} />
        <span className={classes.linesMember}>
          <EllipsisTooltip tooltipMessage={`${fname} ${lname}`}>
            <Typography
              weight={600}
              size="ModalTitle"
              ellips={true}
              color={status === 'blocked' ? 'TextSecondary' : 'TextPrimary'}
            >
              {fname} {lname}
            </Typography>
          </EllipsisTooltip>

          <EllipsisTooltip tooltipMessage={email}>
            <Typography
              color={textPrimary ? 'TextPrimary' : 'TextSecondary'}
              size="IntegrationSelectChannelMessage"
              ellips={true}
            >
              {email}
            </Typography>
          </EllipsisTooltip>
        </span>
      </div>
      <Typography
        componentProps={{ className: classes.leftBorder }}
        size="Caption"
        color={status === 'blocked' ? 'TextSecondary' : 'TextPrimary'}
      >
        {/* @ts-ignore*/}
        {status === 'blocked' ? 'Заблокирован' : WSRolesEnum[role]}
      </Typography>
    </div>
  );
};
