import { TextField } from '@mui/material';
import { CalendarIcon, CloseCircleIcon } from '@plarin/design';
import { shortDate } from '@plarin/utils';
import clsx from 'clsx';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { BidValidationMessage } from '../../../';
import { DatePicker } from '../../../../../date-picker';
import { Popover } from '../../../../../popover';
import commonClasses from '../../../common.module.scss';
import classes from './styles.module.scss';

export const DateInput = ({
  setDate,
  initialDate,
  hasError,
  minDate,
  resetValue,
  validationMessage,
  maxDate,
  isDisabled = false,
  datesAreDifferent,
}: {
  setDate: (newDate: Date | null) => void;
  initialDate: Date | null;
  hasError?: boolean;
  minDate?: null | Date;
  resetValue?: boolean;
  validationMessage: string;
  maxDate?: Date;
  isDisabled?: boolean;
  datesAreDifferent?: boolean;
}) => {
  const [isOpen, setOpen] = useState(false);
  const [value, setValue] = useState<Date | null>(initialDate);
  const [valueInput, setValueInput] = useState<string>(initialDate ? shortDate(+initialDate) : '');

  const [isDiff, setIsDiff] = useState(false);

  useEffect(() => {
    // если ставки у выбранных кампаний разные, то в инпуте вместо значения ставки будет плейсхолдер
    if (datesAreDifferent) {
      setValue(null);
      setValueInput('');
      setIsDiff(true);
    }
  }, [datesAreDifferent]);

  const anchorEl = useRef(null);

  useEffect(() => {
    if (resetValue) {
      setValueInput('');
      setValue(null);
      setDate(null);
      setIsDiff(false);
    }
  }, [resetValue]);

  const onToggle = useCallback(() => {
    setOpen(value => !value);
  }, []);

  const onClose = useCallback(() => {
    setOpen(false);
  }, []);

  const setDefaultValue = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      setValueInput('');
      setValue(null);
      setDate(null);
      setIsDiff(false);
    },
    [setDate],
  );

  const applyDate = () => {
    onClose();
    if (value) {
      setValueInput(shortDate(+new Date(value)));
      setDate(value);
      setIsDiff(false);
    }
  };

  return (
    <>
      <div className={classes.dateInputContainer}>
        <TextField
          ref={anchorEl}
          onClick={onToggle}
          value={valueInput}
          size="small"
          className={classes.input}
          placeholder={isDiff ? 'Разные значения' : '__.__.____'}
          disabled={isDisabled}
          InputProps={{
            endAdornment:
              !isDiff && !isDisabled ? (
                <div className={classes.inputIcons}>
                  {valueInput && <CloseCircleIcon onClick={setDefaultValue} className={classes.icon} />}
                  <CalendarIcon className={classes.icon} />
                </div>
              ) : undefined,
            error: hasError,

            classes: {
              root: clsx(commonClasses.inputWrap, commonClasses.inputNumericWrap),
              input: commonClasses.inputInput,
              notchedOutline: classes.notchedOutline,
              focused: commonClasses.inputFocused,
              error: commonClasses.inputError,
              disabled: commonClasses.disabledInput,
            },
          }}
        />

        {hasError && <BidValidationMessage text={validationMessage} />}
      </div>

      {!isDisabled && (
        <Popover
          zIndex={1400}
          marginTop={0}
          classNameWrapper={classes.paper}
          isOpen={isOpen}
          anchorEl={anchorEl.current}
          setIsOpen={onClose}
        >
          <DatePicker
            isOpen={isOpen}
            setValue={setValue}
            value={value}
            applyDate={applyDate}
            onClose={onClose}
            minDate={minDate}
            maxDate={maxDate}
          />
        </Popover>
      )}
    </>
  );
};
