import { Typography } from '@plarin/design';
import { ICellRendererParams } from 'ag-grid-community';
import clsx from 'clsx';
import React from 'react';
import { formatObjective } from '../../../edit-bid/strategy-form-utils';
import { HightLightText } from '../../../hightlight-text';
import { EllipsisTooltip } from '../../../tooltip';
import classes from './style.module.scss';

type GroupCellRendererParams = {
  firstLine: string;
  page: string;
  isNoneCounter: boolean;
  classNames?: string;
  SettingsCellMenu: (data: any) => JSX.Element;
} & ICellRendererParams;

export const GroupNameRenderer = ({ SettingsCellMenu, classNames, ...props }: GroupCellRendererParams) => {
  const filterValue = props.api.getQuickFilter();
  const adObjectiveType = formatObjective({ adPlanObjective: props.data[props.firstLine] });
  const groupName = adObjectiveType ? `${adObjectiveType}` : props.data[props.firstLine];

  return (
    <div
      className={clsx(
        classes.groupNameWrapper,
        props.data.orgHierarchy?.length === 2 && classes.paddingLevel,
        props.data.orgHierarchy?.length === 3 && classes.paddingLevel2,
        classNames,
      )}
    >
      <EllipsisTooltip tooltipMessage={groupName}>
        <HightLightText weight={600} text={groupName || 'Не указано'} filterValue={filterValue} textEllips={true} />
      </EllipsisTooltip>
      {!props.isNoneCounter && (
        <Typography componentProps={{ className: classes.groupName }} color="TextSecondary">
          ({Object.keys(props.node.childrenAfterFilter || {}).length})
        </Typography>
      )}
      {SettingsCellMenu && <SettingsCellMenu data={props?.data} page={props.page} gridApi={props.api} />}
    </div>
  );
};
