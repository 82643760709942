import { InCenter } from '@plarin/inputs';
import React from 'react';
import { AuthContentResetTemplate } from '../auth-content-template';
import { AuthLayoutTemplate } from '../auth-layout-template';
import authResetPasswordImageSrc from './images/auth-reset-password-image.svg';

export const AuthResetPageTemplate: React.FC<{
  confirm?: boolean;
  children: React.ReactNode;
}> = ({ children }) =>
  AuthLayoutTemplate({
    sidebar: (
      <InCenter>
        <img src={authResetPasswordImageSrc} alt="" />
      </InCenter>
    ),
    children: AuthContentResetTemplate({ children }),
  });
