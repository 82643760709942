import React from 'react';
import classes from './style.module.scss';

type ActionBarProps = React.HTMLAttributes<HTMLDivElement>;


export const ActionBar: React.FC<ActionBarProps> = ({ children, ...props }) => {
  return (
    <div {...props} className={classes.actionBar}>
      {children}
    </div>
  );
};
