import { Typography } from '@plarin/design';
import React from 'react';
import classes from './style.module.scss';

type ProfileBlockProps = {
  title?: string;
  subTitle?: string;
  children?: React.ReactNode;
};

export const ProfileBlockWrapper: React.FC<ProfileBlockProps> = ({ title, subTitle, children }) => {
  return (
    <div className={classes.profileBlockWrapper}>
      <div className={classes.title}>
        <Typography size="AuthSubheader" weight={700}>
          {title}
        </Typography>
      </div>
      <div className={classes.hr} />
      {subTitle && (
        <div className={classes.subTitle}>
          <Typography size="AuthSubheader">{subTitle}</Typography>
        </div>
      )}
      <div className={classes.content}>{children}</div>
    </div>
  );
};
