import { getGridApi, GridApi } from '@plarin/inputs';
import { switchFilter } from '@plarin/utils';
import { useEffect } from 'react';

export const useFilter = (filterValue?: string, gridApi?: GridApi, trigger?: any) => {
  useEffect(() => {
    if (gridApi && getGridApi(gridApi)) {
      const wordsSearchMetrics = filterValue && filterValue.replace(/\s+/g, ' ').trim().split(' ');

      if (wordsSearchMetrics && wordsSearchMetrics.length > 1) {
        const resFilter = wordsSearchMetrics.map(elem => {
          return elem.trim();
        });

        gridApi.setGridOption('quickFilterText', resFilter.join(' '));

        gridApi.setGridOption('quickFilterMatcher', (quickFilterParts, rowQuickFilterAggregateText) => {
          // Преобразуем текст строки с учетом фильтра с переключенной раскладкой
          const combinedFilterText = quickFilterParts.join(' ');
          const switchedPhrase = resFilter.map(elem => switchFilter(elem)).join(' ');
          // Сначала проверяем по оригинальному тексту, затем переключаем раскладку и проверяем снова
          const matchesOriginal = rowQuickFilterAggregateText.toLowerCase().includes(combinedFilterText.toLowerCase());
          const matchesSwitched = rowQuickFilterAggregateText.toLowerCase().includes(switchedPhrase.toLowerCase());


          // Если совпало по любому из фильтров (оригинальный или с переключенной раскладкой), то возвращаем true
          return matchesOriginal || matchesSwitched;
        });
      } else {
        const switchFilteredValue = filterValue ? switchFilter(filterValue) : '';
        gridApi.setGridOption('quickFilterText', filterValue || '');
        gridApi.setGridOption('quickFilterMatcher', (quickFilterParts, rowQuickFilterAggregateText) => {
          // Преобразуем текст строки с учетом фильтра с переключенной раскладкой
          const combinedFilterText = quickFilterParts.join(' ');

          // Сначала проверяем по оригинальному тексту, затем переключаем раскладку и проверяем снова
          const matchesOriginal = rowQuickFilterAggregateText.toLowerCase().includes(combinedFilterText.toLowerCase());
          const matchesSwitched = rowQuickFilterAggregateText.toLowerCase().includes(switchFilteredValue.toLowerCase());


          // Если совпало по любому из фильтров (оригинальный или с переключенной раскладкой), то возвращаем true
          return matchesOriginal || matchesSwitched;
        });
      }
      gridApi.getDisplayedRowCount() === 0 ? gridApi.showNoRowsOverlay() : gridApi.hideOverlay();
    }
  }, [gridApi, filterValue, trigger]);
};
