import { WsItemType } from '@plarin/design';
import { getMemberName } from '@plarin/utils';
import { ICellRendererParams } from 'ag-grid-community';
import React from 'react';
import { Skeleton } from '../../../preloader';
import { AvatarsInRowCell } from '../avatars-in-row-cell';

interface Props extends ICellRendererParams {
  data: any; // TGetTeamByIdResponse
  wsMembers: any[]; // TWorkspaceMembers
}

export const TeamMembersRenderer = (props: Props) => {
  const { node, wsMembers } = props;

  const membersInTeam: { avatar_color: string; id: string; name: string; status: string }[] = wsMembers
    .filter(member => node.data.member_ids.includes(member._id))
    .map(item => {
      return {
        avatar_color: item.avatar_color,
        role: item.role,
        id: item._id,
        status: item.status,
        name: getMemberName({ fname: item.fname, lname: item.lname, email: item.email }),
        fname: item.fname,
        lname: item.lname,
        email: item.email,
      };
    });

  if (
    (node.data?.cellLoadingName && node?.data?.cellLoadingName?.includes('members')) ||
    node.data?.cellLoadingName?.includes('name')
  )
    return <Skeleton height={17} size="maxLong" />;

  return (
    <AvatarsInRowCell
      header="Участники команды"
      itemsData={membersInTeam}
      itemsType={WsItemType.member}
      node={node}
      avatarShape="circle"
    />
  );
};
