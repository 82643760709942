import { EmptyTableFindIcon, Typography } from '@plarin/design';
import classes from './search-stub.module.scss';

export const SearchStub = () => {
  return (
    <div className={classes.searchBlock}>
      <EmptyTableFindIcon />
      <div className={classes.text}>
        <Typography size="AuthSubheader" color="TextSecondary" align="center">
          В соответствии с вашим запросом
          <br />
          ничего не найдено.
        </Typography>
      </div>
    </div>
  );
};
