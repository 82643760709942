import React, { useEffect } from 'react';

export type TPageProps = {
  title: string;
  children: React.ReactNode;
};

export const Page: React.FC<TPageProps> = ({ children, title }) => {
  useEffect(() => {
    window.document.title = title;
  }, [title]);

  return <>{children}</>;
};
