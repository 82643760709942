import { Typography } from '@plarin/design';
import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { ListItem } from './list-item';
import classes from './style.module.scss';
import { IPopover } from './type';
import { useEscape } from './useEscape';
import { usePosition } from './usePosition';

const root = document.getElementById('root') as HTMLElement;

export const Popover = ({ isOpen, ...res }: IPopover & React.HTMLAttributes<HTMLDivElement> & { isOpen: boolean }) => {
  return <>{isOpen && <DropdownContent {...res} />}</>;
};

const DropdownContent = ({
  sizeTextItem,
  sizeText,
  classNameWrapper,
  titleText,
  title,
  footerDefaultText,
  footer,
  dataList,
  setIsOpen,
  anchorEl,
  isListLeft,
  marginTop = 8,
  marginLeft = 0,
  classNameListItem,
  children,
  onEscape,
  upTopElement,
  zIndex,
  observerSize,
  isAutoHorizon,
  isRight,
  isOpenBottom,
  ...res
}: IPopover & React.HTMLAttributes<HTMLDivElement>) => {
  const [haveScroll, setHaveScroll] = useState<boolean>(false);
  const [zIndexDefault, setZIndexDefault] = useState<number | undefined>(-1);
  const ref = useRef<HTMLDivElement>(null);
  const refBoxListItem = useRef<HTMLDivElement>(null);

  useEscape(onEscape);
  const { topWrapper, leftWrapper } = usePosition({
    anchorEl,
    isListLeft,
    refBoxListItem,
    marginTop,
    ref,
    setHaveScroll,
    upTopElement,
    observerSize,
    isAutoHorizon,
    isRight,
    isOpenBottom,
  });

  function resizeWindowAuto(e: Event) {
    setIsOpen(e);
  }
  useEffect(() => {
    setTimeout(() => {
      setZIndexDefault(zIndex ? zIndex + 1 : undefined);
    }, 10);

    window.addEventListener('resize', resizeWindowAuto);

    return () => {
      window.removeEventListener('resize', resizeWindowAuto);
    };
  }, []);

  const styleWrapper = {
    top: `${topWrapper}px`,
    left: `${leftWrapper + marginLeft}px`,
    zIndex: zIndexDefault,
  };

  return ReactDOM.createPortal(
    <>
      <div
        style={zIndex ? { zIndex } : undefined}
        className={classes.wrapperDropdown}
        onClick={e => {
          setIsOpen(e);
        }}
      ></div>
      <div
        {...res}
        ref={ref}
        style={styleWrapper}
        className={clsx(classes.wrapper, !!classNameWrapper && classNameWrapper)}
      >
        {titleText && (
          <Typography
            size="AuthContent"
            color="TextPrimary"
            weight={700}
            ellips={true}
            componentProps={{ className: classes.headerTitle }}
          >
            {titleText}
          </Typography>
        )}
        {title && <div>{title}</div>}
        {children && children}
        <div style={{ marginRight: haveScroll ? '6px' : 0 }} ref={refBoxListItem} className={classes.boxListItem}>
          <ListItem dataList={dataList} classNameListItem={classNameListItem} sizeTextItem={sizeTextItem} />
        </div>
        {footer && ( // если футер должен быть иным передаеем сюда
          <div>
            <hr className={classes.divider} />
            {footer}
          </div>
        )}
        {footerDefaultText && (
          <div className={classes.footerBox}>
            <hr className={classes.divider} />
            <Typography
              componentProps={{ className: classes.footerItem }}
              color="TextSecondary"
              size={`${sizeText ? sizeText : 'Main'}`}
              ellips={true}
            >
              {footerDefaultText}
            </Typography>
          </div>
        )}
      </div>
    </>,
    root,
  );
};
