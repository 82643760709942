import { Avatar, WsAvatar, WsItemType, AvatarsIconTypes, Typography } from '@plarin/design';
import { switchFilter, getMemberName } from '@plarin/utils';
import { ICellRendererParams } from 'ag-grid-community';
import React from 'react';
import { HightLightText } from '../../../hightlight-text';
import { EllipsisTooltip } from '../../../tooltip';
import classes from './style.module.scss';

interface Props extends ICellRendererParams {
  wsMembers: any; // TWorkspaceMembers
  data: any; // TGetTeamByIdResponse
}

// Раздел "Проекты" (не в воркспесах, а корневой раздел, где в главном меню указываются все проекты)
export const SimpleCircleAvatarNameRenderer = (props: ICellRendererParams) => {
  const name = getMemberName({ fname: props.value?.fname, lname: props.value?.lname, email: props.value?.email });
  return (
    <div className={classes.wrap} onClick={() => console.log('props', props)}>
      {props.value ? (
        <WsAvatar data={props.value} type={WsItemType.member} />
      ) : (
        // пользователь, не принявший запрос в воркспейс, сюда приходит в виде очень паршивого объекта, в котором нет никаких данных. Как начнём приводить раздел обзора проектов в порядок, нужно попросить бэк починить это
        <Avatar avatarIconType={AvatarsIconTypes.none} />
      )}

      <EllipsisTooltip tooltipMessage={name}>
        <Typography size="Caption" ellips>
          {name}
        </Typography>
      </EllipsisTooltip>
    </div>
  );
};

// ячейка менеджера проекта или лидера команды
export const CircleAvatarNameRenderer = ({ node, wsMembers, data, api }: Props) => {
  const filterValue = api.getQuickFilter();
  // @ts-ignore
  const leader = wsMembers.find(member => member._id === data.leader_id || member._id === data.manager_id);
  const name = getMemberName({ fname: leader?.fname, lname: leader?.lname, email: leader?.email });
  return (
    <div className={classes.wrap}>
      {leader && (
        <WsAvatar
          data={leader}
          type={WsItemType.member}
          forceGray={node.data.status === 'inactive' || node.data.is_archived}
        />
      )}

      <div className={classes.name}>
        {leader ? (
          <EllipsisTooltip tooltipMessage={name}>
            <HightLightText size="Caption" weight={400} textEllips={true} text={name} filterValue={filterValue} />
          </EllipsisTooltip>
        ) : (
          <Typography color="TextSecondary" weight={400} componentProps={{ className: classes.secondaryName }}>
            —
          </Typography>
        )}
      </div>
    </div>
  );
};
