import Typograf from 'typograf';

// https://github.com/typograf/typograf/blob/v7.4.1/docs/RULES.ru.md - правила для типографа версии 7.4.1

// стандартный набор правил для типографирования. Использовать для тех текстов, где допустимы пунктуационные и орфографически замены. Этот типограф подходит для текста с информацией, изложенной в одном или нескольких предложениях.
export const typografText = (text: string) => {
  const tp = new Typograf({ locale: ['ru', 'en-US'] });
  return tp.execute(text);
};

// Урезанный набор правил для типографирования. Этот вариант типографирования - для названий, тэгов, имён собственных.
// В основном меняет тире/дефисы, расставляет неразрывные пробелы. Текст остаётся максимально приближенным к тому, что написал пользователь.
export const typografNames = (text: string) => {
  const tp = new Typograf({ locale: ['ru', 'en-US'] });

  tp.disableRule('ru/date/fromISO');
  tp.disableRule('common/number/fraction');
  tp.disableRule('common/number/mathSigns');
  tp.disableRule('common/number/times');
  tp.disableRule('common/punctuation/delDoublePunctuation');
  tp.disableRule('ru/date/weekday');
  tp.disableRule('ru/money/currency');
  tp.disableRule('ru/money/ruble');
  tp.disableRule('ru/nbsp/centuries');
  tp.disableRule('ru/nbsp/m');
  tp.disableRule('ru/number/ordinals');
  tp.disableRule('ru/other/phone-number');

  // менеджмент пробелов до и после пунктуационных знаков
  tp.disableRule('common/space/delLeadingBlanks');
  tp.disableRule('common/space/delBetweenExclamationMarks');
  tp.disableRule('common/space/delBeforePunctuation');
  tp.disableRule('common/space/delBeforePercent');
  tp.disableRule('common/space/delBeforeDot');
  tp.disableRule('common/space/bracket');
  tp.disableRule('common/space/beforeBracket');
  tp.disableRule('common/space/afterSemicolon');
  tp.disableRule('common/space/afterQuestionMark');
  tp.disableRule('common/space/afterExclamationMark');
  tp.disableRule('common/space/afterComma');
  tp.disableRule('common/space/afterColon');
  tp.disableRule('common/punctuation/quote');
  tp.disableRule('common/space/squareBracket');
  tp.disableRule('ru/space/year');
  tp.disableRule('ru/symbols/NN');

  // замена символов
  tp.disableRule('common/symbols/arrow');
  tp.disableRule('common/symbols/cf');
  tp.disableRule('common/symbols/copy');
  tp.disableRule('ru/typo/switchingKeyboardLayout');

  // исправление пунктуации
  tp.disableRule('ru/dash/ka');
  tp.disableRule('ru/dash/kakto');
  tp.disableRule('ru/dash/koe');
  tp.disableRule('ru/dash/de');
  tp.disableRule('ru/dash/month');
  tp.disableRule('ru/dash/to');
  tp.disableRule('ru/nbsp/years');
  tp.disableRule('ru/number/comma');
  tp.disableRule('ru/punctuation/ano');
  tp.disableRule('ru/punctuation/exclamation');
  tp.disableRule('ru/punctuation/exclamationQuestion');
  tp.disableRule('ru/punctuation/hellipQuestion');
  tp.disableRule('ru/space/afterHellip');
  tp.disableRule('common/punctuation/hellip');

  return tp.execute(text);
};

export const typografElem = (elem: HTMLElement) => {
  const tp = new Typograf({ locale: ['ru', 'en-US'] });
  elem.innerHTML = tp.execute(elem.innerHTML);
};
