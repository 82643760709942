import { ArrowUpIcon, Typography } from '@plarin/design';
import { Button, Popover } from '@plarin/inputs';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import React, { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { TMembersTeams } from '../../../../../../../types/projects/projects';
import { TEAMS } from '../../../../../../routing/router-path';
import { menuSectionIndexes } from '../../../../../../utils/constants';
import { useAppDataStore } from '../../../../../app-data-provider';
import classes from '../project-info.module.scss';
import { CustomButtonText } from '../uicomponents/custom-button-text';
import { ProjectPopupTeams } from './components/project-popup-teams';
import { MemberItem } from './member-item';
import { TeamItem } from './team-item';

export const TeamsAndMembersSection = observer(() => {
  const anchorRef = useRef(null);
  const history = useHistory();

  const {
    projects: {
      teams,
      teamsState,
      setTeamsState,
      editProject,
      statusWSroles,
      setIsOpenDrawer,
      setInitialTeamsState,
      setIsDrawerDataChanged,
    },
    teams: { teamsData },
    members: { members },
    menu: { selectMenuItem },
  } = useAppDataStore();
  const { isAdmin, isManager, isLeader } = statusWSroles;
  const isHidden = isLeader || isManager || isAdmin;

  const redirectToTeams = () => {
    setIsOpenDrawer(false);
    history.push(TEAMS);
    selectMenuItem(TEAMS, false, menuSectionIndexes.ws);
  };

  const handleClosePopover = () => {
    setTeamsState('isOpenModalTeams', false);
    setTeamsState('searchValue', '');
  };

  useEffect(() => {
    let selectedTeamsMember: { [index: string]: string[] } = {};
    teamsState.selectedTeams.map(team => {
      team.member_ids.map(memberTeamId => {
        if (selectedTeamsMember.hasOwnProperty(memberTeamId)) {
          selectedTeamsMember[memberTeamId] = [...selectedTeamsMember[memberTeamId], team.name];
        } else {
          selectedTeamsMember[memberTeamId] = [team.name];
        }
      });
    });

    // участники всех команд, включённых в проект
    let teamsMember: TMembersTeams[] = members.reduce((acc, el): TMembersTeams[] => {
      if (selectedTeamsMember.hasOwnProperty(el._id)) {
        return [
          ...acc,
          {
            id: el._id,
            fname: el.fname || '',
            lname: el.lname || '',
            avatarColor: el.avatar_color,
            avatar_color: el.avatar_color,
            teamName: selectedTeamsMember[el._id],
            role: el.role,
            email: el.email,
            status: el.status,
          },
        ];
      } else {
        return acc;
      }
    }, [] as TMembersTeams[]);
    setTeamsState('selectedMembersTeam', teamsMember);
  }, [teamsState.selectedTeams]);

  useEffect(() => {
    editProject.editProjectData &&
      setTeamsState(
        'selectedTeams',
        teams.teams
          .filter(el => {
            return editProject.editProjectData?.team_ids?.includes(el._id);
          })
          .map(el => {
            return {
              id: el._id,
              name: el.name,
              avatar_color: el.avatar_color,
              member_ids: el.member_ids,
              status: el.status,
            };
          }),
      );
    setIsDrawerDataChanged(false);
    setInitialTeamsState();
  }, [editProject]);

  if (!teamsData.length) {
    return (
      <div className={clsx(classes.infoBlock)}>
        <span className={classes.infoTitleBox}>
          <Typography color="TextPrimary" weight={600} size="Main" align="left">
            Команды
          </Typography>
        </span>
        <span className={classes.infoTextBox}>
          <Typography color="TextTertiary" weight={400} size="AuthContent" align="left">
            У вас еще не создана ни одна команда в пространстве
          </Typography>
        </span>

        {isHidden && (
          <Button className={classes.buttonAddForm} onClick={redirectToTeams}>
            <div className={classes.addItem}>
              <Typography size="AuthContent" weight={600} color="TextButton">
                Перейти к настройкам команд
              </Typography>
              <ArrowUpIcon className={classes.arrowIcon} />
            </div>
          </Button>
        )}
      </div>
    );
  }

  return (
    <>
      <div className={clsx(classes.infoBlock, teamsState.selectedTeams.length && classes.infoBlockTextNone)}>
        <span className={clsx(classes.infoTitleBox, teamsState.selectedTeams.length && classes.buttonRight)}>
          <Typography color="TextPrimary" weight={600} size="Main" align="left">
            Команды
          </Typography>
          {isHidden && (
            <div ref={anchorRef}>
              <CustomButtonText
                onClickButton={() => setTeamsState('isOpenModalTeams', true)}
                isOpen={teamsState.isOpenModalTeams}
              >
                Добавить команду
              </CustomButtonText>
            </div>
          )}
        </span>
        {!teamsState.selectedTeams.length && (
          <span className={classes.infoTextBox}>
            <Typography color="TextTertiary" weight={400} size="AuthContent" align="left">
              В проект не добавлена еще ни одна команда
            </Typography>
          </span>
        )}
      </div>

      {!!teamsState.selectedTeams.length && (
        <div className={classes.boxScrollProject}>
          {teamsState.selectedTeams.map(el => {
            return (
              <TeamItem
                isArchived={!editProject.editProjectData?.is_archived && isHidden}
                key={el.id}
                team={el}
                setSelectedTeams={e => setTeamsState('selectedTeams', e)}
                selectedTeams={teamsState.selectedTeams}
              />
            );
          })}
        </div>
      )}
      <Popover
        isListLeft
        setIsOpen={handleClosePopover}
        onEscape={handleClosePopover}
        anchorEl={anchorRef.current}
        isOpen={teamsState.isOpenModalTeams}
      >
        <ProjectPopupTeams
          selectedTeams={teamsState.selectedTeams}
          setSelectedTeams={e => setTeamsState('selectedTeams', e)}
        />
      </Popover>

      {!!teamsState.selectedTeams.length && !!teamsState.selectedMembersTeam.length && (
        <div className={clsx(classes.infoBlock)}>
          <span className={clsx(classes.infoTitleBox, classes.membersTitleBlock)}>
            <Typography color="TextPrimary" weight={600} size="Main" align="left">
              Участники
            </Typography>
          </span>
          <div className={classes.boxScrollProject}>
            <div className={classes.projectBlockList}>
              {teamsState.selectedMembersTeam.map(el => {
                return <MemberItem key={el.id} member={el} />;
              })}
            </div>
          </div>
        </div>
      )}
    </>
  );
});
