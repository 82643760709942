import { Typography } from '@plarin/design';
import { IHeaderParams, ColumnPinnedType } from 'ag-grid-community';
import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';
import { Pin } from '../../../pin';
import { EllipsisTooltip } from '../../../tooltip';
import { saveColumnState } from '../../utils';
import classes from './style.module.scss';

interface HeaderNameRendererProps extends IHeaderParams {
  subheader?: string;
  alignY?: string;
  isPinned: boolean;
}

interface IConfig {
  state?: { colId: string; pinned: ColumnPinnedType }[];
  defaultState: { pinned: null };
}

export const HeaderNameRenderer = (props: HeaderNameRendererProps) => {
  const [sorting, setSort] = useState<string | undefined | null>(props.column?.getSort());
  const refButton = useRef<HTMLDivElement>(null);
  const [isColumnPinned, setIsColumnPinned] = useState(false);
  const onMenuClicked = () => props.showColumnMenu(refButton.current as HTMLElement);

  let menu = null;
  if (props.enableMenu) {
    menu = (
      <div ref={refButton} className="customHeaderMenuButton" onClick={() => onMenuClicked()}>
        <i className="fa someMenu" />
      </div>
    );
  }
  const position = props.column.getColDef().type;
  const className = clsx(
    [
      sorting === 'asc' && classes.sortAsc,
      sorting === 'desc' && classes.sortDesc,
      classes.head,
      !props.alignY && classes.headTwoRows,
    ],
    [
      position === 'rightAligned' && classes.right,
      position === 'leftAligned' && classes.left,
      position === '' && classes.center,
    ],
  );

  useEffect(() => {
    if (props.column) {
      if (props.isPinned) {
        setIsColumnPinned(!!props.column?.getPinned());
      }
      const sortChanged = () => setSort(props.column?.getSort());
      props.column?.addEventListener('sortChanged', sortChanged);

      return () => {
        props.column?.removeEventListener('sortChanged', sortChanged);
      };
    }
  }, []);

  const onTogglePin = (isColumnPinned: boolean) => {
    const columId = props.column.getColId();

    const gonnaPinThisColumn = !isColumnPinned;
    setIsColumnPinned(!isColumnPinned);

    const config: IConfig = {
      // state: [{ colId: 'checkbox', pinned: 'left' }],
      state: [],
      defaultState: { pinned: null },
    };

    if (columId) {
      if (gonnaPinThisColumn) {
        config.state?.push({ colId: columId, pinned: 'left' });
      }

      props.api.applyColumnState(config);
      props.api.moveColumns([columId], 1);

      props.api.ensureColumnVisible(props.api?.getColumn(columId) ?? '');
    }

    saveColumnState(props.api);
  };

  return (
    <>
      <div
        className={clsx(className, props.column.getColDef().sortable && classes.pointer)}
        onClick={event => {
          if (!props.column.getColDef().sortable) return;
          props.progressSort(event.shiftKey);
        }}
      >
        <div className={clsx(classes.headerContainer)}>
          <EllipsisTooltip
            tooltipMessage={props.displayName}
            classNameContainer={classes.resetEllipsis}
            classNameTooltip={clsx(
              [
                position === 'rightAligned' && classes.right,
                position === 'leftAligned' && classes.left,
                position === '' && classes.center,
              ],
              classes.headerValue,
            )}
          >
            <Typography ellips={true} weight={600}>
              {props.displayName}
            </Typography>
          </EllipsisTooltip>

          {!props.alignY && (
            <EllipsisTooltip
              tooltipMessage={props.subheader || ''}
              classNameContainer={classes.resetEllipsis}
              classNameTooltip={clsx([
                position === 'rightAligned' && classes.right,
                position === 'leftAligned' && classes.left,
                position === '' && classes.center,
              ])}
            >
              <Typography size="TableRowSubHeader" ellips={true} weight={400} color="TextSecondary">
                {props.subheader}
              </Typography>
            </EllipsisTooltip>
          )}
        </div>
        {props.enableSorting && (
          <>
            <span className={clsx(['ag-icon', 'ag-icon-asc', classes.asc])} unselectable="on" role="presentation" />
            <span className={clsx(['ag-icon', 'ag-icon-desc', classes.desc])} unselectable="on" role="presentation" />
          </>
        )}
        {menu}
      </div>
      {props.isPinned && <Pin isColumnPinned={isColumnPinned} togglePin={onTogglePin} />}
    </>
  );
};
