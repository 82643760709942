import { InputPasswordErrors, TypeInput, useDebounce, useInput } from '@plarin/utils';
import React, { useEffect } from 'react';
import { InputPassword } from '../input-password';
import { Input } from '../inputs';
import { TSetModalAction, ModalActionNamesEnum } from '../modal';
import classes from './style.module.scss';
import { TypeChangePassword, TypeNewPasswordComplexityStyle, TypePasswordData } from './types';

type TSetNewPassword = {
  setModalAction: TSetModalAction;
  changeProfilePassword: (data: TypeChangePassword) => Promise<void>;
  newPasswordStyle: TypeNewPasswordComplexityStyle;
  setNewPasswordStyle: (style?: TypeNewPasswordComplexityStyle) => TypeNewPasswordComplexityStyle;
  isLoading: boolean | undefined;
  setGlobalError: (error?: string | undefined) => string | undefined;
  globalError: string | undefined;
  checkNewPassword: (data: TypePasswordData) => Promise<void>;
};

export const SetProfilePasswordContent = ({
  setModalAction,
  changeProfilePassword,
  newPasswordStyle,
  setNewPasswordStyle,
  isLoading,
  setGlobalError,
  globalError,
  checkNewPassword,
}: TSetNewPassword) => {
  const password = useInput({
    initialValue: '',
    type: TypeInput.password,
  });
  // eslint-disable-next-line
  const {
    setError: setPasswordError,
    setTextError: setTextPasswordError,
    setHint: setPasswordHint,
    setWasChanged: setPasswordWasChanged,
    checkValue: passwordCheckValue,
    ...passwordInput
  } = password;

  const newPassword = useInput({
    initialValue: '',
    type: TypeInput.newPassword,
  });
  // eslint-disable-next-line
  const {
    setError: setNewPasswordError,
    setHint,
    setWasChanged,
    setTextError: setTextNewPasswordError,
    checkValue: newPasswordCheckValue,
    ...newPasswordInput
  } = newPassword;
  const debounce = useDebounce();
  const setAction = () => {
    setModalAction({
      action: () => {
        if (!password.value || password.error || !newPassword.value || newPassword.error) {
          !newPassword.value && setNewPasswordError(true);
          !password.value && setPasswordError(true);
        } else {
          changeProfilePassword({ password: password.value, new_password: newPassword.value! });
        }
      },
      isLoading: isLoading,
      actionTitle: ModalActionNamesEnum.Save,
    });
  };

  useEffect(() => {
    passwordCheckValue();
    newPasswordCheckValue();
    if (password.value && newPassword.value && password.value === newPassword.value) {
      setPasswordError(true);
      setNewPasswordError(true);
      setHint && setHint(InputPasswordErrors.newNotOld);
    }
    setAction();
  });

  useEffect(() => {
    globalError && setTextPasswordError && setTextPasswordError(globalError);
    !globalError && setTextPasswordError && setTextPasswordError('');
  }, [globalError, setTextPasswordError]);

  useEffect(() => {
    if (newPassword.value.length > 7 && !newPassword.error) {
      debounce(() => checkNewPassword({ password: newPassword.value }), 300);
    } else {
      setNewPasswordStyle(undefined);
    }
  }, [newPassword.error, newPassword.value, checkNewPassword, setNewPasswordStyle]);

  useEffect(() => {
    setGlobalError('');
  }, [password.value, setGlobalError]);

  return (
    <form className={classes.form}>
      <Input
        {...passwordInput}
        name="password"
        label="Текущий пароль"
        type="password"
        autoFocus
        fullWidth
        size={48}
        disableMinHeight={true}
      />
      <InputPassword
        {...newPasswordInput}
        passwordComplexity={newPassword.value.length > 7 ? newPasswordStyle : undefined}
        name="user_password"
        label="Новый пароль"
        type="newPassword"
        fullWidth
        autoComplete="newPassword"
        size={48}
        disableMinHeight={true}
      />
    </form>
  );
};
