import { observer } from 'mobx-react';
import React, { useLayoutEffect, useState } from 'react';
import { useRouteMatch } from 'react-router';
import { RedirectToRoot } from '../../routing/routes';
import { useAppDataStore } from '../app-data-provider';

interface CheckWorkspaceProps {
  children: React.ReactNode;
}

export const CheckWorkspaceLayout: React.FC<CheckWorkspaceProps> = observer(({ children }) => {
  const {
    profileStore: { workspaces },
  } = useAppDataStore();

  const { url } = useRouteMatch();

  const hasWorkspaceName = workspaces?.some(el => el.name.includes(url.split('/')[0])) || false;

  const [isViewChildren, setViewChildren] = useState<boolean>(hasWorkspaceName);

  useLayoutEffect(() => {
    hasWorkspaceName ? setViewChildren(true) : setViewChildren(false);
  }, [url]);

  return isViewChildren ? <> {children} </> : <RedirectToRoot />;
});
