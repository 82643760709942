import { FormControl, Select, SelectChangeEvent } from '@mui/material';
import {
  WsAvatar,
  WsItemType,
  Avatar,
  AvatarsIconTypes,
  SortDownSolidET,
  SortDownSolidETDisabled,
  Typography,
  IconStatus,
} from '@plarin/design';
import { useWindowSize } from '@plarin/utils';
import clsx from 'clsx';
import React, { SetStateAction, useEffect, useRef, useState } from 'react';
import { CustomMenuItem } from '../custom-menu-item';
import { EllipsisTooltip } from '../tooltip';
import classes from './style.module.scss';

type CustomSelectProps = {
  value: string;
  onChange: (value: SetStateAction<string | any>) => void;
  listVariants: ListVariants[];
  listWidth?: number; // принудительно задаем нужную ширину выпадающего списка. Без этого пропса список будет по ширине равен селекту, который его вызывает.
  className?: string;
  label?: string;
  ulPadding?: boolean;
  disabled?: boolean;
  disabledRead?: boolean;
  menuHeight: number; // нужно для позиционирования меню над или под самим селектом, так как получить высоту можно только после рендера
  useMemberAvatar?: boolean;
  shape?: 'circle' | 'square' | 'statusDot';
  removeFocusOnClose?: boolean;
};

export type ListVariants = {
  value: string;
  label: string;
  disabled?: boolean;
  iconColor?: any;
  iconType?: AvatarsIconTypes.none;
};

export const CustomSelect = ({
  value,
  onChange,
  listVariants,
  listWidth,
  className,
  label,
  ulPadding,
  menuHeight,
  disabled,
  disabledRead,
  useMemberAvatar,
  shape,
  removeFocusOnClose,
}: CustomSelectProps) => {
  const selectRef = useRef<any>(null);
  const selectHeightToTop = selectRef.current && selectRef.current.getBoundingClientRect().bottom;
  const { height } = useWindowSize();
  const [isOpen, setIsOpen] = useState(false);
  const [isTop, setIsTop] = useState(false);

  const listWidthValue = listWidth ? listWidth + 'px' : selectRef.current?.getBoundingClientRect().width + 'px';

  useEffect(() => {
    height - selectHeightToTop - menuHeight > 10 ? setIsTop(false) : setIsTop(true);
  }, [isOpen, selectRef, height]);

  return (
    <FormControl fullWidth className={className}>
      {(value || isOpen) && label && (
        <label
          className={clsx(
            classes.label,
            isOpen && classes.selectedValueLabel,
            disabledRead && classes.disabledReadLabel,
          )}
        >
          {label}
        </label>
      )}

      <Select
        disabled={disabled}
        renderValue={value => {
          const label = listVariants.filter(e => e.value === value);
          // TODO: добавить эллипсис. Починить пропадающую точку для длинного текста
          return (
            <Typography
              color="TextPrimary"
              componentProps={shape === 'statusDot' ? { className: classes.gap8 } : undefined}
            >
              {shape === 'statusDot' && <IconStatus status={label[0].iconColor} />}
              {label?.length ? label[0].label : 'Лидер команды - гость. Удалить!'}
            </Typography>
          );
        }}
        className={clsx(classes.inputWrap, classes.inputSelect, value && label && classes.pdLabel)}
        classes={{
          root: clsx(classes.root, value && label && classes.pdLabel, disabledRead && classes.disabledRead),
        }}
        value={value}
        onChange={(event: SelectChangeEvent) => {
          onChange(event.target.value);
        }}
        IconComponent={disabled ? SortDownSolidETDisabled : SortDownSolidET}
        ref={selectRef}
        onClose={() => {
          // при закрытии селекта из-за бага mui селект остаётся выбранным. Убираем фокус руками
          if (removeFocusOnClose) {
            setTimeout(() => {
              // @ts-ignore
              document.activeElement?.blur();
            }, 0);
          }

          setIsOpen(false);
        }}
        onOpen={() => setIsOpen(true)}
        MenuProps={{
          MenuListProps: { style: { width: listWidthValue } },
          classes: {
            root: classes.menuOverlay,
            paper: clsx(isTop ? classes.mb8 : classes.mt8, ulPadding && classes.ulPadding),
          },

          anchorOrigin: {
            vertical: isTop ? 'top' : 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: isTop ? 'bottom' : 'top',

            horizontal: 'left',
          },
        }}
        inputProps={{
          classes: {
            icon: classes.rotatable,
            iconOpen: classes.deg180,
          },
        }}
      >
        {listVariants.map((option, index) => {
          return (
            <CustomMenuItem
              key={option.value}
              value={option.value}
              className={clsx(classes.menuItem, option.value === value && classes.menuItemSelect)}
              tabIndex={index}
              selected={option.value === value}
              disabled={option.disabled}
            >
              <span
                className={clsx(
                  classes.leftContent,
                  option.value === value && classes.widthContent,
                  shape === 'statusDot' && classes.dotGap,
                )}
              >
                {shape === 'statusDot' && <IconStatus status={option.iconColor} />}
                {/* Дровер команды или проекта. 
                Выпадашка с выбором лидера или менеджера: ПЕРВЫЙ ПУНКТ "Без лидера" / "Без менеджера" с уникальной аватаркой */}
                {shape !== 'statusDot' && useMemberAvatar && option.iconType ? (
                  <Avatar
                    size={20}
                    shape={shape}
                    name={option.label}
                    backgroundColor={option.iconColor}
                    avatarIconType={option.iconType}
                  />
                ) : null}
                {/* Лидер команды в дровере команды или менеджер проекта в дровере проекта */}
                {shape !== 'statusDot' && useMemberAvatar && !option.iconType ? (
                  <WsAvatar size={20} data={option} type={WsItemType.member} />
                ) : null}
                <EllipsisTooltip tooltipMessage={option.label}>
                  <Typography size="AuthContent" ellips={true}>
                    {option.label}
                  </Typography>
                </EllipsisTooltip>
              </span>
            </CustomMenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
