import { GridApi } from '@plarin/inputs';
import { WSRolesEnum } from '../../types/workspace/enum';

const sortByStatusWorkspaces = (status: string, isArchived?: boolean) => {
  if (status === 'active' && !isArchived) {
    return 0;
  }
  if ((status === 'block' || status === 'completed') && !isArchived) {
    return 1;
  }
  if (isArchived) {
    return 2;
  } else {
    return 3;
  }
};

export const comporatorStatusWorkspaces = (a: string, b: string, isArchivedA?: boolean, isArchivedB?: boolean) => {
  return sortByStatusWorkspaces(a || '', isArchivedA) - sortByStatusWorkspaces(b || '', isArchivedB);
};

const sortRole = (role: WSRolesEnum) => {
  if (role === 'Владелец') return 0;
  if (role === 'Администратор') return 1;
  if (role === 'Сотрудник') return 2;
  return 3;
};

export const comporatorRole = (a: WSRolesEnum, b: WSRolesEnum) => {
  return sortRole(a) - sortRole(b);
};
