import { Typography } from '@plarin/design';
import { typografText } from '@plarin/utils';
import clsx from 'clsx';
import React from 'react';
import classes from './drawer.module.scss';

type TTitleOptions = {
  circle?: boolean;
  iconBlock?: JSX.Element;
  icon?: JSX.Element;
  titleName?: string | JSX.Element;
  defaultTitleName?: string;
  rightHead?: React.ReactNode;
  withTitleBox?: string;
  fixedTitle?: boolean;
};

export const TitleOptions = ({
  icon,
  titleName,
  defaultTitleName,
  rightHead,
  withTitleBox,
  iconBlock,
  circle,
  fixedTitle,
}: TTitleOptions) => {
  return (
    <div className={fixedTitle ? classes.optionHeadFixed : classes.optionHead}>
      <div className={classes.blockIconTitle}>
        {iconBlock && <span className={clsx(classes.boxIcon, circle && classes.circleIcon)}>{iconBlock}</span>}
        {icon}
        <span className={clsx(classes.linesTitleName, withTitleBox && withTitleBox)}>
          {defaultTitleName && (
            <Typography weight={600} size="DrawerProject">
              {typografText(defaultTitleName)}
            </Typography>
          )}
          {titleName}
        </span>
      </div>
      {rightHead}
    </div>
  );
};
