import { Typography } from '@plarin/design';
import { getNumeral, isThisYear } from '@plarin/utils';
import { format, differenceInMinutes, differenceInHours, differenceInDays, isValid } from 'date-fns';
import { ru } from 'date-fns/locale';
import React from 'react';

interface FormattedDateProps {
  dateString: string;
}

export const FormattedDate: React.FC<FormattedDateProps> = ({ dateString }) => {
  const date = new Date(dateString);

  if (!isValid(date)) {
    return null;
  }

  const now = new Date();
  const diffInMinutes = differenceInMinutes(now, date);
  const diffInHours = differenceInHours(now, date);
  const diffInDays = differenceInDays(now, date);

  const getComma = () => diffInMinutes >= 60 ? ',' : '';

  let dateText = '';

  if (diffInMinutes < 1) {
    dateText = 'Меньше минуты назад';
  } else if (diffInMinutes < 60) {
    dateText = `${diffInMinutes} ${getNumeral(diffInMinutes, ['минута', 'минуты', 'минут'])} назад`;
  } else if (diffInHours < 24) {
    dateText = 'Сегодня';
  } else if (diffInDays === 1) {
    dateText = 'Вчера';
  } else if (diffInDays === 2) {
    dateText = 'Позавчера';
  } else if (isThisYear(date, now)) {
    dateText = format(date, 'd MMMM', { locale: ru });
  } else {
    dateText = format(date, 'dd.MM.yyyy', { locale: ru });
  }

  return (
    <Typography size="Caption" color="TextPrimary">
      {dateText}{getComma()}
    </Typography>
  );
};

export const FormattedTime: React.FC<FormattedDateProps> = ({ dateString }) => {
  const date = new Date(dateString);

  if (!isValid(date)) {
    return null;
  }

  return (
    <Typography size="Caption" color="TextSecondary">
      {format(date, 'HH:mm')}
    </Typography>
  );
};
