import { isUrlProd, isUrlDev } from '@plarin/utils';
import React from 'react';
// import ReactDOM from 'react-dom';
import './index.scss';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { createBrowserHistory } from 'history';
import { App } from './components/app';
import { getYmCounter } from '@plarin/utils';
import { sentryErrors } from './utils/sentryErrors';
import { yamRunner } from './utils/ya-metric-runner';
import { createRoot } from 'react-dom/client';

const history = createBrowserHistory();

Sentry.init({
  dsn: 'https://730cb0c7a9e84b29a60fbf9be14c432e@sentry.plarin.ru/15',
  ignoreErrors: sentryErrors,
  environment: isUrlProd ? 'production' : 'development',
  release: 'App ver: ' + PACKAGE_VERSION,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
    }),
    new Sentry.Integrations.Breadcrumbs({
      console: isUrlProd,
    }),
  ],
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: isUrlProd ? 1 : 0.2,
});

const container = document.getElementById('root');
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(<App />);

// eslint-disable-next-line no-console
console.log(`App name: ${PACKAGE_NAME}`);
// eslint-disable-next-line no-console
console.log(`App version: ${PACKAGE_VERSION}`);
// eslint-disable-next-line no-console
console.log(`App mode: ${isUrlProd ? 'production' : 'development'}`);

if (isUrlProd || isUrlDev) {
  yamRunner(window, document, 'script', 'https://mc.yandex.ru/metrika/tag.js', 'ym');
  setTimeout(() => {
    // @ts-ignore
    window.ym(getYmCounter(), 'init', {
      trackLinks: true,
      accurateTrackBounce: true,
      webvisor: isUrlProd,
      defer: true,
    });
  }, 1000);
}
