import { RedoIcon } from '@plarin/design';
import { ActionTableButton, Button } from '@plarin/inputs';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { useAppDataStore } from '../../components/app-data-provider';
import { SwitcherActionButton } from '../workspace-teams/components/table-actions';
import classes from './style.module.scss';

type TProjectsActionBar = {
  hasAdminRights: boolean;
  onOpen: () => void;
  onChangeCompleted: (checked: boolean) => void;
  onChangeArchived: (checked: boolean) => void;
};

export const ProjectsActionBar = observer(
  ({ hasAdminRights, onOpen, onChangeCompleted, onChangeArchived }: TProjectsActionBar) => {
    const {
      projects: { getProjects, isLoading, isTableLoading },
    } = useAppDataStore();

    const [isArchiveShown, setIsArchiveShown] = useState(false);
    const [isFinishedShown, setIsFinishedShown] = useState(false);

    const changeArchive = () => {
      setIsArchiveShown(!isArchiveShown);
      onChangeArchived(!isArchiveShown);
    };
    const changeFinished = () => {
      setIsFinishedShown(!isFinishedShown);
      onChangeCompleted(!isFinishedShown);
    };

    return (
      <div className={classes.tableWrap}>
        <div className={classes.actionPanel}>
          <div className={classes.leftButtons}>
            <Button variant="filled" size="small" color="primary" onClick={onOpen} disabled={!hasAdminRights}>
              Создать
            </Button>
          </div>

          <div className={classes.rightButtons}>
            <SwitcherActionButton isOn={isFinishedShown} action={changeFinished} name="Завершенные" />
            <SwitcherActionButton isOn={isArchiveShown} action={changeArchive} name="Архивные" />
            <ActionTableButton
              textTooltip="Обновить"
              action={getProjects}
              loading={isLoading || isTableLoading}
              icon={<RedoIcon />}
              className={classes.reloadButton}
              isReloadButton={true}
            />
          </div>
        </div>
      </div>
    );
  },
);
