import { Headline } from '@plarin/design';
import React from 'react';
import './auth-form-container-template.scss';

export const AuthFormContainerTemplate: React.FC<{
  title: string;
  children: React.ReactNode;
}> = ({ title, children }) => (
  <div className="auth-form-template">
    <div className="auth-form-template__title">
      <Headline size="s" color="textPrimary" level={1} align="center" bold>
        {title}
      </Headline>
    </div>
    {children}
  </div>
);
