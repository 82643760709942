import { Typography, WsAvatar, WsItemType, SmashedAvatar } from '@plarin/design';
import React, { useRef, useState } from 'react';
import { MainTooltip } from '../tooltip/main-tooltip';
import { ItemsPopover } from '../wsTableActionMenu/items-popover';
import classes from './style.module.scss';

export interface AvatarsInRowProps {
  itemsData: { avatar_color: string; id: string; name: string }[];
  forceAllGray?: boolean; // компонент рендерится в ячейке, принадлежащей неактивной строке, поэтому все аватарки должны быть серыми
  avatarShape?: 'circle' | 'square';
  header?: string;
  itemsType: WsItemType;
}

// аватарки поставлены в ряд и немного находят друг на друга. Аватарки могут принадлежать проектам, командам или мембрам
export const AvatarsInRow = ({
  itemsData,
  avatarShape = 'square',
  header,
  itemsType,
  forceAllGray,
}: AvatarsInRowProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const actionRef = useRef(null);
  const isActionMenuOpen = Boolean(anchorEl);

  const openMenu = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const closeMenu = (e: React.MouseEvent) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <div className={classes.avatarsRow}>
      {!itemsData.length && (
        <Typography color="TextSecondary" weight={400} componentProps={{ className: classes.secondaryName }}>
          —
        </Typography>
      )}

      {/* Если объектов не больше четырёх, показываем все аватарки */}
      {!!itemsData.length &&
        itemsData.length < 5 &&
        itemsData.map((item, index) => (
          <MainTooltip key={index} tooltipMessage={item.name} component="div" isVisible={true}>
            <WsAvatar type={itemsType} hasBorder={true} data={item} forceGray={forceAllGray} />
          </MainTooltip>
        ))}

      {/* Если объектов пять или больше, аватарки схлопываются */}
      {itemsData.length >= 5 && (
        <>
          {itemsData.slice(0, 4).map((item, index) => (
            <MainTooltip key={index} tooltipMessage={item?.name} component="div" isVisible={true}>
              <WsAvatar type={itemsType} hasBorder={true} data={item} />
            </MainTooltip>
          ))}
          <SmashedAvatar amount={itemsData.length - 4} avatarShape={avatarShape} action={openMenu} />
          <ItemsPopover
            isOpen={isActionMenuOpen}
            closeMenu={closeMenu}
            actionRef={actionRef}
            anchorEl={anchorEl}
            items={itemsData}
            itemsType={itemsType}
            header={header ? header : 'Проекты команды'}
          />
        </>
      )}
    </div>
  );
};
