import { PinIcon } from '@plarin/design';
import { sendMetricGoal } from '@plarin/utils';
import clsx from 'clsx';
import React from 'react';
import { MainTooltip } from '../tooltip';
import classes from './pin.module.scss';

interface IPin {
  isColumnPinned: boolean;
  togglePin: (e: boolean) => void;
}

export const Pin = ({ isColumnPinned, togglePin }: IPin) => {
  const clickHandler = () => {
    togglePin(isColumnPinned);
    // Отправляем конверсию в Яндекс.Метрику
    sendMetricGoal('usage_vk_pin_main', 'manage/vk');
  };

  return (
    <MainTooltip
      isVisible={true}
      tooltipMessage={isColumnPinned ? 'Открепить столбец' : 'Закрепить столбец'}
      component="span"
      placement="bottom-start"
    >
      <div
        className={classes.saveZone}
        onClick={() => {
          clickHandler();
        }}
        onTouchStart={() => {
          togglePin(isColumnPinned);
        }}
      >
        <PinIcon size={16} className={clsx(isColumnPinned && classes.iconTrans, classes.pinIcon)} />
      </div>
    </MainTooltip>
  );
};
