import React from 'react';
import { AuthContentRegTemplate } from '../auth-content-template';
import { AuthSliderLayoutTemplate } from '../auth-layout-template';

interface AuthRegPageTemplateProps {
  children: React.ReactNode;
}

export const AuthRegPageTemplate: React.FC<AuthRegPageTemplateProps> = ({ children }) =>
  AuthSliderLayoutTemplate({
    children: AuthContentRegTemplate({
      children,
    }),
  });
