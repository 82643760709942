import { getNumeral } from './numeralsUtils';

export const getDateUtc = (date: Date) => {
  return Date.UTC(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
    date.getUTCMilliseconds(),
  );
};

export const newDateUtc = () => {
  return getDateUtc(new Date());
};

export const timeToSendReq = (date: Date) => {
  const nextReqAlw = getDateUtc(new Date(date));

  return nextReqAlw - newDateUtc();
};

export const minutesToSendReq = (err: any) => {
  const minToSendReq = Math.round(timeToSendReq(err.response?.data.error.details?.next_request_allowed) / 60000);

  return minToSendReq < 1
    ? 'меньше 1 минуты'
    : minToSendReq + ' ' + getNumeral(minToSendReq, ['минуту', 'минуты', 'минут']);
};
