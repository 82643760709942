import { RedoIcon } from '@plarin/design';
import {
  ActionBar,
  ActionTableButton,
  Button,
  getGridApi,
  GridApi,
  SearchDefaultInput,
  StatusPanelDef,
  Table,
} from '@plarin/inputs';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import React, { useEffect, useMemo, useState } from 'react';
import { ModalTypes } from '../../../src/utils/integration-converters';
import { TAFStoreData } from '../../../types/connect-af/types';
import { useAppDataStore } from '../../components/app-data-provider';
import {
  connectionStatusPanel,
  ConnectionStatusPanelTypes,
} from '../../components/connection-status-panel/connection-status-panel';
import { SelectDropMenu } from '../../components/metrics-widgets';
import { useFilter } from '../manage-vk/tabs-content/utils/quickFilterSwitcher';
import { defaultColumns, selectionColumnDef } from './af-columns';
import { MetricsConfirmModal } from './modals';
import { NoRowsOverlay } from './no-rows-overlay';
import classes from './style.module.scss';

const IntegrationsAF = observer(() => {
  const {
    profileStore: { workspaceShortname },
    integrationsAF: {
      setModalDataAF,
      searchTableAF,
      setSearchTableAF,
      enableIntegrationAF,
      tableLoading,
      tableError,
      getData,
      data,
    },
  } = useAppDataStore();

  const [gridApi, setGridApi] = useState<GridApi>(null as unknown as GridApi);
  const [selectedRows, setSelectedRows] = useState<TAFStoreData[]>([]);

  useEffect(() => {
    setTimeout(() => {
      getGridApi(gridApi) && setSelectedRows(gridApi.getSelectedRows());
    });
  }, [data, gridApi]);

  useEffect(() => {
    getData();
    // checkQueryString();
  }, []);

  useFilter(searchTableAF, gridApi);

  const statusBar = useMemo<{
    statusPanels: StatusPanelDef[];
  }>(() => {
    return {
      statusPanels: [
        {
          statusPanel: connectionStatusPanel,
          align: 'left',
          statusPanelParams: { type: ConnectionStatusPanelTypes.appsflyer },
        },
      ],
    };
  }, []);

  return (
    <>
      <SearchDefaultInput
        placeholder="Поиск"
        filterValue={searchTableAF}
        setFilter={setSearchTableAF}
        searchIconColor="main"
        inputStyle="tableSearch"
      />
      <div
        className={clsx(
          classes.tableWrapper,
          classes.fixFlexColumns,
          !data?.length && classes.emptyTable,
          tableError && classes.fixErrorPadding,
        )}
      >
        <ActionBar>
          <div className={classes.actionWrapper}>
            {!tableError && !tableLoading && (
              <>
                <div className={classes.connect}>
                  <Button
                    color="primary"
                    size="small"
                    onClick={() => setModalDataAF({ type: ModalTypes.connectAccount })}
                  >
                    Подключить
                  </Button>
                  <SelectDropMenu
                    selectedRows={selectedRows || []}
                    tableLoading={tableLoading}
                    setModalData={setModalDataAF}
                    enableAction={enableIntegrationAF}
                    gridApi={gridApi}
                  />
                </div>
                <ActionTableButton
                  textTooltip="Обновить"
                  action={getData}
                  loading={tableLoading}
                  icon={<RedoIcon />}
                  isReloadButton={true}
                />
              </>
            )}
          </div>
        </ActionBar>

        <Table
          onGridReady={setGridApi}
          setSelectedRows={setSelectedRows}
          columns={defaultColumns}
          statusBar={data.length && statusBar}
          rowData={data || []}
          tableLoading={tableLoading}
          agNoRowsOverlay={() => <NoRowsOverlay action={() => setModalDataAF({ type: ModalTypes.connectAccount })} />}
          needAutoHeight
          errorTable={tableError}
          ActionError={getData}
          groupDefaultExpanded={1}
          extraFooterHeight={40}
          selectionColumnDef={selectionColumnDef}
        />
      </div>

      <MetricsConfirmModal gridApi={gridApi} />
    </>
  );
});

export default IntegrationsAF;
