import { ActionBar, GridApi, IRowNode, Table } from '@plarin/inputs';
import { ManageVkTabNameEnum } from '@plarin/utils';
import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { DateOptions } from '../../../../../types/common-types';
import { MetricRecord } from '../../../../../types/manage-vk/types';
import { TMetric } from '../../../../../types/metrics';
import { useAppDataStore } from '../../../../components/app-data-provider';
import {
  dynamicColumnsRenderer,
  getVkSelectionColumnDef,
} from '../../../../components/manage-vk-custom-columns/dynamic-column-renderer';
import { FastStatTypes } from '../../../../dictionary/manage-vk';
import { FAST_STAT_IDS } from '../../../../utils/constants';
import { getColumnsForTable } from '../../../../utils/manage-vk';
import { useFilter } from '../utils/quickFilterSwitcher';
import { useGetStatTable } from '../utils/useGetStatTable';
import { useGroupingSelect } from '../utils/useGrouping';
import { AdGroupsActionContent } from './ad-groups-action-content';

export const AdGroupsTab = observer(() => {
  const {
    manageVK: {
      tableLoading,
      data,
      getStat,
      dictionary,
      filterStatuses,
      metricsVK,
      filterValue,
      getFastStat,
      fast72Checked,
      errorTable,
      date,
      setSelectedIds,
      quantitySelected,
      getSelectedIds,
      setDeleteAction,
      setCurrentTab,
      isGroupDestroyed,
      setIsGroupDestroyed,
      getAdditional,
      setLeftNavigationIds,
    },
    profileStore: { wsDictionary },
  } = useAppDataStore();
  const [names, setNames] = useState<TMetric[]>([]);
  const [gridApi, setGridApi] = useState<GridApi>(null as unknown as GridApi);
  const { storeLabel, storeStartDate, storeEndDate } = date as unknown as DateOptions;
  const [loading, setLoading] = useState(true);
  const isGroupingActive = !!data?.length && data[0].hasOwnProperty('orgHierarchy');
  const getStatUp = useGetStatTable(ManageVkTabNameEnum.ADGROUPS);

  const handleBodyScroll = (nodeRendered: IRowNode<any>[], api: GridApi<any>) => {
    // без этого условия не отображается заглушка, если таблица пустая (PYT-7010)
    if (api?.getDisplayedRowCount() !== 0) {
      getFastStat(api, nodeRendered, fast72Checked, FastStatTypes.CAMPAIGNS, FAST_STAT_IDS.campaign);
      getAdditional(api, nodeRendered, FAST_STAT_IDS.campaign, ManageVkTabNameEnum.ADGROUPS);
    }
  };

  const handleBodyScrollToFastStat = (nodeRendered: IRowNode<any>[], api: GridApi<any>) => {
    if (gridApi?.getDisplayedRowCount() !== 0) {
      getFastStat(api, nodeRendered, fast72Checked, FastStatTypes.CAMPAIGNS, FAST_STAT_IDS.campaign);
    }
  };
  // Получаем фаст стату при раскрытии вложенных строк
  const onRowGroupOpened = useCallback(() => {
    if (gridApi && data?.length) {
      const nodes = gridApi.getRenderedNodes().filter(node => !node.data.isGroupRow);
      if (nodes && nodes.length) {
        getFastStat(gridApi, nodes, fast72Checked, FastStatTypes.CAMPAIGNS, FAST_STAT_IDS.campaign);
        getAdditional(gridApi, nodes, FAST_STAT_IDS.campaign, ManageVkTabNameEnum.ADGROUPS);
      }
    }
  }, [gridApi, data]);

  useEffect(() => {
    getStatUp().then(() => {
      setLoading(false);
    });
  }, [
    storeLabel,
    storeStartDate,
    storeEndDate,
    getStat,
    quantitySelected.selectedClients,
    quantitySelected.selectedAdPlans,
    quantitySelected.selectedAds,
    filterStatuses,
  ]);

  useEffect(() => {
    setCurrentTab(ManageVkTabNameEnum.ADGROUPS);
    setDeleteAction(() => {
      gridApi.deselectAll();
    });
  }, [gridApi, setDeleteAction]);

  useEffect(() => {
    if (data?.length && dictionary && metricsVK) {
      setNames(getColumnsForTable(Object.keys(data[0]), dictionary, metricsVK, fast72Checked));
    }
  }, [data, dictionary, metricsVK]);

  useFilter(filterValue, gridApi);
  useGroupingSelect(gridApi, isGroupDestroyed, setIsGroupDestroyed, getSelectedIds, ManageVkTabNameEnum.ADGROUPS, data);

  return (
    <>
      <ActionBar>
        <AdGroupsActionContent gridApi={gridApi} />
      </ActionBar>

      <Table<MetricRecord>
        showStatusBar
        columns={useMemo(
          () => dynamicColumnsRenderer(names, ManageVkTabNameEnum.ADGROUPS, 'campaignName', wsDictionary),
          [names],
        )}
        rowData={data || []}
        onGridReady={setGridApi}
        onBodyScroll={handleBodyScroll}
        onBodyScrollFastStat={handleBodyScrollToFastStat}
        ActionError={() => getStat(ManageVkTabNameEnum.ADGROUPS, wsDictionary)}
        errorTable={errorTable}
        getSelectedIds={getSelectedIds}
        setLeftNavigationIds={setLeftNavigationIds}
        setSelectedKeys={setSelectedIds}
        tabName={ManageVkTabNameEnum.ADGROUPS}
        // emptyDataComponent={
        //   <NoRowsComponent
        //     action={() => getStat(CampaignsTabNameEnum.CAMPAIGNS, wsDictionary)}
        //     tabName={CampaignsTabNameEnum.CAMPAIGNS}
        //   />
        // }
        tableLoading={loading || tableLoading}
        isGroupDestroyed={isGroupDestroyed}
        setGroupDestroyed={setIsGroupDestroyed}
        onRowGroupOpened={onRowGroupOpened}
        selectionColumnDef={getVkSelectionColumnDef(isGroupingActive)}
      />
    </>
  );
});
