import { GridApi, Lockscreen, SearchDefaultInput, Table, StatusPanelDef } from '@plarin/inputs';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ProfileData, TWsProject, TWsRole, WorkspaceResp, WsMembers } from '../../../types/profile/types';
import { IPostProjects } from '../../../types/projects/projects';
import { TTeams } from '../../../types/projects/projects';
import { useAppDataStore } from '../../components/app-data-provider';
import { СommonStatusPanel, CommonStatusPanelTypes } from '../../components/common-status-panel/common-status-panel';
import { ProjectDrawer } from '../../components/drawer/project-drawer/project-drawer';
import { WsEmptyTableOverlay } from '../../components/ws-table-overlay/ws-table-overlay';
import { useFilter } from '../manage-vk/tabs-content/utils/quickFilterSwitcher';
import { projectColumns } from './components/columns';
import { ProjectsConfirmModal } from './components/confirm-modals/projectsConfirmModal';
import { ProjectsActionBar } from './projects-action-bar';
import classes from './style.module.scss';

const getWsRole = (userId: string | undefined, currentWorkspace: WorkspaceResp | undefined): TWsRole | undefined => {
  // при прямом переходе на данную страницу поначалу данные ещё не засетились в profileStore
  if (!userId || !currentWorkspace || !currentWorkspace.members || !currentWorkspace.members.length) return undefined;

  const myWsData = currentWorkspace.members.find(member => member._id === userId);
  return myWsData?.role;
};

const isLeaderInTeamsFilter = (e: any, teams: TTeams, profileData: ProfileData) => {
  const teamsFilter = teams.teams.filter(el => {
    return e.data.team_ids.includes(el._id);
  });

  return teamsFilter
    .map(el => {
      return el.leader_id;
    })
    .includes(profileData.user_id);
};

const Projects = observer(() => {
  const {
    projects: {
      searchTableProject,
      setSearchTableProject,
      setEditProject,
      setIsEditProject,
      isTableLoading,
      projectsData,
      getProjects,
      editProject,
      setRowCountProjects,
      setProjects,
      teams,
      setStatusWSroles,
      setIsOpenDrawer,
      isLoading,
      hasLoadingError,
      clearEditProject,
      clearProjectsState,
      clearTeamsState,
      clearGuestsState,
      projectsState,
      teamsState,
      guestsState,
      postEditProjects,
      postProjects,
      idAddedProject,
      setIdAddedProject,
    },
    profileStore: { currentWorkspace, data: profileData, loadWorkspaces },
  } = useAppDataStore();

  const [filteredProjectData, setFilteredProjectData] = useState<TWsProject[]>([]);
  const [hasAdminRights, setHasAdminRights] = useState(false);

  const [isCompletedSwitch, setCompletedSwitch] = useState(false);
  const [isArchivedSwitch, setArchivedSwitch] = useState(false);

  const [gridApi, setGridApi] = useState<GridApi>(null as unknown as GridApi);
  const archivedAmount = useRef(0);
  const completedAmount = useRef(0);

  const wsRole = getWsRole(profileData.user_id, currentWorkspace);

  const onOpen = useCallback(() => {
    setIsOpenDrawer(true);
  }, []);

  const onClose = useCallback(() => {
    setIsOpenDrawer(false);
  }, []);

  const onChangeCompleted = useCallback((checked: boolean) => {
    setCompletedSwitch(checked);
  }, []);

  const onChangeArchived = useCallback((checked: boolean) => {
    setArchivedSwitch(checked);
  }, []);

  const projectsObject: IPostProjects = {
    name: projectsState.projectsName,
    manager_id:
      projectsState.projectManagers === 'not'
        ? (null as unknown as undefined)
        : projectsState.projectManagers || (null as unknown as undefined),
    tm_client_ids: projectsState.selectedCabinets.map(el => +el.client_id),
    team_ids: teamsState.selectedTeams.map(el => el.id), // команды
    status: projectsState.valuesProjectStatus,
    guest_ids: guestsState.selectedGuests.map(el => el.id),
  };

  const editProjects = () => {
    postEditProjects(editProject.editProjectData?._id ?? '', projectsObject)
      .then(() => {
        setIsOpenDrawer(false);
      })
      .finally(() => {
        // костыль для обновления в колонке проекты в разделе управления рекламой
        // но на 28.02 появились новые вводные: -если в разных вкладках создавать проект, а во второй вкладке в УР обновлять и смотреть, то "Не определено" есть
        // - нужно что то другое, или дорабатывать.
        loadWorkspaces();
      });
  };
  const createProjects = () => {
    postProjects(projectsObject)
      .then(() => {
        setIsOpenDrawer(false);
        getProjects();
      })
      .finally(() => {
        // костыль для обновления в колонке проекты в разделе управления рекламой
        loadWorkspaces();
      });
  };

  const statusBar = useMemo<{
    statusPanels: StatusPanelDef[];
  }>(() => {
    return {
      statusPanels: [
        {
          statusPanel: СommonStatusPanel,
          align: 'left',
          statusPanelParams: { type: CommonStatusPanelTypes.projects },
        },
      ],
    };
  }, []);

  useEffect(() => {
    wsRole && setHasAdminRights(wsRole === 'wsrole:owner' || wsRole === 'wsrole:admin');
  }, [wsRole]);

  useEffect(() => {
    getProjects();
    return () => {
      setProjects([]);
      // нужен при размонтировании таблицы очищаем id нового добавленного проекта (чтоб при следующнм запуске небыло выделения)
      setIdAddedProject('');
    };
  }, []);

  useEffect(() => {
    setStatusWSroles({
      isAdmin: hasAdminRights,
      isManager: false,
      isLeader: false,
    });
  }, [hasAdminRights]);

  useEffect(() => {
    if (projectsData) {
      if (isCompletedSwitch && isArchivedSwitch) {
        setFilteredProjectData(projectsData);
      } else if (isCompletedSwitch) {
        setFilteredProjectData(
          projectsData.filter(e => (e.status === 'active' && !e.is_archived) || e.status === 'completed'),
        );
      } else if (isArchivedSwitch) {
        setFilteredProjectData(projectsData.filter(e => e.is_archived || e.status === 'active'));
      } else {
        setFilteredProjectData(projectsData.filter(e => e.status === 'active' && !e.is_archived));
      }
    }
  }, [projectsData, isCompletedSwitch, isArchivedSwitch]);

  useEffect(() => {
    archivedAmount.current = projectsData.filter(i => i.is_archived === true).length;
    completedAmount.current = projectsData.filter(i => i.status === 'completed').length;
  }, [projectsData]);

  const wsMembers: WsMembers = useMemo(() => currentWorkspace.members, [currentWorkspace]);

  const columns = projectColumns(wsMembers, wsRole);

  const agNoRowsOverlay = useCallback(
    () =>
      WsEmptyTableOverlay({
        type: 'projects',
        hasLoadingError,
        hasAdminRights,
        openDrawer: onOpen,
        archived: archivedAmount.current,
        completed: completedAmount.current,
      }),
    [archivedAmount, completedAmount, hasAdminRights, hasLoadingError],
  );

  const data = useMemo(
    () =>
      filteredProjectData.map(el => {
        const manager = wsMembers.find(member => member._id === el.manager_id);
        const managerName = (() => {
          let name = '';
          if (manager) {
            manager.fname && (name = manager.fname);
            manager.lname && (name = name + ' ' + manager.lname);
          }

          return name;
        })();

        return { ...el, managerName };
      }),
    [filteredProjectData],
  );

  useEffect(() => {
    setRowCountProjects(data.length);
  }, [data]);

  useFilter(searchTableProject, gridApi, data);

  return (
    <>
      <SearchDefaultInput
        placeholder="Поиск"
        filterValue={searchTableProject}
        setFilter={setSearchTableProject}
        searchIconColor="main"
        inputStyle="tableSearch"
      />

      <div
        className={clsx(
          classes.tableContent,
          classes.fixFlexColumns,
          !filteredProjectData.length && classes.emptyTable,
        )}
      >
        <ProjectsActionBar
          hasAdminRights={hasAdminRights}
          onOpen={() => {
            clearEditProject();
            clearProjectsState();
            clearTeamsState();
            clearGuestsState();
            clearEditProject();
            onOpen();
          }}
          onChangeCompleted={onChangeCompleted}
          onChangeArchived={onChangeArchived}
        />
        <Table
          columns={columns}
          statusBar={projectsData.length && statusBar}
          rowData={data}
          onGridReady={setGridApi}
          onRowClicked={e => {
            setStatusWSroles({
              isAdmin: hasAdminRights,
              isManager: e.data.manager_id === profileData.user_id,
              isLeader: isLeaderInTeamsFilter(e, teams, profileData),
            });
            e?.event?.stopPropagation();
            setEditProject('all', e.data);
            setIsEditProject(true);
            onOpen();
          }}
          isRowGroup
          tableLoading={isTableLoading}
          agNoRowsOverlay={agNoRowsOverlay}
          needAutoHeight
          spaceAboveTable={254}
          extraFooterHeight={40}
          getRowClass={el => (el?.data?._id === idAddedProject ? 'ag-row-selected' : undefined)}
        />
      </div>
      <ProjectDrawer
        editProjects={editProjects}
        createProjects={createProjects}
        isDrawerOpen={editProject.isOpen}
        onClose={onClose}
      />
      <ProjectsConfirmModal editProjects={editProjects} createProjects={createProjects} />
      <Lockscreen visible={isLoading} />
    </>
  );
});

export default Projects;
