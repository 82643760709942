import { getGridApi, GridApi } from '@plarin/inputs';
import { switchFilter } from '@plarin/utils';
import { useEffect } from 'react';

export const useFilter = (filterValue?: string, gridApi?: GridApi) => {
  useEffect(() => {
    if (gridApi && getGridApi(gridApi)) {
      const wordsSearchMetrics = filterValue && filterValue.replace(/\s+/g, ' ').trim().split(' ');

      if (wordsSearchMetrics && wordsSearchMetrics.length > 1) {
        const resFilter = wordsSearchMetrics.map(elem => {
          return elem.trim();
        });

        gridApi.setGridOption('quickFilterText', resFilter.join(' '));

        if (gridApi.getDisplayedRowCount() === 0) {
          const switchedPhrase = resFilter.map(elem => switchFilter(elem));

          gridApi.setGridOption('quickFilterText', switchedPhrase.join(' '));
        }
      } else {
        gridApi.setGridOption('quickFilterText', filterValue || '');

        if (gridApi.getDisplayedRowCount() === 0) {
          const switchFilteredValue = filterValue ? switchFilter(filterValue) : '';

          gridApi.setGridOption('quickFilterText', switchFilteredValue);
        }
      }
      gridApi.getDisplayedRowCount() === 0 ? gridApi.showNoRowsOverlay() : gridApi.hideOverlay();
    }
  }, [gridApi, filterValue]);
};
