import {
  ManageYDTabID,
  manageYDTabs,
  ManageYDTabs,
  StatusTextEnum,
  mayArchive,
  mayModerate,
  mayResume,
  maySuspend,
  mayUnarchive,
  StatusToSet,
  statusToSet,
  TRows,
  ManageYDTabState,
  ManageYDTabStatus,
} from '@plarin/inputs';
import { ManageYDTabNameEnum } from '@plarin/utils';
import { IRowNode } from 'ag-grid-community';
import { Dictionary, MetricRecord } from '../../types/manage-vk/types';
import { TMetricGroupYD, TYDMetric } from '../../types/manage-yd/types';
import { ManageYDNameCellsEnum } from './constants';
import { parseStringToCamelCase } from './parseStatData/parseStatistics';

export const returnId = (tabName: ManageYDTabs) => {
  let id = ManageYDTabID.accountId;
  switch (tabName) {
    case manageYDTabs.ACCOUNTS:
      return ManageYDTabID.accountId;
    case manageYDTabs.CAMPAIGNS:
      return ManageYDTabID.campaignId;
    case manageYDTabs.GROUPS:
      return ManageYDTabID.adGroupId;
    case manageYDTabs.ADS:
      return ManageYDTabID.adId;
  }
  return id;
};

export const returnItemIdByTabName = (tabName: ManageYDTabNameEnum) => {
  let id = ManageYDTabID.accountId;
  switch (tabName) {
    case ManageYDTabNameEnum.ACCOUNTS:
      return ManageYDTabID.accountId;
    case ManageYDTabNameEnum.CAMPAIGNS:
      return ManageYDTabID.campaignId;
    case ManageYDTabNameEnum.GROUPS:
      return ManageYDTabID.adGroupId;
    case ManageYDTabNameEnum.ADS:
      return ManageYDTabID.adId;
  }
  return id;
};

export const returnItemStateByTabName = (tabName: ManageYDTabNameEnum) => {
  switch (tabName) {
    case ManageYDTabNameEnum.ACCOUNTS:
      return undefined;

    case ManageYDTabNameEnum.CAMPAIGNS:
      return ManageYDTabState.campaignState;

    case ManageYDTabNameEnum.GROUPS:
      return undefined;

    case ManageYDTabNameEnum.ADS:
      return ManageYDTabState.adState;
  }
};

export const returnItemStatusByTabName = (tabName: ManageYDTabNameEnum) => {
  switch (tabName) {
    case ManageYDTabNameEnum.ACCOUNTS:
      return ManageYDTabStatus.accountStatus;

    case ManageYDTabNameEnum.CAMPAIGNS:
      return ManageYDTabStatus.campaignStatus;

    case ManageYDTabNameEnum.GROUPS:
      return ManageYDTabStatus.adGroupStatus;

    case ManageYDTabNameEnum.ADS:
      return ManageYDTabStatus.adStatus;
  }
};

// проверяем, какие из selectedRows у Яндекс Директ можно запустить, остановить, архивировать и т.д.
export const maySetStatus = (
  selectedRows: TRows,
  status: StatusToSet, // какой статус хотим присвоить сущностям Яндекс Директ
  idName: ManageYDTabID, // campaignId, adId и т.д.
  stateName: ManageYDTabState | undefined, // accountState, adState
  statusName: ManageYDTabStatus | undefined, // accountStatus, adStatus
) => {
  if (!stateName || !statusName) return [];

  switch (status) {
    case statusToSet.SUSPEND:
      return selectedRows.filter(item => maySuspend(item[stateName], item[statusName])).map(item => item[idName]);

    case statusToSet.RESUME:
      return selectedRows.filter(item => mayResume(item[stateName], item[statusName])).map(item => item[idName]);

    case statusToSet.ARCHIVE:
      return selectedRows.filter(item => mayArchive(item[stateName], item[statusName])).map(item => item[idName]);

    case statusToSet.MODERATE:
      return selectedRows.filter(item => mayModerate(item[stateName], item[statusName])).map(item => item[idName]);

    case statusToSet.UNARCHIVE:
      return selectedRows.filter(item => mayUnarchive(item[stateName], item[statusName])).map(item => item[idName]);
  }

  return [];
};

export const comparatorStatus =
  (tabName: keyof typeof ManageYDNameCellsEnum) =>
  (valueA: string, valueB: string, nodeA: IRowNode, nodeB: IRowNode, isDescending: boolean) => {
    const { statusText: statusTextA } = nodeA.data;
    const { statusText: statusTextB } = nodeB.data;

    const aStatus = getSortByTranslation(statusTextA || '');
    const bStatus = getSortByTranslation(statusTextB || '');

    if (aStatus === bStatus) {
      return nodeA.data[tabName].toLowerCase() > nodeB.data[tabName].toLowerCase() ? 1 : -1;
    }

    return aStatus - bStatus;
  };

const getSortByTranslation = (statusText: string) => {
  switch (true) {
    case statusText === StatusTextEnum.ON:
      return 6;
    case statusText === StatusTextEnum.SUSPENDED:
      return 5;
    case statusText === StatusTextEnum.DRAFT:
      return 4;
    case statusText === StatusTextEnum.MODERATION:
      return 3;
    case statusText === StatusTextEnum.REJECTED:
      return 2;
    case statusText === StatusTextEnum.ARCHIVED:
      return 1;

    default:
      return 0;
  }

  //   Запущено
  // Остановлено
  // Черновик
  // На модерации
  // Отклоненные
  // В архиве
};

export const getColumnsForTableYD = (columns: string[], dictionary: Dictionary, metrics: TMetricGroupYD[]) => {
  const result: TYDMetric[] = [];

  metrics.forEach(group => {
    group.fields.forEach(metric => {
      const camelCaseMetricPath = parseStringToCamelCase(metric.path);
      columns.forEach(columnName => {
        if (dictionary[columnName] && columnName === camelCaseMetricPath) {
          result.push(metric);
        }
      });
    });
  });

  return result;
};
