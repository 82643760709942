import { Avatar, WsAvatar, WsItemType, AvatarsIconTypes, IconStatus, Typography } from '@plarin/design';
import { EllipsisTooltip, TitleOptions } from '@plarin/inputs';
import { typografNames } from '@plarin/utils';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { IprojectsState, IEditProject } from '../../../../types/projects/projects';
import { useAppDataStore } from '../../app-data-provider';
import classes from './project-drawer.module.scss';

const getEditedProjectStatus = (
  projectsState: IprojectsState,
  editProject: IEditProject,
  isEditedDataLoaded: boolean,
) => (isEditedDataLoaded ? projectsState.valuesProjectStatus : editProject.editProjectData?.status);

export const TitleProjectDrawer = observer(() => {
  const {
    projects: { projectsState, editProject, setProjectsState, setIsDrawerDataChanged },
  } = useAppDataStore();
  const { editProjectData, isEditProject } = editProject;

  useEffect(() => {
    setProjectsState('projectsName', editProject.editProjectData?.name ?? '');
    setIsDrawerDataChanged(false);
  }, []);

  // когда открывается дровер редактирования проекта, то сначала в шапке будет отображаться текущее название проекта (пока в projectsState не подгрузится копия данных, которые будут редактироваться формой). После того как данные подгрузились, в шапке в качестве названия проекта уже будет использоваться имя из projectsState.
  const projectsName = projectsState.projectsName;
  const status = isEditProject
    ? getEditedProjectStatus(projectsState, editProject, !!projectsState.projectManagers)
    : projectsState.valuesProjectStatus;

  const iconBlock = () => {
    return !isEditProject ? (
      // если мы создаём новый проект, то в шапке будет отображаться аватарка с иконкой папки (фон синий, иконка белая)
      <Avatar shape="square" size={40} avatarIconType={AvatarsIconTypes.folder} />
    ) : (
      <WsAvatar size={40} data={editProjectData} type={WsItemType.project} />
    );
  };

  const titleBlock = () => {
    return (
      <EllipsisTooltip tooltipMessage={projectsName}>
        <Typography ellips={true} weight={600} size="DrawerProject" align="left">
          {typografNames(projectsName) || (!isEditProject ? 'Создание проекта' : 'Название проекта')}
        </Typography>
      </EllipsisTooltip>
    );
  };
  const rightBlock = () => {
    return isEditProject ? (
      <Typography
        componentProps={{ className: classes.leftBorder }}
        size="Caption"
        color={editProjectData?.is_archived ? 'TextSecondary' : 'TextPrimary'}
      >
        <IconStatus
          className={clsx(classes.iconStatus)}
          status={editProjectData?.is_archived ? 'blocked' : status ?? ''}
        />
        {editProjectData?.is_archived ? 'Архивный' : status === 'active' ? 'Активный' : 'Завершенный'}
      </Typography>
    ) : undefined;
  };
  return (
    <TitleOptions
      withTitleBox={classes.linesTitleName}
      icon={iconBlock()}
      titleName={titleBlock()}
      rightHead={rightBlock()}
      fixedTitle={true}
    />
  );
});
