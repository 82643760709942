import { EllipsisTooltip, HightLightText, copy, StatusColorEnum } from '@plarin/inputs';
import clsx from 'clsx';
import React, { useState } from 'react';
import classes from '../style.module.scss';

interface SecondLineContentProps {
  data: any;
  secondLine: string;
  copySecondLine?: boolean;
  additionalInfoToCopy?: string;
  filterValue?: any;
  currentStatusName?: string;
  type?: string;
}

export const SecondLineContent: React.FC<SecondLineContentProps> = ({
  data,
  secondLine,
  copySecondLine,
  additionalInfoToCopy,
  filterValue,
  currentStatusName,
}) => {
  const [isShowTooltip, setIsShowTooltip] = useState<boolean>(false);
  const [isCopied, setIsCopied] = useState<boolean>(false);

  const tooltipText = (isCopied: boolean) => (isCopied ? 'Скопировано!' : 'Нажмите, чтобы скопировать');
  const resetIsCopied = () => setIsCopied(false);
  const updateData = (value: boolean) => setIsShowTooltip(value);

  // Кусочек текста, который копируетя, если по нему кликнуть. У него есть прерывистое подчёркивание и тултип с подсказкой о копировании.
  const itemToCopy = (str: string) => {
    return (
      <EllipsisTooltip
        classNameContainer={classes.containerTooltip}
        tooltipMessage={isShowTooltip ? str : tooltipText(isCopied)}
        isShowTooltip={updateData}
      >
        <span
          className={classes.secondCopy}
          ref={ref => {
            if (!ref) return;
            ref.onclick = event => {
              copy(event, str).then(() => setIsCopied(true));
            };
          }}
          onMouseLeave={resetIsCopied}
        >
          <HightLightText text={str} filterValue={filterValue} textEllips={true} />
        </span>
      </EllipsisTooltip>
    );
  };

  const dataToCopy = () => {
    return (
      <>
        {/* обычно это email или id рекламного объекта */}
        {itemToCopy(data[secondLine])}

        {/* Дополнительная информация. Обычно это id рекламного объекта, если ранее указывался email */}
        {additionalInfoToCopy && <> | {itemToCopy(data[additionalInfoToCopy])}</>}
      </>
    );
  };

  return (
    <div
      className={clsx(
        classes.second,
        currentStatusName &&
          data[currentStatusName] &&
          (data[currentStatusName] === 'disabled' ||
            data[currentStatusName].includes('Архивн') ||
            data[currentStatusName] === StatusColorEnum.ARCHIVED ||
            data.syncStatusCode === 'disabled') &&
          classes.errorGray,
      )}
    >
      {copySecondLine ? (
        // обычно тут оказывается id рекламного объекта или email, которые копируются при клике по ним
        dataToCopy()
      ) : (
        <EllipsisTooltip tooltipMessage={data[secondLine]}>
          <HightLightText text={data[secondLine]} filterValue={filterValue} textEllips={true} />
        </EllipsisTooltip>
      )}
    </div>
  );
};
