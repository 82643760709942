import { WsItemType } from '@plarin/design';
import React from 'react';
import { AvatarsInRow } from '../../../avatars-in-row';

export interface Props {
  itemsData: { avatar_color: string; id: string; name: string }[];
  node?: { data: { plarin_status: string; status: string } };
  avatarShape?: 'circle' | 'square';
  header?: string;
  itemsType: WsItemType;
}

// аватарки поставлены в ряд и немного находят друг на друга. Аватарки могут принадлежать проектам, командам или мембрам
export const AvatarsInRowCell = ({ node, itemsData, avatarShape = 'square', header, itemsType }: Props) => {
  return (
    <AvatarsInRow
      itemsData={itemsData}
      avatarShape={avatarShape}
      header={header}
      itemsType={itemsType}
      forceAllGray={node?.data.status === 'inactive' || node?.data?.plarin_status === 'disabled'}
    />
  );
};
