import { shortDate } from '@plarin/utils';
import { isBefore } from 'date-fns';
import React, { useCallback, useEffect, useState } from 'react';
import { getDate } from '../../../edit-bid';
import { BidDateInputs, LimitTypes } from '../../../inputs';
import classes from './style.module.scss';

interface EditDateProps {
  dateStart: Date | null;
  dateStop: Date | null;
  adPlanDateStart?: Date | null;
  adPlanDateStop?: Date | null;
  limitType: LimitTypes;
  isAdPlanTab: boolean;
  title: string;
  setNewStartDate: React.Dispatch<React.SetStateAction<Date | null>>;
  setNewEndDate: React.Dispatch<React.SetStateAction<Date | null>>;
  setHasError: React.Dispatch<React.SetStateAction<boolean>>;
  setWasChanged: React.Dispatch<React.SetStateAction<boolean>>;
}

export const EditDate = ({
  dateStart,
  dateStop,
  adPlanDateStart,
  adPlanDateStop,
  limitType,
  isAdPlanTab,
  title,
  setNewStartDate,
  setNewEndDate,
  setHasError,
  setWasChanged,
}: EditDateProps) => {
  const [initialDateStart] = useState<Date | null>(dateStart);
  const [initialDateEnd] = useState<Date | null>(dateStop);

  const [startDateWasChanged, setStartDateWasChanged] = useState<boolean>();
  const [endDateWasChanged, setEndDateWasChanged] = useState<boolean>();

  const [newStartDateHasError, setNewStartDateHasError] = useState<boolean>();
  const [newEndDateHasError, setNewEndDateHasError] = useState<boolean>();

  const [startDateErrorMessage, setStartDateErrorMessage] = useState('');
  const [endDateErrorMessage, setEndDateErrorMessage] = useState('');

  useEffect(() => {
    if (!dateStart && startDateWasChanged) {
      setNewStartDateHasError(true);
      setStartDateErrorMessage('Задайте дату начала');
    } else if (dateStart && adPlanDateStart && startDateWasChanged && isBefore(dateStart, adPlanDateStart)) {
      setNewStartDateHasError(true);
      setStartDateErrorMessage(`Не может быть ранее ${shortDate(adPlanDateStart)}`);
    } else if (dateStart && adPlanDateStop && startDateWasChanged && isBefore(adPlanDateStop, dateStart)) {
      setNewStartDateHasError(true);
      setStartDateErrorMessage(`Не может быть позднее ${shortDate(adPlanDateStop)}`);
    } else {
      setNewStartDateHasError(false);
      setStartDateErrorMessage('');
    }

    if (limitType === LimitTypes.lifetime && !dateStop) {
      setNewEndDateHasError(true);
      setEndDateErrorMessage('Задайте дату окончания');
    } else if (dateStop && adPlanDateStop && endDateWasChanged && isBefore(adPlanDateStop, dateStop)) {
      setNewEndDateHasError(true);
      setEndDateErrorMessage(`Не может быть позднее ${shortDate(adPlanDateStop)}`);
    } else if (dateStop && adPlanDateStop && endDateWasChanged && isBefore(adPlanDateStop, dateStop)) {
      setNewEndDateHasError(true);
      setEndDateErrorMessage(`Не может быть ранее ${shortDate(adPlanDateStop)}`);
    } else {
      setNewEndDateHasError(false);
      setEndDateErrorMessage('');
    }
  }, [dateStart, dateStop, limitType, startDateWasChanged, endDateWasChanged, adPlanDateStop, adPlanDateStart]);

  useEffect(() => {
    if (newStartDateHasError || newEndDateHasError) {
      setHasError(true);
    } else {
      setHasError(false);
    }

    if (startDateWasChanged || endDateWasChanged) {
      setWasChanged(true);
    } else {
      setWasChanged(false);
    }
  }, [newStartDateHasError, newEndDateHasError, startDateWasChanged, endDateWasChanged]);

  const setStartDate = useCallback((date: React.SetStateAction<Date | null>) => {
    setNewStartDate(date);
  }, []);

  const setStopDate = useCallback((date: React.SetStateAction<Date | null>) => {
    setNewEndDate(date);
  }, []);

  return (
    <BidDateInputs
      title={title}
      startDate={dateStart}
      initialStartDate={initialDateStart}
      initialEndDate={initialDateEnd}
      endDate={dateStop}
      setStartDate={setStartDate}
      setEndDate={setStopDate}
      setStartDateWasChanged={setStartDateWasChanged}
      setEndDateWasChanged={setEndDateWasChanged}
      setNewStartDateHasError={setNewStartDateHasError}
      newStartDateHasError={newStartDateHasError}
      newEndDateHasError={newEndDateHasError}
      setNewEndDateHasError={setNewEndDateHasError}
      startDateErrorMessage={startDateErrorMessage}
      endDateErrorMessage={endDateErrorMessage}
      limitType={limitType}
      adPlanStartDate={adPlanDateStart ? getDate(adPlanDateStart) : null}
      adPlanEndDate={adPlanDateStop ? getDate(adPlanDateStop) : null}
      areDatesLimitedByAdplan={!isAdPlanTab}
    />
  );
};
