import { Typography } from '@plarin/design';
import { Switch } from '@plarin/inputs';
import React from 'react';
import classes from './style.module.scss';

type TSwitcherProps = {
  isOn: boolean;
  action: () => void;
  name: string;
};

export const SwitcherActionButton = ({ isOn, action, name }: TSwitcherProps) => (
  <div className={classes.switcheraction}>
    {/* TODO: стилизовать свитчер, сделать поменьше. Переиспользуется в других местах */}
    <Switch checked={isOn} onChange={action} />
    <Typography
      size="Caption"
      weight={400}
      color="TextPrimary"
      componentProps={{ className: classes.cursor, onClick: action }}
    >
      {name}
    </Typography>
  </div>
);
