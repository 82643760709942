import { Page } from '@plarin/core';
import { Typography } from '@plarin/design';
import { Button } from '@plarin/inputs';
import { typografText } from '@plarin/utils';
import clsx from 'clsx';
import React from 'react';
import { useHistory } from 'react-router-dom';
import error400ImageSrc from './images/error400.svg';
import classes from './style.module.scss';

export const ErrorPageInvalidRequest: React.FC = () => {
  const history = useHistory();

  return (
    <Page title="Произошла ошибка запроса на сервер">
      <div className={classes.hideSkroll}>
        <div className={clsx(classes.boxlayout)}>
          <div className={clsx(classes.errorContent, classes.errorContent400)}>
            <div className={clsx(classes.leftBox400)}>
              <Typography color="textPrimary" size="errorTitle" weight={700}>
                {typografText('Не удалось подключиться к вашей учетной записи')}
              </Typography>

              <Typography
                componentProps={{ className: clsx(classes.textBlock, classes.textBlock400) }}
                color="textPrimary"
                size="AuthSubheader"
              >
                {typografText(
                  'Ваши данные в порядке, но мы не можем подключиться к вашему аккаунту из-за технической проблемы на нашей стороне и уже занимаемся исправлением.',
                )}
                <Typography component="span" color="textSecondary">
                  {typografText(
                    'Пожалуйста попробуйте подключиться повторно через некоторое время. Если проблема возникает многократно, свяжитесь со службой поддержки по адресу',
                  )}{' '}
                  <Typography
                    underline
                    color="linkPrimary"
                    component="a"
                    componentProps={{ href: 'mailto:support@plarin.ru' }}
                  >
                    support@plarin.ru
                  </Typography>
                  .
                </Typography>
              </Typography>
              <div>
                <Button variant="filled" size="small" color="primary" onClick={() => history.go(0)}>
                  Обновить страницу
                </Button>
              </div>
            </div>
            <div className={clsx(classes.rightBox, classes.rightBox400)}>
              <img src={error400ImageSrc} alt="400" />
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
};
