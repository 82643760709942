import { observer } from 'mobx-react';
import React, { FC } from 'react';
import { useAppDataStore } from '../app-data-provider';

export const DataLoader: FC<{ fallback: React.ReactNode; children: React.ReactNode }> = observer(
  ({ children, fallback }) => {
    const { isFulfilled } = useAppDataStore();
    return <>{isFulfilled ? children : fallback}</>;
  },
);
