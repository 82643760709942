import { Typography } from '@plarin/design';
import React, { useCallback } from 'react';
import { useAppDataStore } from '../../../components/app-data-provider';
import { SocialButtons } from '../../../components/social-buttons';
import { DrawerStep } from '../../../enums';
import { ITabContentWithoutAccountsProps } from '../types';
import classes from './tab-content-without-accounts.module.scss';
import { NetworkEnum } from '../../../dictionary/integrations';

export const TabContentWithoutAccounts: React.FC<ITabContentWithoutAccountsProps> = ({ tabType }) => {
  const {
    integrationsVK: { openDrawer },
  } = useAppDataStore();

  const action = useCallback(
    (network: NetworkEnum) => openDrawer({ network, stepInDrawer: DrawerStep.ConnectStart }),
    [openDrawer],
  );

  return (
    <div className={classes.tabContent}>
      <div className={classes.messages}>
        <Typography align="center" size="AuthSubheader" weight={700} color="TextPrimary">
          У вас еще нет подключенных {tabType === 'accounts' ? 'аккаунтов.' : 'рекламных кабинетов.'}
        </Typography>
        <Typography align="center" color="TextPrimary" size="AuthContent" weight={400}>
          {tabType === 'accounts'
            ? 'Выберите рекламную систему, аккаунт с рекламными кампаниями которой вы хотите подключить к платформе Plarin.'
            : 'Выберите канал и подключите аккаунты и рекламные кабинеты.'}
        </Typography>
      </div>
      <div className={classes.socialButtonsBlock}>
        <SocialButtons action={action} />
      </div>
    </div>
  );
};
