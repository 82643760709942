import { IconStatus, Typography } from '@plarin/design';
import { getHeader, parseDeliveryStatus, parseStatus } from '@plarin/inputs';
import TooltipPortal from '@plarin/inputs/src/table/cell-renderers/fast-stat/tooltip-portal';
import { getNumeral } from '@plarin/utils';
import { differenceInMinutes } from 'date-fns';
import React from 'react';
import classes from '../style.module.scss';
import { getFieldsByCellName } from '../utils';
import { FormattedDate, FormattedTime } from './FormattedDates';

interface TooltipContentProps {
  data: any;
  active: boolean;
  cellName?: string;
}

export const TooltipContent: React.FC<TooltipContentProps> = ({ data, active, cellName }) => {
  if (!active || cellName === 'accounts') return null;

  const tabName = window.location.href.split('vk/')[1];

  let { objectType, objectName, objectId, issues, createdDate, updatedDate, status, deliveryStatus } =
    cellName && getFieldsByCellName(cellName, data, tabName);

  const objective = getHeader(data.adPlanObjective, data.bidType).split(' — ')[0] || '';
  status = parseStatus(status);
  const translationStatus = parseDeliveryStatus(deliveryStatus) || '';
  const adModerationReasons =
    Array.isArray(data.adModerationReasons) && tabName === 'ads' && cellName === 'ads'
      ? data.adModerationReasons.length
      : 0;
  const issuesCount = (Array.isArray(issues) ? issues.length : 0) + adModerationReasons;
  const noIssuesCountStatuses = ['Остановлена', 'Остановлено', 'Архивная', 'Архивное'];
  issues =
    !noIssuesCountStatuses.includes(status) && issuesCount
      ? ` ${issuesCount} ${getNumeral(issuesCount, ['причина', 'причины', 'причин'])}`
      : null;
  const createText = objectType === 'Объявление' ? 'Создано' : 'Создана';
  const updateText = objectType === 'Объявление' ? 'Изменено' : 'Изменена';
  const showTime = (checkDate: string) => differenceInMinutes(new Date(), new Date(checkDate)) >= 60;

  return (
    <TooltipPortal active={active}>
      <div className={classes.customTooltip}>
        <div className={classes.tooltipHeader}>
          <Typography size="Caption" color="TextSecondary">
            {objectType}
          </Typography>
          <Typography
            size="Caption"
            weight={600}
            color="TextPrimary"
            componentProps={{ className: classes.objectName }}
          >
            {objectName}
          </Typography>
          <Typography size="SmallText" color="TextSecondary">
            {objectId}
            {objective && `, ${objective}`}
          </Typography>
        </div>

        <div className={classes.tooltipBody}>
          <div className={classes.tooltipRow}>
            <Typography size="Caption" color="TextSecondary">
              Статус
            </Typography>
            <div className={classes.rowElementWrapper}>
              <IconStatus status={status} className={classes.statusIcon} />
              <Typography size="Caption" color="TextPrimary">
                {status}
              </Typography>
            </div>
          </div>

          <div className={classes.tooltipRow}>
            <Typography size="Caption" color="TextSecondary">
              Трансляция
            </Typography>
            <div className={classes.rowElementWrapper}>
              <Typography size="Caption" color="TextPrimary">
                {translationStatus}
              </Typography>
              {issues && (
                <Typography size="Caption" color="LinkPrimary">
                  {issues}
                </Typography>
              )}
            </div>
          </div>
          <div className={classes.tooltipRow}>
            <Typography size="Caption" color="TextSecondary">
              {createText}
            </Typography>
            <div className={classes.rowElementWrapper}>
              <FormattedDate dateString={createdDate} />
              {showTime(createdDate) && <FormattedTime dateString={createdDate} />}
            </div>
          </div>
          <div className={classes.tooltipRow}>
            <Typography size="Caption" color="TextSecondary">
              {updateText}
            </Typography>
            <div className={classes.rowElementWrapper}>
              <FormattedDate dateString={updatedDate} />
              {showTime(updatedDate) && <FormattedTime dateString={updatedDate} />}
            </div>
          </div>
        </div>
      </div>
    </TooltipPortal>
  );
};
