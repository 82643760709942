import { TooltipProps } from '@mui/material';
import clsx from 'clsx';
import React, { useState, useCallback, useEffect } from 'react';
import classes from './ellipsis-tooltip.module.scss';
import { MainTooltip } from './main-tooltip';

type EllipsisTooltipProps = {
  children: React.ReactElement;
  classNameContainer?: string;
  classNameTooltip?: string;
  tooltipMessage: NonNullable<React.ReactNode> | string;
  tooltipPlacement?: TooltipProps['placement'];
  tooltipTextAlign?: 'start' | 'end' | 'left' | 'right' | 'center' | 'justify' | 'match-parent';
  isShowTooltip?: (a: boolean) => void;
  isShowTooltipVisibleOff?: (a: boolean) => void;
  refElement?: React.MutableRefObject<null>; // если нужно использовать два разных текста HightLightText  структура типа ~ <Ellipsis refElement={ref}><div ref={ref}><HightLightText/><HightLightText/></div></EllipsisTooltip>
  maxWidth?: number;
  isTypograf?: boolean;
  typografType?: 'default' | 'names';
};

export const EllipsisTooltip = ({
  children,
  classNameContainer,
  classNameTooltip,
  tooltipMessage,
  tooltipPlacement = 'bottom-start',
  tooltipTextAlign = 'left',
  isShowTooltip,
  isShowTooltipVisibleOff,
  refElement,
  maxWidth,
  isTypograf = true,
  typografType = 'names',
}: EllipsisTooltipProps) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const mouseEnterHandler = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      const element = refElement ? refElement.current : (e.target as HTMLElement);

      if (element && element.offsetWidth !== element.scrollWidth && !showTooltip) {
        setShowTooltip(true);
      } else if (element && element.offsetWidth === element.scrollWidth && showTooltip) {
        setShowTooltip(false);
      }
    },
    [showTooltip, setShowTooltip],
  );

  useEffect(() => {
    isShowTooltip && isShowTooltip(showTooltip);
  }, [isShowTooltip, showTooltip]);

  useEffect(() => {
    isShowTooltipVisibleOff && isShowTooltipVisibleOff(showTooltip);
  }, [isShowTooltipVisibleOff, showTooltip]);

  return (
    <div className={clsx(classes.ellipsis, classNameContainer)} onMouseEnter={mouseEnterHandler}>
      <MainTooltip
        tooltipMessage={tooltipMessage}
        isVisible={isShowTooltip ? true : showTooltip}
        component="span"
        placement={tooltipPlacement}
        componentFeature="textEllips"
        textAlign={tooltipTextAlign}
        followCursor={true}
        componentClassName={classNameTooltip}
        maxWidth={maxWidth}
        isTypograf={isTypograf}
        typografType="names"
      >
        {children}
      </MainTooltip>
    </div>
  );
};
