import { Typography, WsItemType } from '@plarin/design';
import { getMemberName } from '@plarin/utils';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { WsMember } from '../../../../../types/profile/types';
import { useAppDataStore } from '../../../../components/app-data-provider';
import { TeamsDrawerItemList } from '../../../../components/teams-drawer-components/teams-drawer-item-list';
import { TeamsDrawerSearchPopup } from '../../../../components/teams-drawer-components/teams-drawer-search-popup/teams-drawer-search-popup';
import classes from './teams-drawer.module.scss';

export const TeamsDrawerMembers = observer(() => {
  const {
    profileStore: { currentWorkspace, data: profileData, WSRole },
    teams: { dataDrawer, updateDataDrawer },
  } = useAppDataStore();

  const [allMembersItems, setAllMembersItems] = useState<WsMember[]>([]);
  const [viewMemberItems, setViewMemberItems] = useState<WsMember[]>([]);

  const onAddItem = (item: WsMember) => {
    dataDrawer?.member_ids && updateDataDrawer('member_ids', [...dataDrawer.member_ids, item._id]);
  };

  const onDeleteItem = (id: string) => {
    dataDrawer?.member_ids &&
      updateDataDrawer(
        'member_ids',
        dataDrawer?.member_ids.filter(e => e !== id),
      );
  };

  useEffect(() => {
    const hash: { [key in string]: boolean } = {};
    const all: WsMember[] = [];
    const members: WsMember[] = [];

    dataDrawer?.member_ids.forEach(e => (hash[e] = true));
    currentWorkspace.members.forEach(e => {
      if (hash[e._id]) {
        members.push(e);
      } else if (!hash[e._id] && e.role !== 'wsrole:guest') {
        all.push(e);
      }
    });

    setViewMemberItems(members);
    setAllMembersItems(all);
  }, [dataDrawer?.member_ids, currentWorkspace.members]);

  return (
    <div className={classes.member}>
      <div className={classes.memberTitle}>
        <Typography size="Main" weight={600}>
          Участники
        </Typography>

        {!dataDrawer?.canOnlyView && (
          <TeamsDrawerSearchPopup
            buttonText="Добавить участника"
            searchPlaceholder="Поиск участника"
            listItems={allMembersItems}
            setItem={onAddItem}
            allAddedText="Все участники добавлены в команду."
            searchErrorText="По вашему запросу не найден ни один участник."
            wsItemType={WsItemType.member}
          />
        )}
      </div>

      {!dataDrawer?.member_ids.length ? (
        <Typography size="AuthContent" color="TextSecondary">
          В команду еще не добавлен ни один участник
        </Typography>
      ) : null}
      <div>
        {viewMemberItems.map(e => {
          return (
            <TeamsDrawerItemList
              itemData={e}
              wsItemType={WsItemType.member}
              key={e._id}
              status={e.status}
              name={getMemberName({ fname: e?.fname, lname: e?.lname, email: e?.email })}
              id={e._id}
              deleteAction={dataDrawer?.canOnlyView ? () => {} : onDeleteItem}
              hasAdminRights={
                dataDrawer?.leader_id === profileData.user_id || WSRole === 'wsrole:admin' || WSRole === 'wsrole:owner'
              }
            />
          );
        })}
      </div>
    </div>
  );
});
