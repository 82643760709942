import { Typography, WsAvatar, WsItemType } from '@plarin/design';
import React from 'react';
import { CustomMenuItem } from '../custom-menu-item';
import { Popover } from '../popover';
import { IPopover } from '../popover/type';
import { EllipsisTooltip } from '../tooltip';
import classes from './style.module.scss';

type ItemProjects = { name?: string; avatar_color: string; lname?: string; fname?: string; role?: string };

type TActionPanel = {
  isOpen: boolean;
  closeMenu: (e: React.MouseEvent) => void;
  actionRef?: React.MutableRefObject<null>;
  anchorEl: HTMLDivElement | null;
  items: ItemProjects[];
  header: string;
  itemsType: WsItemType;
};

// поповер со списком юзеров/проектов/пр/ В каждой строке аватарка и название проекта
export const ItemsPopover = ({
  isOpen,
  closeMenu,
  actionRef,
  anchorEl,
  items,
  header,
  itemsType,
  ...res
}: TActionPanel & Partial<IPopover> & React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <Popover
      {...res}
      classNameWrapper={classes.popoverWrapper}
      isOpen={isOpen}
      setIsOpen={e => {
        closeMenu(e as React.MouseEvent);
      }}
      anchorEl={anchorEl}
    >
      <div className={classes.itemsPaper}>
        <span className={classes.header}>
          <Typography size="Main" weight={600}>
            {header}
          </Typography>

          <span className={classes.amountCircle}>{items.length}</span>
        </span>

        <div className={classes.itemsContainer}>
          {items.map((item, index) => (
            <CustomMenuItem hover={false} key={index} className={classes.menuItem}>
              <WsAvatar type={itemsType} data={item} />
              {/* Имя пользователя или название проекта */}
              <EllipsisTooltip tooltipMessage={item.name ? item.name : item.fname + ' ' + item.lname}>
                <Typography size="AuthContent" ellips={true}>
                  {item.name ? item.name : item.fname + ' ' + item.lname}
                </Typography>
              </EllipsisTooltip>
            </CustomMenuItem>
          ))}
        </div>
      </div>
    </Popover>
  );
};
