import { Typography, AvatarsIconTypes, WsItemType } from '@plarin/design';
import { CustomSelect, Input, ListVariants } from '@plarin/inputs';
import { getMemberName, InputNameErrors } from '@plarin/utils';
import { observer } from 'mobx-react';
import React, { useMemo, useState } from 'react';
import { useAppDataStore } from '../../../../components/app-data-provider';
import { TeamsDrawerItemList } from '../../../../components/teams-drawer-components/teams-drawer-item-list';
import classes from './teams-drawer.module.scss';

export const TeamsDrawerLeaderBlock = observer(() => {
  const {
    teams: { dataDrawer, updateDataDrawer, isErrorName },
    profileStore: { currentWorkspace },
  } = useAppDataStore();

  const memberList = useMemo(() => {
    return currentWorkspace.members.reduce((acc: ListVariants[], member, i) => {
      if (i === 0) {
        acc.push({ value: 'not', label: 'Без лидера', iconType: AvatarsIconTypes.none });
      }
      if (member.role !== 'wsrole:guest') {
        acc.push({
          value: member._id,
          label: getMemberName({ fname: member?.fname, lname: member?.lname, email: member?.email }),
          ...member,
        });
      }
      return acc;
    }, []);
  }, [currentWorkspace]);

  // если дровер команды открыл пользователь, не имеющий права редактировать команду, то этот блок отобразит нередактируемого лидера команды с аватаркой, без выпадающего меню
  if (dataDrawer && dataDrawer.canOnlyView) {
    return (
      <div className={classes.isViewTitle}>
        <Typography weight={600} size="Main">
          Лидер команды
        </Typography>
        {currentWorkspace.members.map(leaderData => {
          if (leaderData._id === dataDrawer.leader_id) {
            const name =
              leaderData.fname || leaderData.lname ? `${leaderData.fname} ${leaderData.lname}` : leaderData.email;

            return (
              <TeamsDrawerItemList
                key={leaderData._id}
                itemData={leaderData}
                name={name}
                hasAdminRights={false}
                wsItemType={WsItemType.member}
              />
            );
          }
        })}
      </div>
    );
  }

  return (
    dataDrawer && (
      <div className={classes.nameContent}>
        <Typography weight={600} size="Main">
          О команде
        </Typography>
        <div className={classes.name}>
          <Input
            type="text"
            hint={isErrorName ? InputNameErrors.bigName255 : ''}
            error={isErrorName}
            inputRootWrapperDefault
            deactivatedMessage={dataDrawer.name}
            value={dataDrawer.name}
            size={48}
            maxLength={42}
            maxCounterValue={42}
            onChange={newValue => {
              updateDataDrawer('name', newValue);
            }}
            onBlur={() => {
              updateDataDrawer('name', dataDrawer.name.trim());
            }}
            label="Название команды"
          />
        </div>
        <CustomSelect
          value={dataDrawer.leader_id ? dataDrawer.leader_id : 'not'}
          listVariants={memberList}
          label="Лидер команды"
          menuHeight={248}
          useMemberAvatar
          shape="circle"
          onChange={value => {
            updateDataDrawer('leader_id', value);
          }}
        />
      </div>
    )
  );
});
