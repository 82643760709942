import { SelectionColumnDef } from 'ag-grid-community';
import React from 'react';
import classes from '../tooltip/main-tooltip.module.scss';

export const isMac = () => {
  return navigator.userAgent.indexOf('Mac OS X') !== -1;
};

export const SelectionHotkeysHintText = (
  <div>
    Выбрать все ({isMac() ? 'CMD' : 'CTRL'} + A)
    <br />
    Снять выделение ({isMac() ? 'CMD' : 'CTRL'} + D)
  </div>
);

export const hasCheckboxColumn = (selectionColDef: SelectionColumnDef | undefined) => {
  return !!selectionColDef;
};

export const handleKeyBindings = (
  event: KeyboardEvent,
  gridRef: React.RefObject<any>,
  selectionColDef: SelectionColumnDef | undefined,
) => {
  const key = event.key?.toLowerCase();
  const ctrlOrMeta = event.ctrlKey || event.metaKey;

  // Проверяем есть ли фокус на input или textarea
  const activeElement = document.activeElement;
  const isInputFocused =
    activeElement &&
    ((activeElement.tagName === 'INPUT' && (activeElement as HTMLInputElement).type !== 'checkbox') ||
      activeElement.tagName === 'TEXTAREA');

  if (!isInputFocused && gridRef.current && hasCheckboxColumn(selectionColDef)) {
    if (ctrlOrMeta && (key === 'a' || key === 'ф')) {
      event.preventDefault();
      gridRef.current.api.selectAllFiltered();
    }

    if (ctrlOrMeta && (key === 'd' || key === 'в')) {
      event.preventDefault();
      gridRef.current.api.deselectAll();
    }
  }
};

export const TooltipRenderer = (props: any) => {
  return <div className={classes.content}>{props.value}</div>;
};
