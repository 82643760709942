import React from 'react';
import './in-center.scss';

interface InCenterProps {
  children: React.ReactNode;
}

export const InCenter: React.FC<InCenterProps> = function InCenter({ children }) {
  return <div className="inCenter">{children}</div>;
};
