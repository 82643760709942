import { Preloader } from '@plarin/design';
import { isUrlProd, isUrlDev } from '@plarin/utils';
import { getYmCounter } from '@plarin/utils';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useIntercom } from 'react-use-intercom';
import { InviteData, TClientInfoResp } from '../../../types/profile/types';
import { useAppDataStore } from '../../components/app-data-provider';
import { WorkspaceDrawer } from '../../components/drawer/workspace-drawer';
import { getLocalSelectedWS } from '../../utils/localStorageSelectedWS';
import { useRedirectRoot } from '../../utils/redirect';
import { CreateFirstWs, AllWsList, Invites } from './blocks';
import classes from './style.module.scss';

const RootPage = observer(() => {
  const {
    profileStore: {
      workspaces = [],
      workspaceName,
      workspaceShortname,
      data,
      acceptWsInvite,
      declineWsInvite,
      hideRecalledInvite,
      isRootRedirect,
      setIsRootRedirect,
    },
    ws: { wsList, isOpenDrawerNewWS, setShowDrawerNewWS, isLoading, getWsList },
  } = useAppDataStore();

  const invites: InviteData[] | undefined = data.invites;
  const history = useHistory();
  const { boot, hide } = useIntercom();
  const pathnameParts = window.location.href.split('/');
  const wsUrlPart = decodeURIComponent(pathnameParts[3]);
  const selectWs = getLocalSelectedWS(data.user_id);

  useEffect(() => {
    if (!isLoading) {
      getWsList();
    }
  }, [wsList.length, selectWs, data]);

  // Скрываем Intercom, если был открыт, передаем данные пользователя, прячем значок
  useEffect(() => {
    hide();
    const userName = `${data.fname || ''} ${data.lname || ''}`.trim() || data.email;
    boot({
      userId: data.user_id,
      name: userName,
      email: data.email,
      hideDefaultLauncher: true,
      companies: workspaces.map(({ workspace_id, name }) => ({
        companyId: workspace_id,
        name,
      })),
    });
  }, [workspaces, data, boot, hide]);

  useEffect(() => {
    // Передаем в Яндекс.Метрику user_id через параметры посетителя
    if (isUrlProd || isUrlDev) {
      // @ts-ignore
      ym(getYmCounter(), 'userParams', { UserID: data.user_id });
    }
  }, [data.user_id]);

  useRedirectRoot({
    isRootRedirect,
    wsUrlPart,
    invites,
    workspaceShortname,
    selectWs,
    setIsRootRedirect,
    workspaces,
  });

  if (isLoading) {
    return (
      <>
        {!workspaceName && <div className={classes.bg} />}
        <div className={classes.loading}>
          <Preloader />
        </div>
      </>
    );
  }

  return (
    <>
      {/* Фон с градиентом. Применяется, когда не выбран текущий воркспейс*/}
      {!workspaceName && <div className={classes.bg} />}
      <div className={classes.scroll}>
        {invites && invites?.length > 0 && (
          <Invites
            isStartPage={!workspaceName}
            invites={invites}
            userFname={data.fname}
            declineInvite={declineWsInvite}
            acceptInvite={(hash, setIsLoading) => {
              acceptWsInvite(hash, setIsLoading).then(getWsList);
            }}
            hideInvite={hideRecalledInvite}
            margins={clsx(
              workspaces.length ? classes.mb32 : classes.mb36,
              workspaceShortname ? classes.mt8 : classes.mt36,
            )}
          />
        )}
        {workspaces.length > 0 ? (
          <AllWsList
            setIsDrawerOpen={setShowDrawerNewWS}
            margins={clsx(classes.mt32, workspaceShortname && !invites?.length && classes.mt4)}
          />
        ) : (
          <CreateFirstWs openDrawer={setShowDrawerNewWS} margins={clsx(!invites?.length && classes.mt32)} />
        )}
        <WorkspaceDrawer isDrawerOpen={isOpenDrawerNewWS} setIsDrawerOpen={setShowDrawerNewWS} />
      </div>
    </>
  );
});

export default RootPage;
