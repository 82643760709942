import React, { createContext, useContext, useRef } from 'react';
import { AppDataStore, TAppDataStoreProps } from '../../stores/app-data.store';

let appDataStore: AppDataStore;

const AppDataStoreContext = createContext<AppDataStore | undefined>(undefined);

export function useAppDataStore() {
  const context = useContext(AppDataStoreContext);
  if (context === undefined) {
    throw new Error('useAppDataStore должен использоваться в AppDataStoreContext');
  }
  return context;
}

interface AppDataStoreProviderProps extends TAppDataStoreProps {
  children?: React.ReactNode;
}

export const AppDataStoreProvider: React.FC<AppDataStoreProviderProps> = ({ children, ...props }) => {
  const storeRef = useRef<AppDataStore | null>(null);

  if (!storeRef.current) {
    storeRef.current = new AppDataStore(props);
  }

  return <AppDataStoreContext.Provider value={storeRef.current}>{children}</AppDataStoreContext.Provider>;
};
