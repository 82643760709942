import { WsAvatar, WsItemType, CloseCircleIcon, Typography } from '@plarin/design';
import { EllipsisTooltip, MainTooltip } from '@plarin/inputs';
import { TooltipMessage, typografNames } from '@plarin/utils';
import clsx from 'clsx';
import React, { useState } from 'react';
import { TTeamsFilters } from '../../../../../../../types/projects/projects';
import classes from '../project-info.module.scss';

interface IProjectTeamFormItem {
  team: TTeamsFilters;
  setSelectedTeams: (e: TTeamsFilters[]) => void;
  selectedTeams: TTeamsFilters[];
  isArchived: boolean;
}

export const TeamItem = ({ team, setSelectedTeams, selectedTeams, isArchived }: IProjectTeamFormItem) => {
  const [isHovered, setIsHovered] = useState(false);
  const nameTooltipContent = `${team.name}`;

  return (
    <div
      data-isarchived={isArchived}
      className={clsx(classes.projectCabinetsList, classes.listItemAdded)}
      onMouseOver={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onMouseDown={e => e.preventDefault()}
    >
      <div className={classes.iconBlock}>
        <WsAvatar data={team} type={WsItemType.team} />
        {team.name && (
          <EllipsisTooltip tooltipMessage={nameTooltipContent}>
            <Typography color="TextPrimary" size="AuthContent" weight={400} ellips={true}>
              {typografNames(team.name)}
            </Typography>
          </EllipsisTooltip>
        )}
      </div>
      {isArchived && (
        <div className={clsx(classes.deleteCabinetProjectHover, !isHovered && classes.deleteCabinetProject)}>
          <MainTooltip
            tooltipMessage={TooltipMessage.DELETE_FROM_LIST}
            isVisible={true}
            component="span"
            followCursor={true}
          >
            <CloseCircleIcon
              onClick={() => {
                setSelectedTeams(selectedTeams.filter(e => e.id !== team.id));
              }}
            />
          </MainTooltip>
        </div>
      )}
    </div>
  );
};
