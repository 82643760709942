import { Typography } from '@plarin/design';
import { EllipsisTooltip, Switch } from '@plarin/inputs';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import classes from './style.module.scss';

interface IItem {
  name: string;
  enabled: boolean;
  id: number;
}

interface IGoalsList {
  title: string;
  subTitle?: string;
  isCount?: boolean;
  items: IItem[];
  defaultValue?: string;
  onChangeValue?: (item: IItem[]) => void;
  idShow?: boolean;
}

export const GoalsList = ({ idShow, title, subTitle, isCount, items, onChangeValue }: IGoalsList) => {
  const [arrItems, setArrItems] = useState(items);
  const [checkedAll, setCheckedAll] = useState(false);

  const count = (subTitle ? subTitle : '') + (isCount ? ` (${items?.length})` : '');

  const onChangeSwitch = (name: string) => {
    setArrItems(
      arrItems?.map(item => {
        if (item.name === name) {
          const result = { ...item };
          result.enabled = !item.enabled;
          return result;
        } else {
          return item;
        }
      }),
    );
  }; // для тогла итемов

  const onChangeAllSwitch = (enabled: boolean) => {
    setArrItems(
      arrItems?.map(item => {
        const result = { ...item };
        result.enabled = enabled;
        return result;
      }),
    );
    setCheckedAll(enabled);
  }; // для тогла все цели

  useEffect(() => {
    const haveChecked = arrItems?.filter(item => {
      return item.enabled;
    }).length;
    if (haveChecked === items?.length) {
      setCheckedAll(true);
    }
    if (haveChecked === 0) {
      setCheckedAll(false);
    }
    arrItems && onChangeValue && onChangeValue(arrItems);
  }, [arrItems]); // для изменения состояния тогла все цели, если в списке все итемы выбраны или нет

  useEffect(() => {
    setArrItems(items);
  }, [items]);

  return (
    <div>
      <Typography size="GoalsList" weight={600}>
        {title}
      </Typography>
      {subTitle && (
        <div className={clsx(classes.titleList, classes.listGoals)}>
          <Typography size="Main" weight={600}>
            {count}
          </Typography>
          <Switch
            checked={checkedAll}
            onChange={() => {
              onChangeAllSwitch(!checkedAll);
            }}
          />
        </div>
      )}
      {arrItems?.map((item, index) => {
        return (
          <div key={index} className={classes.listGoals}>
            <EllipsisTooltip classNameContainer={classes.ellipsisList} tooltipMessage={item.name}>
              <Typography ellips size="AuthContent">
                {idShow && (
                  <Typography component="span" color="TextSecondary">
                    {`${item.id} `}
                  </Typography>
                )}
                {item.name}
              </Typography>
            </EllipsisTooltip>
            <Switch
              onChange={() => {
                onChangeSwitch(item.name);
              }}
              checked={item.enabled}
            />
          </div>
        );
      })}
    </div>
  );
};
