import { CheckIcon, HelpIcon, Typography } from '@plarin/design';
import { isUrlProd, sendMetricGoal } from '@plarin/utils';
import React, { useEffect, useMemo, useState } from 'react';
import { Button } from '../buttons';
import {
  BidDateInputs,
  BidItemsType,
  BidTypeInput,
  BidValueInput,
  BudgetLimit,
  getCommonMaxPrice,
  getCurrencySymbol,
  getItemsType,
  grabStrategyBids,
  isKnownCurrency,
  LimitTypes,
  maxPricesAreDifferent,
  setCommonMaxPrice,
  TCommonBudgetOptimizationLevel,
  MtOptimisationLevel,
  TNewStrategyBids,
  datesAreDifferent,
} from '../inputs';
import { MainTooltip } from '../tooltip';
import { autobiddingMode, getDate, getRequestData, getTypeVariants } from './bidsUtils';
import { checkIfCanSave } from './checkForRow';
import {
  EditBidProps,
  addPackageLimitMinMax,
  setAvailableAdsIds,
  getBidMode,
  getCommonDailyLimit,
  getCommonLifetimeLimit,
  getCommonLimitType,
  getHeader,
  getItemsTypeForAdsReq,
  getStartAndEndDateForReqBody,
  inputsTypeVariants1,
  inputsTypeVariants2,
  StrategyBidModes,
  VKHardcodeRUB,
  VKHardcodeUSDAndEUR,
  isPackageLimitBidError,
  GetOptimnisationLevelAlert,
} from './strategy-form-utils';
import classes from './style.module.scss';
import { AvailableAdsItemResp, StrategyBidSaveActionProps, TRows } from './types';

export const StrategyEditBid = ({
  selectedRows: data,
  close,
  gridApi,
  onSaveAction,
  getAvailableAds,
  isOnlyBudgetEditor,
  whatIsEdited,
}: EditBidProps) => {
  const [formIsBlocked, setFormIsBlocked] = useState(false);
  const [adsAmountArr, setAdsAmounts] = useState<number[]>([]);
  const [isBlockedBudget, setIsBlockedBudget] = useState(true);
  const [selectedRows, setSelectedRows] = useState([...data]);
  const [maxPriceLimit, setMaxPriceLimit] = useState<undefined | number>(undefined);
  const [minPriceLimit, setMinPriceLimit] = useState<undefined | number>(undefined);
  const [errorPackageLimitBid, setErrorPackageLimitBid] = useState(false);
  const [isPackageLimitBidAdded, setIsPackageLimitBidAdded] = useState(false);

  const startModeForInputsTypeVariants2 = (selectedRows: TRows) => {
    if (new Set(selectedRows.map(row => row.bidAutobiddingMode)).size > 1) {
      return StrategyBidModes.differentModes;
    } else {
      if (selectedRows[0]?.bidAutobiddingMode === 'second_price_mean') return 'Максимум показов';
      if (selectedRows[0]?.bidAutobiddingMode === 'second_price') return 'Предельная цена';
      if (selectedRows[0]?.bidAutobiddingMode === 'fixed') return 'Фиксированная ставка';
    }
    return '';
  };
  let inputsTypeVariants = () => getTypeVariants(selectedRows[0].adPlanObjective);
  const startMode =
    inputsTypeVariants() === 'inputsTypeVariants1'
      ? getBidMode(selectedRows)
      : startModeForInputsTypeVariants2(selectedRows);

  const [mode, setMode] = useState<string>(startMode);

  const initialMode = startMode;
  const currency = isKnownCurrency(selectedRows[0].accountCurrency);
  const minLimitCoefficient =
    getCurrencySymbol(currency) === '$' || getCurrencySymbol(currency) === '€'
      ? VKHardcodeUSDAndEUR.minLimit
      : VKHardcodeRUB.minLimit;
  const maxLimit =
    getCurrencySymbol(currency) === '$' || getCurrencySymbol(currency) === '€'
      ? VKHardcodeUSDAndEUR.maxLimit
      : VKHardcodeRUB.maxLimit;

  // ********* Новые и старые данные редактируемых рекламных объектов

  const itemsType: BidItemsType = useMemo(() => getItemsType(window.location.pathname), []);
  const oldBidsData = useMemo(() => grabStrategyBids(selectedRows, itemsType), [selectedRows, itemsType]);

  const [newBidsData, setNewBidsData] = useState<TNewStrategyBids>(grabStrategyBids(selectedRows, itemsType));
  // храним здесь исходные значения в строковом формате, чтобы потом сравнивать с newBidsData и понимать, внёс ли пользователь изменения, то есть есть ли что сохранять
  const prevBidsData = useMemo(() => JSON.stringify(oldBidsData), []);
  const initBidsDataObj = JSON.parse(prevBidsData);

  // ********* Лимиты дневные/общие

  // если у выбранных сущностей разные значения лимита, то в стейте будет undefined
  const [commonDailyLimit, setCommonDailyLimit] = useState<number | undefined>(getCommonDailyLimit(newBidsData));
  const [commonLifetimeLimit, setCommonLifetimeLimit] = useState<number | undefined>(
    getCommonLifetimeLimit(newBidsData),
  );
  // проверяем, есть ли такое, что у всех выбранных сущностей значение только в дневном лимите либо только в вечернем
  const [limitType, setLimitType] = useState<LimitTypes>(getCommonLimitType(newBidsData));

  // Минимальное ограничение лимита бюджета - это minLimitCoefficient, умноженный на количество объявлений
  // minLimit для разных валют разный. Здесь при инициализации компонента мы сеттим minLimit соответствующей валюты. Но ниже в useEffect мы умножим этот коэффициент на количество объявлений
  const [minBudgetLimit, setMinBudgetLimit] = useState(minLimitCoefficient);

  // ********* Состояния кнопки сохранения

  const [maySave, setMaySave] = useState(false);
  const [blockingMessage, setBlockingMessage] = useState('');

  // *********  Состояния дат

  const [newStartDate, setNewStartDate] = useState<Date | null>(
    itemsType === 'ad_plan' ? getDate(selectedRows[0].adPlanStart) : getDate(selectedRows[0].campaignStart),
  );
  const [newEndDate, setNewEndDate] = useState<Date | null>(
    itemsType === 'ad_plan' ? getDate(selectedRows[0].adPlanStop) : getDate(selectedRows[0].campaignStop),
  );

  const [startDateWasChanged, setStartDateWasChanged] = useState<boolean>();
  const [endDateWasChanged, setEndDateWasChanged] = useState<boolean>();

  const [newStartDateHasError, setNewStartDateHasError] = useState<boolean>();
  const [newEndDateHasError, setNewEndDateHasError] = useState<boolean>();

  const [startDateErrorMessage, setStartDateErrorMessage] = useState('');
  const [endDateErrorMessage, setEndDateErrorMessage] = useState('');

  // Если выбрали несколько сущностей для редактирования и у них НЕ одинаковый уровень оптимизации бюджета (adPlan / campaign), то в этой переменной будет undefined
  const commonBudgetOptimizationLevel: TCommonBudgetOptimizationLevel = useMemo(
    () =>
      Array.from(new Set(newBidsData.map(item => item.budgetOptimizationLevel))).length === 1
        ? newBidsData[0].budgetOptimizationLevel
        : undefined,
    [newBidsData],
  );
  const budgetLimitsDisabled = useMemo(
    () =>
      selectedRows.length === 1 &&
      commonBudgetOptimizationLevel === MtOptimisationLevel.adGroup &&
      itemsType === 'ad_plan',
    [],
  );

  // Минимальное ограничение лимита бюджета зависит от количества объявлений. Если объявлений больше одного, то minLimitCoefficient нужно умножить на количество объявлений.
  useEffect(() => {
    let maxAds = adsAmountArr.length ? Math.max(...adsAmountArr) : 0;
    setMinBudgetLimit(maxAds * minLimitCoefficient || minLimitCoefficient);
  }, [adsAmountArr]);

  const saveActionWrapper = () => {
    setFormIsBlocked(true);

    const requestData: StrategyBidSaveActionProps = [];

    // добавляем в requestData те объекты, которые были изменены. Сравниваем строковые представления объектов (есть проблема с датами: они поначалу string, потом Date)
    // TODO: для решения проблема с датами можно добавить проверку, что если нет равенства, нужно дополнительно проверить даты, приведя строку и Date к единому формату
    newBidsData.forEach((newData, index) => {
      if (JSON.stringify(newData) !== JSON.stringify(oldBidsData[index]) || initialMode !== mode) {
        const { startDate, stopDate } = getStartAndEndDateForReqBody(newData, itemsType);

        const newRequestData = getRequestData({
          inputsTypeVariants: inputsTypeVariants(),
          newData,
          itemsType,
          startDate,
          stopDate,
          mode: mode as keyof typeof autobiddingMode,
        });
        newRequestData && requestData.push(newRequestData);
      }
    });

    onSaveAction({
      requestData,
      itemsType,
      commonBudgetOptimizationLevel,
      newBidsData,
      gridApi,
      whatIsEdited,
    })
      .then(res => {
        // Отправляем конверсию в Яндекс.Метрику
        sendMetricGoal('usage_vk_change_budget', 'manage/vk');
        if (res.command_results?.length && res.command_results[0].success) {
          !isUrlProd && console.log('Отредактировалось успешно. Результат: ', res.command_results[0]);
        } else {
          !isUrlProd &&
            console.log(
              'Ошибка: отредактировать не получилось ',
              res.command_results?.length && res.command_results[0],
            );
        }
      })
      .catch(res => {
        !isUrlProd && console.log('Catch:', res);
        // Отправляем конверсию в Яндекс.Метрику
        sendMetricGoal('error_vk_change_budget', 'manage/vk');
      });

    close();
  };

  // валидация инпутов с датами
  useEffect(() => {
    if (!newStartDate && startDateWasChanged) {
      setNewStartDateHasError(true);
      setStartDateErrorMessage('Задайте дату начала');
    } else {
      setNewStartDateHasError(false);
      setStartDateErrorMessage('');
    }

    if (limitType === LimitTypes.lifetime && !newEndDate) {
      setNewEndDateHasError(true);
      setEndDateErrorMessage('Задайте дату окончания');
    } else {
      setNewEndDateHasError(false);
      setEndDateErrorMessage('');
    }
  }, [newStartDate, newEndDate, limitType, startDateWasChanged, endDateWasChanged]);

  useEffect(() => {
    const itemsTypeForRequest = getItemsTypeForAdsReq(itemsType);
    const ids = newBidsData.map(i => Number(i.item_id));

    itemsTypeForRequest &&
      getAvailableAds({
        ids: ids,
        itemsType: itemsTypeForRequest,
        setIsBlockedBudget,
      }).then((res: AvailableAdsItemResp[]) => {
        if (res) {
          addPackageLimitMinMax(res, selectedRows, setSelectedRows);
          setIsPackageLimitBidAdded(true);
          setAvailableAdsIds(res, setAdsAmounts);
        }
      });
  }, []);

  useEffect(() => {
    setNewBidsData(
      newBidsData.map((i, index) => {
        const startDate = startDateWasChanged
          ? newStartDate
          : selectedRows[index]?.[itemsType === 'ad_plan' ? 'adPlanStart' : 'campaignStart'];
        return {
          ...i,
          adPlanStart: itemsType === 'ad_plan' ? startDate : initBidsDataObj[index].adPlanStart,
          campaignStart: itemsType !== 'ad_plan' ? startDate : initBidsDataObj[index].campaignStart,
        };
      }),
    );
  }, [newStartDate, startDateWasChanged]);

  useEffect(() => {
    setNewBidsData(
      newBidsData.map((i, index) => {
        const endDate = endDateWasChanged
          ? newEndDate
          : selectedRows[index]?.[itemsType === 'ad_plan' ? 'adPlanStop' : 'campaignStop'];

        return {
          ...i,
          adPlanStop: itemsType === 'ad_plan' ? endDate : initBidsDataObj[index].adPlanStop,
          campaignStop: itemsType !== 'ad_plan' ? endDate : initBidsDataObj[index].campaignStop,
        };
      }),
    );
  }, [newEndDate, endDateWasChanged]);

  useEffect(() => {
    if (mode === initialMode) {
      setNewBidsData(
        newBidsData.map((i, index) => {
          return {
            ...i,
            maxPrice: initBidsDataObj[index].maxPrice,
            price: initBidsDataObj[index].price,
          };
        }),
      );
    }
    if (mode !== initialMode) {
      setNewBidsData(
        newBidsData.map(i => {
          return {
            ...i,
            maxPrice: '',
            price: '',
          };
        }),
      );
    }
  }, [mode]);

  useEffect(() => {
    checkIfCanSave({
      newBidsData,
      mode,
      initialMode,
      setMaySave,
      setBlockingMessage,
      minPriceLimit,
      maxPriceLimit,
      commonDailyLimit,
      commonLifetimeLimit,
      limitType,
      minBudgetLimit,
      budgetLimitsDisabled,
      newEndDateHasError,
      newStartDateHasError,
      selectedRows,
      startDateWasChanged,
      endDateWasChanged,
      prevBidsData,
      inputsTypeVariants: inputsTypeVariants(),
      itemsType,
      commonBudgetOptimizationLevel,
      errorPackageLimitBid,
    });
  });

  useEffect(() => {
    if (isPackageLimitBidAdded) {
      if (!isPackageLimitBidError(selectedRows)) {
        // Лимиты размера ставки
        const newMaxPriceLimit = Math.min(...Array.from(new Set(selectedRows.map(row => row.packageLimitBidMax))));
        const newMinPriceLimit = Math.max(...Array.from(new Set(selectedRows.map(row => row.packageLimitBidMin))));
        setMaxPriceLimit(newMaxPriceLimit);
        setMinPriceLimit(newMinPriceLimit);
      } else {
        setErrorPackageLimitBid(true);
      }
    }
  }, [selectedRows, isPackageLimitBidAdded]);

  if (isOnlyBudgetEditor)
    return (
      <div className={classes.mainWrap}>
        <Typography
          size="Main"
          color="typographyColorTextPrimary"
          weight={700}
          componentProps={{ className: classes.formHeader }}
        >
          Бюджет
        </Typography>
        <div className={classes.formInputWrap}>
          {/* Уведомление о том, к какому срезу применятся изменения. Например, при редактировании объявлений - к группе или к адплану */}
          <GetOptimnisationLevelAlert
            commonBudgetOptimizationLevel={commonBudgetOptimizationLevel}
            itemsType={itemsType}
            isMultipleEdit={selectedRows.length > 1}
          />

          <BudgetLimit
            prevBidsData={JSON.parse(prevBidsData)}
            commonDailyLimit={commonDailyLimit}
            setCommonDailyLimit={setCommonDailyLimit}
            commonLifetimeLimit={commonLifetimeLimit}
            setCommonLifetimeLimit={setCommonLifetimeLimit}
            limitType={limitType}
            setLimitType={setLimitType}
            adsAmount={newBidsData.length === 1 ? adsAmountArr[0] || 0 : 0}
            minLimit={minBudgetLimit}
            maxLimit={maxLimit}
            currencySymbol={getCurrencySymbol(currency)}
            newBidsData={newBidsData}
            setNewBidsData={setNewBidsData}
            budgetOptimizationLevel={commonBudgetOptimizationLevel}
            allDisabled={formIsBlocked || !adsAmountArr.length || isBlockedBudget}
            isBlockedBudget={isBlockedBudget}
          />
        </div>
        <div>
          <BidDateInputs
            isDisabled={limitType === LimitTypes.differentLimitTypes}
            initialLimitType={getCommonLimitType(JSON.parse(prevBidsData))}
            datesAreDifferent={datesAreDifferent({ selectedRows, itemsType })}
            title={itemsType === 'ad_plan' ? 'Даты проведения Кампании' : 'Даты проведения Группы объявлений'}
            startDate={newStartDate}
            initialStartDate={
              itemsType === 'ad_plan' ? getDate(selectedRows[0].adPlanStart) : getDate(selectedRows[0].campaignStart)
            }
            initialEndDate={
              itemsType === 'ad_plan' ? getDate(selectedRows[0].adPlanStop) : getDate(selectedRows[0].campaignStop)
            }
            endDate={newEndDate}
            setStartDate={setNewStartDate}
            setEndDate={setNewEndDate}
            setStartDateWasChanged={setStartDateWasChanged}
            setEndDateWasChanged={setEndDateWasChanged}
            setNewStartDateHasError={setNewStartDateHasError}
            newStartDateHasError={newStartDateHasError}
            newEndDateHasError={newEndDateHasError}
            setNewEndDateHasError={setNewEndDateHasError}
            startDateErrorMessage={startDateErrorMessage}
            endDateErrorMessage={endDateErrorMessage}
            limitType={limitType}
            adPlanStartDate={getDate(selectedRows[0].adPlanStart)}
            adPlanEndDate={getDate(selectedRows[0].adPlanStop)}
            areDatesLimitedByAdplan={itemsType === 'campaign' || itemsType === 'banner'}
          />
        </div>
        <div className={classes.btnsContainer}>
          <MainTooltip
            tooltipMessage={blockingMessage}
            isVisible={!!blockingMessage}
            followCursor={true}
            component="span"
            placement="bottom-start"
          >
            <Button
              disabled={!maySave || formIsBlocked}
              variant="filled"
              size="small"
              color="primary"
              onClick={saveActionWrapper}
            >
              Сохранить
            </Button>
          </MainTooltip>

          <Button disabled={formIsBlocked} variant="outlined" size="small" color="primary" onClick={close}>
            Отменить
          </Button>
        </div>
      </div>
    );

  return (
    <div className={classes.mainWrap}>
      <Typography
        size="Main"
        color="typographyColorTextPrimary"
        weight={700}
        componentProps={{ className: classes.formHeader }}
      >
        {getHeader(selectedRows[0].adPlanObjective, selectedRows[0].bidType)}
      </Typography>
      {/* Уведомление о том, к какому срезу применятся изменения. Например, при редактировании объявлений - к группе или к адплану */}
      <GetOptimnisationLevelAlert
        commonBudgetOptimizationLevel={commonBudgetOptimizationLevel}
        itemsType={itemsType}
        isMultipleEdit={selectedRows.length > 1}
      />

      {/* За день:{adPlanDaily}  За всё время: {adPlanLifetime} */}
      <div className={classes.formInputWrap}>
        <BidTypeInput
          mode={mode}
          setMode={setMode}
          inputsModeVariants={
            inputsTypeVariants() === 'inputsTypeVariants1' ? inputsTypeVariants1 : inputsTypeVariants2
          }
          header="Стратегия"
          tooltipMessage="Изменить стратегию"
          isDisabled={formIsBlocked}
        />
      </div>
      {(mode === 'Предельная цена' ||
        (inputsTypeVariants() === 'inputsTypeVariants2' && mode !== 'Разные значения')) && (
        <div className={classes.formInputWrap}>
          <BidValueInput
            selectedItems={newBidsData}
            setNewBids={setNewBidsData}
            bidsAreDifferent={maxPricesAreDifferent(newBidsData, inputsTypeVariants())}
            getCommonBid={e => {
              return getCommonMaxPrice(e, inputsTypeVariants());
            }}
            setCommonBid={setCommonMaxPrice}
            currency={currency}
            maxBid={maxPriceLimit}
            minBid={minPriceLimit}
            pinSize="12"
            header={`Ставка${selectedRows[0].bidType ? ` - ${selectedRows[0].bidType}` : ''}`}
            isDisabled={formIsBlocked}
          />
        </div>
      )}
      <div className={classes.formInputWrap}>
        <BudgetLimit
          prevBidsData={JSON.parse(prevBidsData)}
          commonDailyLimit={commonDailyLimit}
          setCommonDailyLimit={setCommonDailyLimit}
          commonLifetimeLimit={commonLifetimeLimit}
          setCommonLifetimeLimit={setCommonLifetimeLimit}
          limitType={limitType}
          setLimitType={setLimitType}
          adsAmount={newBidsData.length === 1 ? adsAmountArr[0] || 0 : 0}
          minLimit={minBudgetLimit}
          maxLimit={maxLimit}
          currencySymbol={getCurrencySymbol(currency)}
          newBidsData={newBidsData}
          setNewBidsData={setNewBidsData}
          budgetOptimizationLevel={commonBudgetOptimizationLevel}
          allDisabled={formIsBlocked || !adsAmountArr.length || isBlockedBudget}
          isBlockedBudget={isBlockedBudget}
        />
      </div>
      <div className={classes.datesInStrategyForm}>
        <BidDateInputs
          isDisabled={limitType === LimitTypes.differentLimitTypes}
          initialLimitType={getCommonLimitType(JSON.parse(prevBidsData))}
          datesAreDifferent={datesAreDifferent({ selectedRows, itemsType })}
          title={itemsType === 'ad_plan' ? 'Даты проведения Кампании' : 'Даты проведения Группы объявлений'}
          startDate={newStartDate}
          initialStartDate={
            itemsType === 'ad_plan' ? getDate(selectedRows[0].adPlanStart) : getDate(selectedRows[0].campaignStart)
          }
          initialEndDate={
            itemsType === 'ad_plan' ? getDate(selectedRows[0].adPlanStop) : getDate(selectedRows[0].campaignStop)
          }
          endDate={newEndDate}
          setStartDate={setNewStartDate}
          setEndDate={setNewEndDate}
          setStartDateWasChanged={setStartDateWasChanged}
          setEndDateWasChanged={setEndDateWasChanged}
          setNewStartDateHasError={setNewStartDateHasError}
          newStartDateHasError={newStartDateHasError}
          newEndDateHasError={newEndDateHasError}
          setNewEndDateHasError={setNewEndDateHasError}
          startDateErrorMessage={startDateErrorMessage}
          endDateErrorMessage={endDateErrorMessage}
          limitType={limitType}
          adPlanStartDate={getDate(selectedRows[0].adPlanStart)}
          adPlanEndDate={getDate(selectedRows[0].adPlanStop)}
          areDatesLimitedByAdplan={itemsType === 'campaign' || itemsType === 'banner'}
        />
      </div>
      <div className={classes.btnsContainer}>
        <MainTooltip
          tooltipMessage={blockingMessage}
          isVisible={!!blockingMessage}
          followCursor={true}
          component="span"
          placement="bottom-start"
        >
          <Button
            disabled={!maySave || formIsBlocked}
            variant="filled"
            size="small"
            color="primary"
            onClick={saveActionWrapper}
          >
            Сохранить
          </Button>
        </MainTooltip>

        <Button disabled={formIsBlocked} variant="outlined" size="small" color="primary" onClick={close}>
          Отменить
        </Button>
      </div>
    </div>
  );
};
