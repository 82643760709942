import { Text, Typography } from '@plarin/design';
import React from 'react';
import { Link } from 'react-router-dom';
import { routerPaths } from '../../../routing/router-path';
import { AuthContentTemplate } from './auth-content-template';

export const AuthContentResetTemplate: React.FC<{
  confirm?: boolean;
  children: React.ReactNode;
}> = ({ children }) =>
  AuthContentTemplate({
    children,
    footer: (
      <Text color="textTertiary" size="s">
        <Typography underline color="linkPrimary" component={Link} componentProps={{ to: routerPaths.unAuth.LOGIN }}>
          Вернуться на страницу входа
        </Typography>
      </Text>
    ),
  });
