import React from 'react';
import './auth-layout.scss';

interface SpaceBetweenProps {
  children: React.ReactNode;
}

export const AuthLayoutTemplate: React.FC<{ children: React.ReactNode; sidebar: React.ReactNode }> = ({
  children,
  sidebar,
}) => (
  <div className="authLayoutWrapper">
    <div className="authLayout">
      <main>{children}</main>
      <aside>{sidebar}</aside>
    </div>
  </div>
);
