import { Column, getGridApi, GridApi, Table, TextError } from '@plarin/inputs';
import { IntegrationsTabNameEnum, relativeDate } from '@plarin/utils';
import type { ICellRendererParams, IRowNode, SelectionColumnDef } from 'ag-grid-community';
import { GetQuickFilterTextParams } from 'ag-grid-enterprise';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Account } from '../../../../types/integration/types';
import { useAppDataStore } from '../../../components/app-data-provider';
import { SyncStatusEnum } from '../../../dictionary/integrations';
import { STATUS } from '../../../utils/constants';
import { selectionColumnDef } from '../../../utils/get-selection-column-def';
import { useFilter } from '../../manage-vk/tabs-content/utils/quickFilterSwitcher';
import { IntegrationsActionBar } from '../integrations-action-bar';
import { TabContentWithoutAccounts } from '../tab-content-without-accounts/tab-content-without-accounts';

export interface ITabContentWithAccounts {
  data?: Account[];
}

// скрываем чекбокс, вместо него показываем лоадер
export const isLoading = (node: ICellRendererParams<any, any, any> | IRowNode<any>) =>
  node.data.syncStatusCode === 'loading';

export const getSelectionColumnDef = (): SelectionColumnDef => {
  return {
    ...selectionColumnDef,
    minWidth: 40,
    maxWidth: 40,
    cellRendererSelector: params => (isLoading(params) ? { component: 'PreloaderRenderer' } : { component: null }),
    cellClass: params => `integrationStatusCode checkbox ${isLoading(params.node) ? 'hideCheckbox' : 'hidePreloader'}`,
    cellClassRules: {
      deleted: param =>
        param.data.syncStatusCode === STATUS.DISABLED ||
        param.data.integrationStatusCode === STATUS.BLOCKED ||
        param.data.integrationStatusCode === STATUS.DELETED,
      active: param =>
        param.data.syncStatusCode === STATUS.ENABLED && param.data.integrationStatusCode === STATUS.ACTIVE,
      blocked: param =>
        param.data.syncStatusCode === STATUS.ENABLED && param.data.integrationStatusCode === STATUS.BLOCKED,
    },
  };
};

const column: Column[] = [
  {
    headerName: 'Название',
    field: 'name',
    flex: 2,
    cellRenderer: 'NameRenderer',
    headerComponentParams: { subheader: 'ID аккаунта' },
    cellClassRules: {
      'sync-status-disabled': param =>
        param.data.syncStatusCode === STATUS.DISABLED || param.data.integrationStatusCode === STATUS.DELETED,
    },
    cellRendererSelector: params => {
      if (params.node.rowPinned) return { component: 'NameCountRenderer' };
    },
    cellRendererParams: {
      text: ['аккаунт', 'аккаунта', 'аккаунтов', 'm'],
      innerRenderer: 'NameRenderer',
      showSelectedAmount: true,
    },
    resizable: false,
    lockPosition: 'left',
    suppressMovable: true,
    valueGetter: params => `${params.data.name} ${params.data.syncStatusCode}`,
    getQuickFilterText: (params: GetQuickFilterTextParams) => {
      return `${params.value} ${params.data.clientId}`;
    },
  },
  // {
  //   headerName: 'Рекламная сеть',
  //   field: 'network',
  //   hide: true,
  //   cellClassRules: {
  //     'sync-status-disabled': param =>
  //       param.data.syncStatusCode === STATUS.DISABLED || param.data.integrationStatusCode === STATUS.DELETED,
  //   },
  // },
  // {
  //   headerName: 'ID аккаунта',
  //   field: 'clientId',
  //   hide: true,
  //   cellClassRules: {
  //     'sync-status-disabled': param =>
  //       param.data.syncStatusCode === STATUS.DISABLED || param.data.integrationStatusCode === STATUS.DELETED,
  //   },
  // },
  {
    headerName: 'Тип аккаунта',
    headerComponentParams: { subheader: 'Описание' },
    field: 'type',
    flex: 1,
    cellRenderer: 'TwoLinesRenderer',
    cellRendererParams: {
      firstLine: 'type',
      secondLine: 'description',
      isQuickFilter: false,
    },
    resizable: false,
    // lockPosition: true,
    suppressMovable: true,
    cellClassRules: {
      'sync-status-disabled': param =>
        param.data.syncStatusCode === STATUS.DISABLED || param.data.integrationStatusCode === STATUS.DELETED,
    },
    getQuickFilterText: () => '',
  },
  {
    headerName: 'Рекламные кабинеты',
    field: 'cabinetCount',
    flex: 1,
    cellClassRules: {
      'sync-status-disabled': param =>
        param.data.syncStatusCode === STATUS.DISABLED || param.data.integrationStatusCode === STATUS.DELETED,
    },
    getQuickFilterText: () => '',
    resizable: false,
    suppressMovable: true,
  },
  {
    headerName: 'Статус интеграции',
    flex: 1,
    cellRenderer: 'IntegrationStatusRenderer',
    cellRendererParams: (params: ICellRendererParams) => ({
      bullet: 'integrationStatusCode',
      statusName: 'integrationStatus',
      lastUpdated: 'integrationDate',
      alternativeBullet: 'syncStatusCode',
      hasError:
        params.data.syncStatusCode !== 'disabled' &&
        (params.data.integrationStatusCode === 'blocked' || params.data.syncStatusCode === 'blocked'),
      errorTitle: params.data.integrationStatus,
      hintComponent: <TextError />,
    }),
    headerComponentParams: { subheader: 'Последняя синхронизациия' },
    cellClassRules: {
      'sync-status-disabled': param =>
        param.data.syncStatusCode === STATUS.DISABLED || param.data.integrationStatusCode === STATUS.DELETED,
    },
    getQuickFilterText: () => '',

    resizable: false,
    suppressMovable: true,
  },
];

export const TabContentAccounts = observer(() => {
  const {
    integrationsVK: { accounts, filterValue, isTableLoading, hasError, fetchClients, showActionBar },
  } = useAppDataStore();

  const [gridApi, setGridApi] = useState<GridApi>(null as unknown as GridApi);
  const [selectedRows, setSelectedRows] = useState<Account[]>([]);

  useEffect(() => {
    setTimeout(() => {
      getGridApi(gridApi) && setSelectedRows(gridApi.getSelectedRows());
    }, 10);
  }, [accounts, gridApi]);

  useFilter(filterValue, gridApi);

  return (
    <>
      <>
        {isTableLoading || (!!accounts && accounts.length) || hasError ? (
          <>
            {showActionBar && <IntegrationsActionBar gridApi={gridApi} selectedRows={selectedRows} />}
            <Table<Account>
              tabName={IntegrationsTabNameEnum.ACCOUNTS}
              showStatusBar
              columns={column}
              rowData={accounts || []}
              setSelectedRows={setSelectedRows}
              onGridReady={setGridApi}
              tableLoading={isTableLoading!}
              errorTable={hasError}
              ActionError={fetchClients}
              selectionColumnDef={getSelectionColumnDef()}
            />
          </>
        ) : (
          <TabContentWithoutAccounts tabType="accounts" />
        )}
      </>
    </>
  );
});
