import React from 'react';
import { AuthFormContainerTemplate } from './auth-form-container-template';
import './auth-form-container-template.scss';

export const AuthFormContainerResetTemplate: React.FC<{
  title: string;
  error?: React.ReactNode;
  children: React.ReactNode;
}> = ({ title, error, children }) =>
  AuthFormContainerTemplate({
    title,
    children: (
      <>
        {error && (
          <div role="alert" className="auth-form-template__error">
            {error}
          </div>
        )}
        {children}
      </>
    ),
  });
