import clsx from 'clsx';
import React from 'react';
import './auth-inputs-template.scss';

export const AuthInputsTemplate: React.FC<{
  formProps?: React.ComponentProps<'form'>;
  addSpace?: boolean;
  children: React.ReactNode;
}> = ({ formProps, children, addSpace }) => (
  <form
    {...formProps}
    className={clsx('authInputsTemplate', formProps?.className, addSpace && 'authInputsTemplate_space')}
    autoComplete="on"
  >
    {children}
  </form>
);
