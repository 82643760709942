import { WsItemType } from '@plarin/design';
import { CellClassParams, Column } from '@plarin/inputs';
import { TWsRole, WsMembers } from '../../../../types/profile/types';
import { sortRow } from '../../../utils/common';
import { comporatorStatusWorkspaces } from '../../../utils/workspaces';
import { MenuProjects } from './MenuProject';

export const projectColumns = (wsMembers: WsMembers, wsRole: TWsRole | undefined): Column[] => {
  return [
    {
      headerName: 'Название',
      field: 'name',
      cellRenderer: 'SquareAvatarNameRenderer',
      cellRendererParams: {
        ProjectMenu: MenuProjects,
        wsRole,
        wsType: WsItemType.project,
      },
      headerComponentParams: {
        alignY: 'center',
      },
      flex: 2,
      cellClassRules: {
        'users-status-disabled': (params: CellClassParams) => params.data.is_archived,
      },
      resizable: false,
      lockPosition: true,
      comparator: (a, b, nodeA, nodeB) => sortRow(nodeA.data.name, nodeB.data.name),
    },
    {
      headerName: 'Статус',
      cellRenderer: 'StatusRenderer',
      headerComponentParams: {
        alignY: 'center',
      },
      flex: 1,
      cellClassRules: {
        'users-status-disabled ': (params: CellClassParams) => params.data.is_archived,
      },
      resizable: false,
      lockPosition: true,
      comparator: (a, b, nodeA, nodeB) =>
        comporatorStatusWorkspaces(
          nodeA.data.status,
          nodeB.data.status,
          nodeA.data.is_archived,
          nodeB.data.is_archived,
        ),
    },
    {
      headerName: 'Менеджер проекта',
      field: 'managerName',
      cellRenderer: 'CircleAvatarNameRenderer',
      cellRendererParams: {
        wsMembers: wsMembers,
      },
      headerComponentParams: {
        alignY: 'center',
      },
      flex: 1,
      cellClassRules: {
        'users-status-disabled ': (params: CellClassParams) => params.data.is_archived,
      },
      resizable: false,
      lockPosition: true,
      comparator: (a, b) => sortRow(b, a),
    },
  ];
};
