import { WsItemType, WsAvatar, CloseCircleIcon, Typography } from '@plarin/design';
import { EllipsisTooltip, MainTooltip } from '@plarin/inputs';
import { TooltipMessage, typografNames } from '@plarin/utils';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { TeamAccessItemResp } from '../../../../../types/teams/teams';
import { useAppDataStore } from '../../../app-data-provider';
import classes from './style.module.scss';

export const SelectedTeamItem = observer(
  ({ team, deleteAction }: { team: TeamAccessItemResp; deleteAction: (id: string) => void }) => {
    const {
      profileStore: { WSRole },
    } = useAppDataStore();
    const [isHover, setIsHover] = useState(false);

    return (
      <div
        className={classes.teamsListItem}
        key={team._id}
        onMouseLeave={() => setIsHover(false)}
        onMouseOver={() => !isHover && setIsHover(true)}
      >
        <div className={classes.iconBlock}>
          <WsAvatar data={team} type={WsItemType.team} />

          <EllipsisTooltip tooltipMessage={team.name}>
            <Typography
              size="AuthContent"
              ellips={true}
              color={team.status === 'inactive' ? 'TextSecondary' : 'TextPrimary'}
            >
              {typografNames(team.name)}
            </Typography>
          </EllipsisTooltip>
        </div>

        {WSRole !== 'wsrole:employee' && (
          <div className={classes.deleteAction}>
            {isHover && (
              <MainTooltip
                tooltipMessage={TooltipMessage.DELETE_FROM_LIST}
                isVisible={true}
                component="span"
                followCursor={true}
              >
                <CloseCircleIcon onClick={() => deleteAction(team._id)} />
              </MainTooltip>
            )}
          </div>
        )}
      </div>
    );
  },
);
