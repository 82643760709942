import {
  AvatarWorkspaceIcon,
  AvatarWorkspaceIconSelect,
  SettingsIcon,
  SignInIcon,
  Typography,
  WsItemType,
} from '@plarin/design';
import { EllipsisTooltip, AvatarsInRow } from '@plarin/inputs';
import { getMemberName } from '@plarin/utils';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import { IwsList } from '../../../../../types/profile/types';
import { useAppDataStore } from '../../../../components/app-data-provider';
import { SETTINGS, WORKSPACE } from '../../../../routing/router-path';
import classes from './ws-card.module.scss';

interface IWorkspaceItem {
  item: IwsList;
}

export const WorkspaceItem = ({ item }: IWorkspaceItem) => {
  const {
    profileStore: { data, setCurrentWS, currentWorkspace },
    menu: { deselectMenuItem },
  } = useAppDataStore();
  const history = useHistory();
  const { hide: hideIntercom } = useIntercom();

  const showSettingsButton = item?.members.find(el => {
    return (el.role === 'wsrole:owner' || el.role === 'wsrole:admin') && el._id === data.user_id;
  });

  const projectsData = item.projects.map(el => {
    return { avatar_color: el.avatar_color, id: el._id, name: el.name };
  });

  const nameOwner = getMemberName({ fname: item.owner?.fname, lname: item.owner?.lname, email: item.owner?.email });

  const membersData = item.members.reduce((result, current) => {
    if (current._id !== data.user_id) {
      result.push({
        avatar_color: current.avatar_color,
        id: current._id,
        name: getMemberName({ fname: current?.fname, lname: current?.lname, email: current?.email }),
      });
    }
    return result;
  }, [] as { avatar_color: string; id: string; name: string }[]);

  const teamsData = item.teams.map(el => {
    return { avatar_color: el.avatar_color, id: el._id, name: el.name };
  });

  const showInfoBlock = !!teamsData.length || !!projectsData.length || !!membersData.length;

  const redirect = () => {
    setCurrentWS(item._id).then(wsShortname => {
      history.push(`/${wsShortname}`);
      deselectMenuItem();
      hideIntercom();
    });
  };

  const redirectToSetting = () => {
    setCurrentWS(item._id).then(wsShortname => {
      history.push(`/${wsShortname}/${WORKSPACE}/${SETTINGS}`);
    });
  };

  return (
    <div className={classes.cardWrap} key={item._id}>
      <div className={classes.blockIcon}>
        {currentWorkspace._id === item._id ? (
          <AvatarWorkspaceIconSelect className={classes.cardAvatar} onClick={redirect} size={48} />
        ) : (
          <AvatarWorkspaceIcon className={classes.cardAvatar} onClick={redirect} size={48} />
        )}
      </div>

      <div className={classes.blocCardContent}>
        <div className={classes.cardContent}>
          <div className={classes.blockName}>
            <EllipsisTooltip classNameTooltip={classes.ellipsisWidth} tooltipMessage={item.company}>
              <Typography
                componentProps={{ onClick: redirect }}
                weight={600}
                size="DrawerTitle"
                color="LinkPrimary"
                ellips={true}
              >
                {item.company || '<Без названия>'}
              </Typography>
            </EllipsisTooltip>
            <Typography size="Main" color="TextSecondary">
              {`Владелец: ${nameOwner}`}
            </Typography>
          </div>

          <div className={classes.lincBlock}>
            <div className={classes.linkContent} onClick={redirect}>
              <Typography weight={600} size="Main" color="LinkPrimary">
                Перейти в пространство
              </Typography>
              <SignInIcon className={classes.linkIcon} color="primary60" />
            </div>
            {showSettingsButton && (
              <div className={classes.linkContent} onClick={redirectToSetting}>
                <Typography color="LinkPrimary" weight={600} size="Main">
                  Настройки
                </Typography>
                <SettingsIcon color="primary60" />
              </div>
            )}
          </div>
        </div>
        {showInfoBlock && (
          <div className={classes.infoBlock}>
            {!!membersData.length && (
              <div className={classes.infoCount}>
                <AvatarsInRow
                  header="Ваши коллеги"
                  avatarShape="circle"
                  itemsData={membersData}
                  itemsType={WsItemType.member}
                />
                <Typography size="Main" color="TextSecondary">
                  {`Ваши коллеги: ${membersData.length}`}
                </Typography>
              </div>
            )}
            {!!teamsData.length && (
              <div className={classes.infoCount}>
                <AvatarsInRow header="Ваши команды" itemsData={teamsData} itemsType={WsItemType.team} />
                <Typography size="Main" color="TextSecondary">
                  {`Ваши команды: ${teamsData.length}`}
                </Typography>
              </div>
            )}
            {!!projectsData.length && (
              <div className={classes.infoCount}>
                <AvatarsInRow itemsData={projectsData} itemsType={WsItemType.project} />
                <Typography size="Main" color="TextSecondary">
                  {`Ваши проекты: ${projectsData.length}`}
                </Typography>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
