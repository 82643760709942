import { RedoIcon, Preloader } from '@plarin/design';
import clsx from 'clsx';
import React from 'react';
import { Button } from '../buttons';
import { ButtonProps } from '../buttons/button-props';
import classes from './icon-button.module.scss';

export const AlertErrorRefreshButton: React.FC<ButtonProps> = ({ loading, onClick, ...props }) => {
  return (
    <Button
      {...props}
      onClick={ev => (loading || !onClick ? undefined : onClick(ev))}
      variant="outlined"
      size="medium"
      color="primary"
      className={clsx(classes.paddingButton, loading && classes.paddingLoad)}
    >
      <div className={clsx(classes.buttonRetry, loading && classes.buttonLoad)}>
        {loading ? (
          <Preloader size={16} thickness={3} className={classes.circularProgress} />
        ) : (
          <RedoIcon color="primary60" />
        )}
        Загрузить данные об аккаунте повторно
      </div>
    </Button>
  );
};
