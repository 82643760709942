import React from 'react';
import './space-between.scss';

interface SpaceBetweenProps {
  children: React.ReactNode;
}

export const SpaceBetween: React.FC<SpaceBetweenProps> = function SpaceBetween({ children }) {
  return <div className="spaceBetween">{children}</div>;
};
