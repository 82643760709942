import React from 'react';
import './auth-complete-buttons-group.scss';

interface AuthCompleteProps {
  children: React.ReactNode;
}

export const AuthCompleteButtonsGroup: React.FC<AuthCompleteProps> = ({ children }) => (
  <div className="authCompleteButtonsGroup">{children}</div>
);
