import { EyeOffIcon, LockIcon, Typography, WsAvatar, WsItemType, PeopleIcon } from '@plarin/design';
import { EllipsisTooltip } from '@plarin/inputs';
import { typografNames } from '@plarin/utils';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import React from 'react';
import { useAppDataStore } from '../../../../components/app-data-provider';
import classes from './teams-drawer.module.scss';

export const TeamsDrawerTitle = observer(() => {
  const {
    teams: { dataDrawer },
  } = useAppDataStore();

  const isArchived = dataDrawer ? dataDrawer.status === 'inactive' : false;

  return (
    dataDrawer && (
      <div className={classes.title}>
        <div className={classes.leftBlock}>
          {dataDrawer.isEditDrawer ? (
            <WsAvatar type={WsItemType.team} size={40} data={dataDrawer} />
          ) : (
            <span className={clsx(classes.blockIcon)}>
              <PeopleIcon />
            </span>
          )}

          <EllipsisTooltip
            tooltipMessage={
              dataDrawer.name ? dataDrawer.name : dataDrawer.isEditDrawer ? 'Название команды' : 'Создание команды'
            }
          >
            <Typography
              componentProps={{ className: clsx(isArchived ? classes.titleNameArchived : classes.titleName) }}
              weight={600}
              size="DrawerProject"
              ellips
              color={isArchived ? 'TextSecondary' : 'TextPrimary'}
            >
              {dataDrawer.name
                ? typografNames(dataDrawer.name)
                : dataDrawer.isEditDrawer
                ? 'Название команды'
                : 'Создание команды'}
            </Typography>
          </EllipsisTooltip>
        </div>
        <div className={classes.rightBlock}>
          {dataDrawer.hidden && (
            <EllipsisTooltip tooltipMessage="Невидимая команда">
              <EyeOffIcon />
            </EllipsisTooltip>
          )}
          {dataDrawer.closed && (
            <EllipsisTooltip tooltipMessage="Закрытая команда">
              <LockIcon />
            </EllipsisTooltip>
          )}
          {isArchived && (
            <span className={classes.isArchivedSeparatorTitle}>
              <Typography size="Caption" color="TextSecondary">
                В архиве
              </Typography>
            </span>
          )}
        </div>
      </div>
    )
  );
});
