import { Typography } from '@plarin/design';
import { switchFilter } from '@plarin/utils';
import { observer } from 'mobx-react';
import React from 'react';
import { TTeamsData, TTeamsFilters, TTeams } from '../../../../../../../../types/projects/projects';
import classes from '../../styles.module.scss';
import { ProjectTeamsList } from './project-teams-list';

interface ITeamsSearchResult {
  teams: TTeams;
  filteredTeams: null | TTeamsFilters[];
  selectedTeams: TTeamsFilters[];
  changeTeamsSelection: (teams: TTeamsFilters) => void;
  filtersTeams: (searchVal: string, allTeams: TTeamsData[]) => TTeamsFilters[];
  searchValue: string;
}

export const TeamsSearchResult = observer(
  ({ teams, filteredTeams, selectedTeams, changeTeamsSelection, filtersTeams, searchValue }: ITeamsSearchResult) => {
    const searchValueData = filteredTeams?.filter(e => {
      return !selectedTeams.find(el => {
        return el.id === e.id;
      });
    });

    const openTeamsList = teams.teams
      .filter(team => team.status === 'active')
      ?.map(team => {
        return {
          id: `${team._id}`,
          name: team.name,
          avatar_color: team.avatar_color,
          member_ids: team.member_ids,
          status: team.status,
        };
      })
      .filter(e => {
        return !selectedTeams.find(el => {
          return el.id === e.id;
        });
      });

    const errorResult = () => {
      if (searchValue && filteredTeams && searchValueData?.length === 0) {
        return 'По вашему запросу не найдена ни одна команда пространства.';
      }
      if (selectedTeams.length === teams.teams?.length) {
        return 'Все команды пространства добавлены в проект.';
      }
      if (!openTeamsList.length) {
        return 'Команды в пространстве находятся в архиве. Разархивируйте команды или создайте новые.';
      }
    };

    return (
      <>
        {openTeamsList.length && !(searchValue && filteredTeams && searchValueData?.length === 0) ? (
          <ProjectTeamsList
            teamsListToRender={searchValue ? filteredTeams : openTeamsList}
            selectedTeams={selectedTeams}
            changeTeamsSelection={changeTeamsSelection}
            isFoundItem={true}
            searchValue={filtersTeams(searchValue, teams.teams).length ? searchValue : switchFilter(searchValue)}
          />
        ) : (
          <div className={classes.wrapMessage}>
            <Typography weight={400} size="AuthContent" color="TextSecondary">
              {errorResult()}
            </Typography>
          </div>
        )}
      </>
    );
  },
);
