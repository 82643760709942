import { Avatar, CloseCircleIcon, Typography } from '@plarin/design';
import { EllipsisTooltip, MainTooltip } from '@plarin/inputs';
import { getMemberName, TooltipMessage } from '@plarin/utils';
import clsx from 'clsx';
import React, { useState } from 'react';
import { TGuests } from '../../../../../../../types/projects/projects';
import classes from '../project-info.module.scss';

interface IProjectGuestFormList {
  guest: TGuests;
  setSelectedGuests: (e: TGuests[]) => void;
  selectedGuests: TGuests[];
  isArchived: boolean;
}

//
export const ProjectGuestFormList = ({
  guest,
  setSelectedGuests,
  selectedGuests,
  isArchived,
}: IProjectGuestFormList) => {
  const [isHovered, setIsHovered] = useState(false);
  const nameTooltipContent = getMemberName({ fname: guest?.fname, lname: guest?.lname, email: guest?.email });

  return (
    <div
      data-isarchived={isArchived}
      className={clsx(classes.projectCabinetsList, classes.listItemAdded)}
      onMouseOver={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onMouseDown={e => e.preventDefault()}
    >
      <div className={classes.iconBlock}>
        <Avatar
          backgroundColor={guest.avatarColor}
          fname={guest.fname}
          lname={guest.lname}
          email={guest.email}
          classProps={classes.avatar}
        />
        <EllipsisTooltip tooltipMessage={nameTooltipContent}>
          <Typography color="TextPrimary" size="AuthContent" weight={400} ellips={true}>
            {nameTooltipContent}
          </Typography>
        </EllipsisTooltip>
      </div>
      {isArchived && (
        <div className={clsx(classes.deleteCabinetProjectHover, !isHovered && classes.deleteCabinetProject)}>
          <MainTooltip
            tooltipMessage={TooltipMessage.DELETE_FROM_LIST}
            isVisible={true}
            component="span"
            followCursor={true}
          >
            <CloseCircleIcon
              onClick={() => {
                setSelectedGuests(selectedGuests.filter(e => e.id !== guest.id));
              }}
            />
          </MainTooltip>
        </div>
      )}
    </div>
  );
};
