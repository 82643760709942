import { ArrowRight } from '@plarin/design';
import React from 'react';

interface LinkWithArrowProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
}

export const LinkWithArrow: React.FC<LinkWithArrowProps> = function LinkWithArrow({ children }) {
  return (
    <div className="linkWithArrow">
      <span className="linkWithArrow__text">{children}</span>
      <ArrowRight />
    </div>
  );
};
