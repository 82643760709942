import { Slider } from '@plarin/inputs';
import React from 'react';
import { TariffsSlide } from '../../slider/tariffs-slide';
import { AuthLayoutTemplate } from './auth-layout-template';
import createWorkspaceImageSrc from './images/create-workspace-image.svg';
import inviteMembersImageSrc from './images/invite-members-image.svg';

interface AuthSliderProps {
  children: React.ReactNode;
}

export const AuthSliderLayoutTemplate: React.FC<AuthSliderProps> = ({ children }) =>
  AuthLayoutTemplate({
    children,
    sidebar: (
      <Slider interval={6000}>
        {/* <TariffsSlide />*/}
        <img src={createWorkspaceImageSrc} alt="" />
        <img src={inviteMembersImageSrc} alt="" />
      </Slider>
    ),
  });
