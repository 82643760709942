import { TooltipRenderer, SelectionHotkeysHintText } from '@plarin/inputs';
import type { ICellRendererParams, IRowNode, SelectionColumnDef } from 'ag-grid-community';

// базовые свойства колонки с чекбоксами
export const selectionColumnDef: SelectionColumnDef = {
  pinned: 'left',
  lockPinned: true,
  resizable: false,
  headerClass: 'checkbox',

  // Для заголовка колонки тултип с информацией о хоткеях для выделения
  tooltipComponent: TooltipRenderer,
  // @ts-ignore JSX.Element вместо стринги прокатывает
  headerTooltip: SelectionHotkeysHintText,
};

// колонка с чекбоксами для интеграций
export const getIntegrationsSelectionColumnDef = (
  isLoading: (node: ICellRendererParams<any, any, any> | IRowNode<any>) => boolean,
  page?: string,
): SelectionColumnDef => {
  return {
    ...selectionColumnDef,
    minWidth: 64,
    maxWidth: 64,
    cellRendererSelector: params => {
      if (isLoading(params)) {
        return { component: 'PreloaderRenderer' };
      }
      // такая строка не имеет детей, поэтому не может являться группирующей. Однако, с точки зрения пользователя, это "родитель", просто без детей. Поэтому мы добавляем неактивную стрелочку для разворачивания группировки.
      if (params.data.isParent && !params.data.items.length) {
        return { component: 'FakeGroupCellRenderer' };
      }
      return { component: 'agGroupCellRenderer' };
    },
    cellRendererParams: {
      suppressCount: true,
      value: '',
      suppressDoubleClickExpand: true,
      suppressEnterExpand: true,
      loaderPrecisePositioning: -8,
      page: page,
    },
    cellStyle: { borderRight: '1px solid transparent' },
    cellClass: params => {
      if (params.data.isParent) {
        return `connectionStatusPanelCode ${isLoading(params.node) ? 'hideCheckbox' : 'hidePreloader'}`;
      }

      if (isLoading(params.node)) {
        return 'hideCheckbox';
      }

      return 'hidePreloader';
    },
    cellClassRules: {
      enabled: params => params.data.plarin_status === 'enabled',
      disable: params => params.data.plarin_status === 'disabled',
      blocked: params => params.data.plarin_status === 'enabled' && params.data.integrationStatusCode === 'blocked',
    },
  };
};
